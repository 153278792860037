import React, { Component, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { get } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
} from "reactstrap";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { getCompetition, listAllPromotions } from "../../graphql/queries";
import { updatePost, updateCompetition } from "../../graphql/mutations";
import axios from "axios";
import queryString from "query-string";
//React Typist
// import images
import ReferralPostBlock from "./referral-post-block";
//CountDown

function PromotionReview() {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [activeCreator, setActiveCreator] = useState('');
  const [competitions, setCompetitions] = useState([]);
  const [creators, setCreators] = useState([]);
  const [promotion, setPromotion] = useState();
  const [currentIndex, setCurrentIndex] = useState(12);
  const [sortByKey, setSortByKey] = useState("id");
  const [competitionId, setCompetitionId] = useState(params.id);
  const [competition, setCompetition] = useState();
  const [posts, setPosts] = useState([]);
  const [isTimeLoading, setIsTimeLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [limit, setLimit] = useState(20);
  
  const fetchCompetitions = async () => {
    const apiData = await API.graphql({
      query: getCompetition,
      variables: { id: competitionId },
    });
    setCompetition(apiData.data.getCompetition);
    // setCompetitions(
    //   apiData.data.listCompetitions.items.filter(function (e) {
    //     return e._deleted !== true && e.hasEnded !== true;
    //   })
    // );
    let tempCreators = [];
    apiData.data.getCompetition.posts.items.map(e => {
      if (
        !tempCreators.includes({
          creator: e.creator,
          referrals: e.referrals.items,
        }) &&
        e.referrals.items.length > 0
      ) {
        tempCreators.push({ creator: e.creator, referrals: e.referrals.items });
      }
    });
    setCreators(tempCreators);
  };

  console.log(creators);
  const updateActiveCreator = (e) => {
    setActiveCreator(e.target.value);
  }

  useEffect(async () => {
    fetchCompetitions();
  }, []);

  let fakeCreators = [];

  const update = async (limit) => {
    const maxLength = limit + 1 > posts.length ? posts.length : limit + 1;
    for (let i = limit; i <= maxLength; i++) {
      const e = posts[i];
      if (e !== undefined) {
        const referralsArr = get(e, ["referrals", "items"], []);
        if (referralsArr.length > 0) {
        }
        const isFinalPost = i === maxLength - 1 && maxLength === posts.length;
        const isLastPost = i === maxLength - 1;
        console.log(e);
        
      }
    }
  };

  const updateTime = async (id) => {
    setIsTimeLoading(true);
    const vs = {
      input: {
        id: competition.id,
        name: competition.name,
        lastUpdated: new Date().getTime(),
        _version: competition._version,
      },
    };
    const updateComp = await API.graphql(
      graphqlOperation(updateCompetition, vs)
    );

    setIsTimeLoading(false);
  };

  const handleCompetitionChange = (e) => {
    setCompetitionId(e.id);
  }

  const reset = async (id, likes, bLikes, version) => {
    const variables = {
      input: {
        id: id,
        likes: parseInt(likes) - parseInt(bLikes),
        bonusLikes: 0,
        _version: version,
      },
    };
    const createPostResult = await API.graphql(
      graphqlOperation(updatePost, variables)
    );
    window.location.reload();
  }

  const columns = [
    {
      id: 1,
      label: "ID",
    },
    {
      id: 2,
      label: "Link",
    },

    {
      id: 2,
      label: "Likes",
    },

    {
      id: 2,
      label: "Bonus Likes",
    },
    {
      id: 2,
      label: "View",
    },
    {
      id: 2,
      label: "Reset",
    },
  ];

  return (
    <React.Fragment>
      <section className="container" id="home">
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col md="12" className="mt-4">
            <div
              className="bg-white p-2 mb-4 shadow"
              style={{ borderRadius: 8 }}
            >
              <h1 className="text-uppercase text-primary title-dark mt-2 mb-4 coming-soon">
                <span className="element">
                  {competition && competition.name}
                </span>
              </h1>
              <h4>Competition</h4>

              <select
                className="form-control custom-select"
                name="select"
                style={{ maxWidth: 200 }}
                value={activeCreator}
                onChange={updateActiveCreator}
              >
                <option value="">-</option>
                {creators &&
                  creators.map((c, i) => {
                    if (!fakeCreators.includes(c.creator)) {
                      fakeCreators.push(c.creator);
                      return (
                        <option
                          key={i}
                          value={`${c.creator}`}
                        >{`${c.creator}`}</option>
                      );
                    }
                  })}
              </select>
            </div>
            <Paper style={{ width: "100%" }}>
              <TableContainer>
                <h4 className="mt-2 ml-3 p-3" style={{ marginBottom: 0 }}>
                  Posts
                </h4>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => {
                        return (
                          <TableCell key={column.id}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSortByKey(column.label);
                              }}
                            >
                              {column.label === sortByKey ? (
                                <b>{column.label}</b>
                              ) : (
                                column.label
                              )}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {competition &&
                      competition.posts.items.map((row) => {
                         if (row.creator === activeCreator && row.referrals.items.length > 0) {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              // onClick={() =>
                              //   window.open(`https://preffy.com/c/${row.id}`, "_blank")
                              // }
                              tabIndex={-1}
                              key={row.id}
                            >
                              <TableCell key={row.id}>{row.id}</TableCell>
                              <TableCell key={row.id}>{row.link}</TableCell>
                              <TableCell key={row.id}>{row.likes}</TableCell>
                              <TableCell key={row.id}>
                                {row.bonusLikes}
                              </TableCell>
                              <TableCell key={row.id}>
                                <div
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setPosts(row.referrals.items);
                                  }}
                                >
                                  View
                                </div>
                              </TableCell>
                              <TableCell key={row.id}>
                                <div
                                  className="btn btn-primary"
                                  onClick={() => {
                                    reset(row.id, row.likes, row.bonusLikes, row._version);
                                  }}
                                >
                                  Reset
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                         }
                        
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                maxWidth: "85%",
                margin: "auto",
              }}
            >
              {posts &&
                posts.map((e, key) => {
                  console.log(e);
                  return <ReferralPostBlock key={key} post={e.referringLink} />;
                  
                })}
            </div>
          </Col>
        </Row>

        {/* <Row>
            <Col md="12">
              <Link
                to="#"
                onClick={this.togglemodal}
                className="btn btn-primary mt-4 mr-2"
              >
                <i className="mdi mdi-check"></i> Notify Me
              </Link>
              <Link to="/index" className="btn btn-outline-primary mt-4">
                <i className="mdi mdi-backup-restore"></i> Go Back Home
              </Link>
            </Col>
          </Row> */}
      </section>
    </React.Fragment>
  );
}

export default PromotionReview;
