import axios from "axios";

export const BasicCompetitionEmail = (emails, prizes, songName, artistName, link) => {
  const welcomeCode = `Hi!<br/><br/>I just wanted to reach out to let you know that we just launched a new ${prizes} Preffy competition that we hope you'll consider entering.<br/><br/>The song is ${songName} by ${artistName} and you can find the competition here: ${link}.<br/><br/> Please let me know if you have any questions!`;
  axios
    .post("https://mandrillapp.com/api/1.0/messages/send.json", {
      key: "DFk_CJ59VD66VtMy9OddOA",
      message: {
        from_email: "contact@preffy.com",
        to: emails,
        subject: `New ${prizes} Preffy Competition`,
        html: welcomeCode,
      },
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};
