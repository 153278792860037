import React, { Component, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { get } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
} from "reactstrap";
import { getCompetition, listAllPromotions } from "../../graphql/queries";
import { updatePost, updateCompetition } from "../../graphql/mutations";
import axios from "axios";
import queryString from "query-string";
//React Typist
// import images
import PostBlock from "./post-block";
//CountDown

function PromotionReview() {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [activeEmail, setActiveEmail] = useState('');
  const [competitions, setCompetitions] = useState([]);
  const [promotion, setPromotion] = useState();
  const [currentIndex, setCurrentIndex] = useState(12);
  const [competitionId, setCompetitionId] = useState(params.id);
  const [competition, setCompetition] = useState();
  const [posts, setPosts] = useState([]);
  const [isTimeLoading, setIsTimeLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [limit, setLimit] = useState(20);
  
  const fetchCompetitions = async () => {
    const apiData = await API.graphql({
      query: getCompetition,
      variables: { id: competitionId },
    });
    setCompetition(apiData.data.getCompetition);
    // setCompetitions(
    //   apiData.data.listCompetitions.items.filter(function (e) {
    //     return e._deleted !== true && e.hasEnded !== true;
    //   })
    // );
    setPosts(
      apiData.data.getCompetition.posts.items.filter(function (e) {
        return e._deleted !== true;
      })
    );
  };

  const fetchPromotion = async () => {
    const apiData = await API.graphql({
      query: listAllPromotions,
    });
    setPromotion(apiData.data.listPromotions.items.filter(function (e) {
        return e.CompetitionID === competitionId;
      })
    );
    // setCompetitions(
    //   apiData.data.listCompetitions.items.filter(function (e) {
    //     return e._deleted !== true && e.hasEnded !== true;
    //   })
    // );
  
  };


  const updateActiveEmail = (e) => {
    setActiveEmail(e.target.value);
  }

  useEffect(async () => {
    fetchPromotion();
    fetchCompetitions();
  }, []);

  const getIGPostLikes = (
    link,
    id,
    isFinalPost,
    isLastPost,
    limit,
    referralsNum,
    version
  ) => {
    const scrapBee =
      "https://app.scrapingbee.com/api/v1/?api_key=6L7YKCH2A6W5PJU44RFELBW47RTGCOW9MCO4QG5OO6916BEGC4I46SNTN3RFDD7N4ZQ6O1ISVFHV1JYV&url=";
    const newLink =
      link.includes("?") && link.includes("instagram.com")
        ? scrapBee + link.split("?")[0]
        : scrapBee + link;
    axios
      .get(
        `${
          newLink.substring(newLink.length - 1) === "/"
            ? newLink
            : newLink + "/"
        }embed`
      )
      .then(async (data) => {
        const dataStr = data.data;
        let numOfLikes = dataStr.split('edge_liked_by":{"count":')[1];
        if (numOfLikes !== undefined && numOfLikes.split("}}})")[0]) {
          numOfLikes = numOfLikes.split("}}})")[0];
        } else {
          numOfLikes = parseFloat(
            dataStr
              .split("likeCountClick")[1]
              .split('target="_blank">')[1]
              .split("likes")[0]
              .replace(/,/g, "")
          );
        }
        const variables = {
          input: {
            id: id,
            likes: parseFloat(numOfLikes),
            _version: version,
          },
        };
        // const createPostResult = await API.graphql(
        //   graphqlOperation(updatePost, variables)
        // );
       
        return ;
      });
  };

  const getTikTokPostLikes = (
    link,
    postId,
    creator,
    isFinalPost,
    isLastPost,
    limit,
    referralsNum,
    version
  ) => {
    axios
      .get(
        `https://app.scrapingbee.com/api/v1/store/tiktok/user-feed?api_key=6L7YKCH2A6W5PJU44RFELBW47RTGCOW9MCO4QG5OO6916BEGC4I46SNTN3RFDD7N4ZQ6O1ISVFHV1JYV&username=${creator}`
      )
      .then((data) => {
        data.data.forEach(async (element) => {
          if (link.includes(element.webVideoUrl)) {
            const variables = {
              input: {
                id: postId,
                likes: element.diggCount,
                _version: version,
              },
            };
            console.log(element.diggCount + referralsNum * 100, isFinalPost);
            const createPostResult = await API.graphql(
              graphqlOperation(updatePost, variables)
            );
            if (isLastPost) {
              if (isFinalPost) {
                setIsLoading(false);
              } else {
                update(limit + 1);
              }
            }
            return createPostResult;
          }
        });
      });
  };

  const update = async (limit) => {
    const maxLength = limit + 1 > posts.length ? posts.length : limit + 1;
    for (let i = limit; i <= maxLength; i++) {
      const e = posts[i];
      if (e !== undefined) {
        const referralsArr = get(e, ["referrals", "items"], []);
        if (referralsArr.length > 0) {
        }
        const isFinalPost = i === maxLength - 1 && maxLength === posts.length;
        const isLastPost = i === maxLength - 1;
        console.log(e);
        if (e.platform === "tiktok") {
          await getTikTokPostLikes(
            e.link,
            e.id,
            e.creator,
            isFinalPost,
            isLastPost,
            limit,
            referralsArr.length,
            e._version
          );
        } else if (e.platform === "instagram") {
          await getIGPostLikes(
            e.link,
            e.id,
            isFinalPost,
            isLastPost,
            limit,
            referralsArr.length,
            e._version
          );
        }
      }
    }
  };

  const updateTime = async (id) => {
    setIsTimeLoading(true);
    const vs = {
      input: {
        id: competition.id,
        name: competition.name,
        lastUpdated: new Date().getTime(),
        _version: competition._version,
      },
    };
    const updateComp = await API.graphql(
      graphqlOperation(updateCompetition, vs)
    );

    setIsTimeLoading(false);
  };

  const handleCompetitionChange = (e) => {
    setCompetitionId(e.id);
  }

  let creators = [];

  return (
    <React.Fragment>
      <section className="container" id="home">
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col md="12" className="mt-4">
            <div
              className="bg-white p-2 mb-4 shadow"
              style={{ borderRadius: 8 }}
            >
              <h1 className="text-uppercase text-primary title-dark mt-2 mb-4 coming-soon">
                <span className="element">
                  {competition && competition.name}
                </span>
              </h1>
              <h4>Competition</h4>
              
              <select
                className="form-control custom-select"
                name="select"
                style={{ maxWidth: 200 }}
                value={activeEmail}
                onChange={updateActiveEmail}
              >
                <option value="">-</option>
                {promotion &&
                  promotion[0].creatorEmails.map((post, i) => {
                    console.log(creators);
                    if (!creators.includes(post)) {
                      creators.push(post);
                      return (
                        <option key={i} value={`${post}`}>{`${post}`}</option>
                      );
                    }
                    
                  })}
              </select>
              
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                maxWidth: "85%",
                margin: "auto",
              }}
            >
              {competition && competition.posts.items.map((e, key) => {
                if (e.email === activeEmail) {
                  return <PostBlock post={e} key={key} />;
                }
              })}
            </div>
          </Col>
        </Row>

        {/* <Row>
            <Col md="12">
              <Link
                to="#"
                onClick={this.togglemodal}
                className="btn btn-primary mt-4 mr-2"
              >
                <i className="mdi mdi-check"></i> Notify Me
              </Link>
              <Link to="/index" className="btn btn-outline-primary mt-4">
                <i className="mdi mdi-backup-restore"></i> Go Back Home
              </Link>
            </Col>
          </Row> */}
      </section>
    </React.Fragment>
  );
}

export default PromotionReview;
