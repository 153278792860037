// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Winners, Referral, Post, Competition } = initSchema(schema);

export {
  Winners,
  Referral,
  Post,
  Competition
};