import React, {useState,useEffect} from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import request from "ajax-request";
import { get } from "lodash";
import { AvForm, AvField } from "availity-reactstrap-validation";

function UpdateModal({modal, toggle, inquiryNew, setInquiryNew, contacts, assigned2 }) {
    const phone = get(inquiryNew, ["phone"], "");
    const firstName = get(inquiryNew, ["firstName"], "");
    const lastName = get(inquiryNew, ["lastName"], "");
    const assignedTo = get(inquiryNew, ["assignedTo"], "");
    const location = useLocation();
    let params = queryString.parse(location.search);
    const [incrementer, setIncrementer] = useState(0);
    const history = useHistory();
    const status = get(inquiryNew, ["status"], "Unseen");
    const id = get(inquiryNew, ["id"], "");
    const email = get(inquiryNew, ["email"], "");
    const handleFirstNameChange = (e) => {
        let copy = inquiryNew;
        copy.firstName = e.target.value;
        setInquiryNew(copy);
    };

    const handleLastNameChange = (e) => {
      let copy = inquiryNew;
      copy.lastName = e.target.value;
      setInquiryNew(copy);
    };
   
    const handleEmailChange = (e) => {
      let copy = inquiryNew;
      copy.email = e.target.value;
      setInquiryNew(copy);
    };
    const handlePhoneChange = (e) => {
      let copy = inquiryNew;
      copy.phone = e.target.value;
      setInquiryNew(copy);
    };
    const handleAssignedToChange = (e) => {
      let copy = inquiryNew;
      copy.assignedTo =
        `${contacts[e.target.value]["FIRSTNAME"]} ${contacts[e.target.value]["LASTNAME"]}`;
      copy.assignedToEmail = contacts[e.target.value]["EMAIL"];
      console.log(inquiryNew.assignedTo === assigned2);
      setInquiryNew(copy);

    };

    const handleStatusChange = (e) => {
      let copy = inquiryNew;
      copy.status = e.target.value;
      setInquiryNew(copy);
    };

    useEffect(() => {
      
    }, [contacts])
   
    async function update() {
      fetch(
        `https://www.songfluencer.me/portal2/inquiries/UpdateInquiry.cfm/?FirstName=${
          inquiryNew.firstName
        }&LastName=${inquiryNew.lastName}&Email=${
          inquiryNew.email
        }&AssignedTo=${inquiryNew.assignedTo}&Phone=${
          inquiryNew.phone
        }&InquiryStatus=${inquiryNew.status || "Unseen"}&id=${id}&Token=${params.LoginToken}`,
        { method: "POST" }
      ).then((res) => {
        if (res.ok) {
          if (inquiryNew.assignedTo === assigned2) {
            window.location.reload();
          } else {
            request(
              {
                url: `https://www.songfluencer.me/portal2/inquiries/SendAssignedToMail.cfm/?AssignedToName=${inquiryNew.assignedTo}&AssignedToEmail=${inquiryNew.assignedToEmail}&id=${inquiryNew.id}`,
                method: "POST",
                crossDomain: true,
              },
              function (err, res, body) {
                window.location.reload();
              }
            );
          }
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      });
        request(
          {
            url: `https://www.songfluencer.me/portal2/inquiries/UpdateInquiry.cfm/?FirstName=${
              inquiryNew.firstName
            }&LastName=${inquiryNew.lastName}&Email=${
              inquiryNew.email
            }&AssignedTo=${inquiryNew.assignedTo}&Phone=${
              inquiryNew.phone
            }&InquiryStatus=${inquiryNew.status || "Unseen"}&id=${id}`,
            method: "POST",
            https: true,
            crossDomain: true,
          },
          function (err, res, body) {
            
          }
        );
        
    }
    
    return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          Update Inquiry
        </ModalHeader>
        <ModalBody>
          <AvForm>
            <Row form>
              <Col className="col-12">
                <div className="mb-3">
                  <AvField
                    name="firstName"
                    label="First Name"
                    type="text"
                    errorMessage="Invalid First Name"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={handleFirstNameChange}
                    value={firstName}
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    name="lastName"
                    label="Last Name"
                    type="text"
                    errorMessage="Invalid Last Name"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={handleLastNameChange}
                    value={lastName}
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    type="text"
                    errorMessage="Invalid email"
                    onChange={handleEmailChange}
                    validate={{
                      required: { value: true },
                    }}
                    value={email}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="phone"
                    label="Phone"
                    type="text"
                    onChange={handlePhoneChange}
                    errorMessage="Invalid phone"
                    value={phone}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="assignedTo"
                    label="Assigned To"
                    type="select"
                    className="form-select"
                    value={assignedTo.length > 0 ? assignedTo : ""}
                    defaultValue={assignedTo}
                    onChange={handleAssignedToChange}
                  >
                    {assignedTo ? (
                      <option value={assignedTo} disabled>{assignedTo}</option>
                    ) : (
                      <option value="" disabled></option>
                    )}

                    {contacts && contacts.map((c, i) => {
                      return (
                        <option key={i} value={i}>
                          {c.FIRSTNAME + " " + c.LASTNAME}
                        </option>
                      );
                    })}
                  </AvField>
                </div>

                <div className="mb-3">
                  <AvField
                    name="inquiryStatus"
                    label="Inquiry Status"
                    type="select"
                    className="form-select"
                    errorMessage="Invalid Payment Status"
                    validate={{
                      required: { value: true },
                    }}
                    value={status.length > 0 ? status : "Unseen"}
                    defaultValue={status}
                    onChange={handleStatusChange}
                  >
                    <option value="Unseen">Unseen</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                    onClick={async ()=> {
                      update();
                    }}
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    );
}

export default UpdateModal;
