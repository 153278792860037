import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { create, get } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { updatePost, updateCompetition, updateWinners, deleteWinners, createWinners } from "../../graphql/mutations";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { listWinnerss, listPayments } from "../../graphql/queries";

function Winners() {
  const [isDone, setIsDone] = useState(false);
  const [page, setPage] = React.useState(0);
  const [winners, setWinners] = useState([]);
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [createModal, setCreateModal] = useState(false);
  const [isBanned, setIsBanned] = useState(false);
  const [email,setEmail] = useState();
  const [paypal, setPayPal] = useState();
  const handleEmailChange = (e) =>{
    setEmail(e.target.value);
  }
  const handlePayPalChange = (e) => {
    setPayPal(e.target.value);
  }
  const toggleCreateModal = () =>{
    setCreateModal(!createModal);
  }
  const [sortByKey, setSortByKey] = useState("Balance");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [competitions, setCompetitions] = useState([]);
  const determineState = (comp) => {
    if (comp.hasEnded === true) {
      return "completed";
    } else if (comp.isActive === true) {
      return "active";
    } else {
      return "preview";
    }
  };
  
  const ban = async (e,row) => {
    const vs = {
      input: {
        id: row.id,
        isBanned: e === "False" ? false : true,
        _version: row._version,
      },
    };
    const updateWinner = await API.graphql(graphqlOperation(updateWinners, vs));
    setWinners([]);
    fetchWinners();
  };

  const vet = async (e,row) => {
    const vs = {
      input: {
        id: row.id,
        isVetted: e === "False" ? false : true,
        _version: row._version,
      },
    };
    const updateWinner = await API.graphql(graphqlOperation(updateWinners, vs));
    setWinners([]);
    fetchWinners();
  };

  const unsubscribe = async (e, row) => {
    const vs = {
      input: {
        id: row.id,
        isUnsubscribed: e === "False" ? false : true,
        _version: row._version,
      },
    };
    const updateWinner = await API.graphql(graphqlOperation(updateWinners, vs));
    setWinners([]);
    fetchWinners();
  };

  const deleteRow = async (row) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const vs = {
        input: {
          id: row.id,
          _version: row._version,
        },
      };
      const updateWinner = await API.graphql(graphqlOperation(deleteWinners, vs));
      setWinners([]);
      fetchWinners();
    }
  };
  const [filter, setFilter]=useState(params.q ? params.q : "");
  const filterByEmail = (e) => {
    setFilter(e.target.value);
  };
  const handleIsBanned = (e) => {
    setIsBanned(!isBanned);
  };
  function compare(a, b) {
    if (sortByKey === "Email") {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    } else if (sortByKey === "PayPal") {
      if (a.paypal < b.paypal) {
        return -1;
      }
      if (a.paypal > b.paypal) {
        return 1;
      }
      return 0;
    } else if (sortByKey === "Balance") {
      if (a.balance > b.balance) {
        return -1;
      }
      if (a.balance < b.balance) {
        return 1;
      }
      return 0;
    }
  }
  const fetchWinners = async () => {
    const myWinners = [];
    let done = false;
    let nToken = false;
    while (!done) {
      const apiData = await API.graphql({
        query: listWinnerss,
        variables: {
          limit: 1000,
          nextToken: !nToken ? null : nToken,
        },
      });
      const myWinnersTemp = get(apiData, ["data", "listWinnerss", "items"], []);
      myWinnersTemp.map((e) => {
        if (e._deleted !== true) {
          myWinners.push(e);
        }
      });

      nToken = apiData.data.listWinnerss.nextToken;
      if (nToken === null) {
        done = true;
      }
    }
    setWinners(myWinners.filter(function (e) {
        return e._deleted !== true;
      }).sort(compare));
  };
  
  const columns = [
    {
      id: 1,
      label: "Email",
    },
    {
      id: 2,
      label: "PayPal",
    },
    {
      id: 2,
      label: "Balance",
    },
    {
      id: 3,
      label: "Is Banned",
    },
    {
      id: 3,
      label: "Is Vetted",
    },
    {
      id: 4,
      label: "Is Unsubscribed",
    },
    {
      id: 5,
      label: "Delete",
    },
  ];
  const updateCompetitionState = async (newVal, r) => {
    let vs = {};
    if (newVal === "completed") {
      vs = {
        input: {
          id: r.id,
          hasEnded: true,
          isActive: false,
          _version: r._version,
        },
      };
    } else if (newVal === "active") {
      vs = {
        input: {
          id: r.id,
          hasEnded: false,
          isActive: true,
          _version: r._version,
        },
      };
    } else {
      vs = {
        input: {
          id: r.id,
          hasEnded: false,
          isActive: false,
          _version: r._version,
        },
      };
    }
    const updateComp = await API.graphql(
      graphqlOperation(updateCompetition, vs)
    );
    window.location.reload();
  };

  const [currentBalance, setCurrentBalance] = useState();
  const addWinner = async () => {
    const inputData = {
      email: email,
      paypal: paypal,
      isBanned: isBanned
    };
    await API.graphql(
      graphqlOperation(createWinners, { input: inputData })
    );
    window.location.reload();
  }
  const checkBalance = async (row) => {
    const myPayments = [];
    let done = false;
    let nToken = false;
    const apiData = await API.graphql({
      query: listPayments,
      variables: { filter: { ContactEmail: { eq: row.email } }, limit: 100000 },
    });
    let tempBalance = 0;

    apiData.data.listPayments.items.map(e=>{
       if (!e._deleted && e.Status === "To Be Paid") {
         tempBalance = tempBalance + e.Amount;
       }
    });
    alert(tempBalance);
  }

  useEffect(() => {
    fetchWinners();
  }, []);
  let winnersArr = winners.filter(function (e) {
    if (e.email && e.paypal) {
      return (
        e.email.toLowerCase().includes(filter.toLowerCase()) ||
        e.paypal.toLowerCase().includes(filter.toLowerCase())
      );
    } else if(e.email) {
      return (
        e.email.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return false;
      
    }).sort(compare);
  return (
    <React.Fragment>
      <br />
      <br />
      <section className="container pb-4 mt-5" id="home">
        <Paper style={{ width: "100%" }}>
          <TableContainer>
            <div
              className="d-flex shadow"
              style={{ justifyContent: "space-between" }}
            >
              <h4 className="mt-2 ml-3 p-3 mb-1" style={{ marginBottom: 0 }}>
                Winners
              </h4>
              <div>
                <input
                  type="text"
                  className="shadow p-2 mr-2 mt-3"
                  placeholder="Search by email"
                  onChange={filterByEmail}
                  style={{
                    maxHeight: 40,
                    border: "1px solid rgba(224, 224, 224, 1)",
                    borderRadius: 6,
                  }}
                />
                <Button
                  className="btn-md btn-primary"
                  style={{ marginLeft: 12, marginRight: 12 }}
                  onClick={() => {
                    toggleCreateModal();
                  }}
                >
                  Create Winner
                </Button>
              </div>
            </div>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.id}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSortByKey(column.label);
                          }}
                        >
                          {column.label === sortByKey ? (
                            <b>{column.label}</b>
                          ) : (
                            column.label
                          )}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {winnersArr
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        // onClick={() =>
                        //   window.open(`https://preffy.com/c/${row.id}`, "_blank")
                        // }
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell key={row.id}>{row.email}</TableCell>
                        <TableCell key={row.id}>{row.paypal}</TableCell>
                        <TableCell key={row.id}>
                          <Button
                            size="sm"
                            onClick={() => {
                              checkBalance(row);
                            }}
                          >
                            Check Balance
                          </Button>
                        </TableCell>
                        <TableCell key={row.id}>
                          <select
                            className="form-control custom-select"
                            id="Sortbylist-job"
                            value={row.isBanned ? "True" : "False"}
                            onChange={(a) => {
                              ban(a.target.value, row);
                            }}
                          >
                            <option value="False">False</option>
                            <option value="True">True</option>
                          </select>
                        </TableCell>
                        <TableCell key={row.id}>
                          <select
                            className="form-control custom-select"
                            id="Sortbylist-job"
                            value={row.isVetted ? "True" : "False"}
                            onChange={(a) => {
                              vet(a.target.value, row);
                            }}
                          >
                            <option value="False">False</option>
                            <option value="True">True</option>
                          </select>
                        </TableCell>
                        <TableCell key={row.id}>
                          <select
                            className="form-control custom-select"
                            id="Sortbylist-job"
                            value={row.isUnsubscribed ? "True" : "False"}
                            onChange={(a) => {
                              unsubscribe(a.target.value, row);
                            }}
                          >
                            <option value="False">False</option>
                            <option value="True">True</option>
                          </select>
                        </TableCell>
                        <TableCell key={row.id}>
                          <Button
                            size="sm"
                            onClick={() => {
                              deleteRow(row);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={winnersArr.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </section>
      <Modal isOpen={createModal} toggle={toggleCreateModal}>
        <ModalHeader toggle={toggleCreateModal} tag="h4">
          Add A Winner
        </ModalHeader>
        <ModalBody>
          <AvForm>
            <AvGroup className="form-group position-relative">
              <Label>Email</Label>
              <AvField
                type="text"
                className="form-control pl-5"
                name="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Ex. charlie@preffy.com"
                errorMessage=""
              />
            </AvGroup>
            <AvGroup className="form-group position-relative mt-3">
              <Label>PayPal</Label>
              <AvField
                type="text"
                className="form-control pl-5"
                name="paypal"
                id="paypal"
                value={paypal}
                onChange={handlePayPalChange}
                placeholder="Ex. charlie@preffy.com"
                errorMessage=""
              />
            </AvGroup>
            <AvGroup className="form-group position-relative mt-3">
              <Label>Is Banned?</Label>
              <br />
              <input
                type="checkbox"
                checked={isBanned}
                onChange={handleIsBanned}
              />
            </AvGroup>
          </AvForm>
          <Row className="mt-3">
            <Col>
              <div className="text-end">
                <button onClick={()=>{
                  addWinner();
                }} className="btn btn-primary save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default Winners;
