import React, {useState} from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import request from "ajax-request";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AvForm, AvField } from "availity-reactstrap-validation";


function AddANoteModal({modal, toggle, DisplayName, InquiryID, token}) {
  const [note, setNote] = useState();
  const addNote = () => {
    request(
      {
        url: `https://www.songfluencer.me/portal2/inquiries/InsertNote.cfm/?id=${InquiryID}&Token=${token}&displayName=${DisplayName}&noteMessage=${note}`,
        method: "POST",
        crossDomain: true,
      },
      function (err, res, body) {
        window.location.reload();
      }
    );
  }
    return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          Add A Note
        </ModalHeader>
        <ModalBody>
          <CKEditor
            editor={ClassicEditor}
            data=""
            height="500"
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              console.log({ event, editor, data });
              setNote(data);
            }}
            onBlur={(event, editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              console.log("Focus.", editor);
            }}
          />
          <Row className="mt-3">
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-primary save-user" onClick={addNote}>
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
}

export default AddANoteModal;
