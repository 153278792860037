import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { FancyCompetitionEmail } from "./fancy-competition-email.js";
import { PromotionEmail } from "./promotion-email.js";
import { BasicCompetitionEmail } from "./basic-competition-email.js";
import {
  listAllWinners,
  listAllCompetitions,
  listAllPromotions,
} from "../../graphql/queries";
import {
  Col,
  Row,
  FormGroup,
  Modal,
  Label,
  ModalHeader,
  ModalBody,
} from "reactstrap";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, setEmailModal, emailModal } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Emails
        </Typography>
      )}

      {numSelected > 0 && (
        <div className="btn btn-secondary" style={{cursor:'pointer'}} onClick={()=>{setEmailModal(!emailModal)}}>
          Email
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,

};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [emailModal, setEmailModal] = useState(false);
  const [email, setEmail] = useState('fancy');
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [selectedCompetitionName, setSelectedCompetitionName] = useState(null);
  const [selectedCompetitionID, setSelectedCompetitionID] = useState(null);
  const [selectedCompetitionPrizes, setSelectedCompetitionPrizes] = useState(0);
  const [selectedCompetitionOrganizer, setSelectedCompetitionOrganizer] = useState(null);
  const [competitions, setCompetitions] = useState([]);

  const calcTotalPrizes = (prizes) => {
    if (prizes) {
      let sum = 0;
      // let sum = prizes[0].includes("4th") ? 300 : 0;
      prizes.forEach((element, i) => {
        let val = element.split("$")[1];
        let label = element.split("$")[0];
        if (label.includes("-") && !label.includes("Followers")) {
          const rangeLow = element.split("-")[0];
          const rangeLowVal = rangeLow.substring(0, rangeLow.length - 2);
          const rangeHigh = element.split("-")[1].split(" Place")[0];
          const rangeHighVal = rangeHigh.substring(0, rangeHigh.length - 2);
          const multiplier = parseInt(rangeHighVal) - parseInt(rangeLowVal) + 1;
          val = val * multiplier;
        } else if (label.includes("Top")) {
          const amountOfWinners = parseInt(label.split(" ")[1]);
          val = amountOfWinners * val;
        }
        sum += parseFloat(val);
      });
      return "$" + sum;
    }
  };
  const handleSelectedCompetitionChange = (e) => {
    setSelectedCompetition(e.target.value);
    competitions.map(a => {
      if (a.id === e.target.value) {
        setSelectedCompetitionName(a.name);
        setSelectedCompetitionID(a.id);
        setSelectedCompetitionOrganizer(a.organizer);
        setSelectedCompetitionPrizes(calcTotalPrizes(a.prizes));
      }
    })
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const sendEmail = async () => {
    const emailList = [];
    selected.map(e => {
      emailList.push({email: e})
    });
    if (email === 'fancy') {
      await FancyCompetitionEmail(
        emailList,
        selectedCompetitionPrizes,
        selectedCompetitionName,
        selectedCompetitionOrganizer,
        `https://preffy.com/c/${selectedCompetition}?ref=fancy`,

      );
    } else if(email === 'promotion') {
      let totalAmount;
      let incrementAmount;
      promotions.map(e => {
        if (selectedCompetitionID === e.CompetitionID) {
          totalAmount = e.RemainingBalance;
          incrementAmount = e.Amount;
        }
      })
      if (totalAmount > 0 && incrementAmount > 0) {
        await PromotionEmail(
          emailList,
          totalAmount,
          selectedCompetitionName,
          selectedCompetitionOrganizer,
          `https://preffy.com/c/${selectedCompetition}?ref=fancy`,
          incrementAmount,
        );
      }
      
      
    } else {
      await BasicCompetitionEmail(
        emailList,
        selectedCompetitionPrizes,
        selectedCompetitionName,
        selectedCompetitionOrganizer,
        `https://preffy.com/c/${selectedCompetition}?ref=basic`,
      );
    }
    setIsLoading(true);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const [rows,setRows] = useState([]);

  const isSelected = (email) => selected.indexOf(email) !== -1;
  const fetchWinners = async () => {
    const apiData = await API.graphql({ query: listAllWinners });
    const winnersArr = [];
    const emailsArr = [];
    apiData.data.listWinnerss.items.map(e => {
      if (e._deleted !== true && e.isUnsubscribed !== true && !emailsArr.includes(e.email)) {
        winnersArr.push(e);
        emailsArr.push(e.email);
      }
    });
    setRows(
      winnersArr
    );
  };

  const [promotions, setPromotions] = useState();

  const fetchPromotions = async () => {
    const apiData = await API.graphql({
      query: listAllPromotions,
      variables: { limit: 100000 },
    });
    setPromotions(
      apiData.data.listPromotions.items.filter(function (e) {
        return e._deleted !== true;
      })
    );
  };

  const fetchCompetitions = async () => {
    const apiData = await API.graphql({ query: listAllCompetitions, variables: {limit: 100000} });
    setCompetitions(
      apiData.data.listCompetitions.items.filter(function (e) {
        return e._deleted !== true;
      })
    );
  };
  useEffect(() => {
    fetchPromotions();
    fetchWinners();
    fetchCompetitions();
  }, []);
  const toggleEmailModal = () => {
    setEmailModal(!emailModal);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className="container" style={{ marginTop: 80 }}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          setEmailModal={setEmailModal}
          emailModal={emailModal}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.email);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.email)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.email}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.email}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Modal isOpen={emailModal} toggle={toggleEmailModal}>
        <ModalHeader toggle={toggleEmailModal} tag="h4">
          Send An Email To {selected.length} Users
        </ModalHeader>
        <ModalBody>
          <AvForm>
            {!isLoading ? (
              <Row className="mt-4">
                <Col md="12" className="mb-3">
                  <AvGroup className="form-group position-relative">
                    <Label>Email Type</Label>
                    <select
                      className="form-control custom-select"
                      style={{ maxWidth: 300 }}
                      onChange={handleEmailChange}
                    >
                      <option value="fancy">Fancy</option>
                      <option value="promotion">Promotion</option>
                      <option value="basic">Basic</option>
                    </select>
                  </AvGroup>
                </Col>
                <Col md="12" className="mb-3">
                  <AvGroup className="form-group position-relative">
                    <Label>Competition</Label>
                    <select
                      className="form-control custom-select"
                      style={{ maxWidth: 300 }}
                      onChange={handleSelectedCompetitionChange}
                    >
                      {competitions.map((e, i) => {
                        return (
                          <option
                            value={`${e.id}`}
                            key={i}
                            selected={
                              selectedCompetition &&
                              e.id === selectedCompetition.id
                            }
                          >
                            {e.name}
                          </option>
                        );
                      })}
                    </select>
                  </AvGroup>
                </Col>

                {/* <Col md="12" className="mb-3">
                  <select
                    className="form-control custom-select"
                    style={{ maxWidth: 200 }}
                    onChange={handleCompetitionChange}
                  >
                    {competitions.map((e, i) => {
                      return (
                        <option
                          value={`${e.id}`}
                          key={i}
                          selected={e.id === competitionId}
                        >
                          {`${e.organizer}-${e.name}`}
                        </option>
                      );
                    })}
                  </select>
                </Col> */}
              </Row>
            ) : (
              <h3>Succesfully Added</h3>
            )}

            <br />
            <Row>
              <Col sm="12">
                <input
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn btn-secondary float-right"
                  value={isLoading ? "Done" : "Submit"}
                  disabled={isLoading}
                  onClick={sendEmail}
                />
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}
