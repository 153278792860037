import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import {
  Button,
} from "reactstrap";
import { listWinnerss } from "../../graphql/queries";
import countriesContinentList from "./countries-continents";
import MuiButton from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { DisqualificationEmail } from "./disqualification-email";
import { NonOriginalContent } from "./non-originalcontent";
import { CreativeDirectionIssue } from "./creativedirectionissue";
import { GeneralDisqualification } from "./generaldisqualification";
import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  updatePost,
  updateCompetition,
  deletePost,
} from "../../graphql/mutations";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PostBlock({ post, startDate, idArr, winners, countries, continents }) {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  let params = queryString.parse(location.search);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getYTID(pLink) {
    const pattern = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([\w.-]+)(?:\/|\?|$)/;
  
    const match = pLink.match(pattern);
      
      if (match && match[1]) {
          return match[1];
      } else {
          return null; // Return null if no match is found
      }
  }

  const handleDelete = async () => {

    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    await DisqualificationEmail(post.email, post.link);
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const handleDeleteOC = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    await NonOriginalContent(post.email, post.link);
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const handleDeleteCD = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    await CreativeDirectionIssue(post.email, post.link);
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const handleDeleteGeneral = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    await GeneralDisqualification(post.email, post.link);
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const handleDeleteDC = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    await CreativeDirectionIssue(post.email, post.link);
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const handleOnlyDelete = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const [isBanned, setIsBanned] = useState(false);
  const [views, setViews] = useState();
  const [likes, setLikes] = useState();
  const [comments, setComments] = useState();
  const [wasDeleted, setWasDeleted] = useState("Delete");
  const bonusLikes = get(post, ["bonusLikes"], 0);
  const [value, setValue] = useState(post.likes - bonusLikes);
  const [newValue, setNewValue] = useState(null);
  const [error, setError] = useState(null);
  const [bonus, setBonus] = useState(0);
  const [link, setLink] = useState(post.link);
  const [country, setCountry] = useState("");
  const [language, setLanguage] = useState("");
  const [hasUpdated, setHasUpdated] = useState(false);
  const [referralsNum, setReferralsNum] = useState(post.referrals.items.length);
  const [videoId, setVideoId] = useState();
  const [isLoading, setIsLoading]= useState(true);
  const getContinentByCountryCode = (countryCode) => {
    const mapping = countriesContinentList.find(item => item.CC === countryCode);
    return mapping.continent;
  };
  const isValidCountry = (myCountry) => {
    const validCountry = !countries || ((!myCountry || countries.includes(myCountry)));
    const validContinent = !continents || (continents.includes(getContinentByCountryCode(myCountry)));
    if (validCountry && validContinent) {
      return true;
    }
    return false;
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleBonusChange = (e) => {
    setBonus(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    return t.toDateString();
  };
  function getVideoId() { 
    if (post.link.includes("tiktok")) {
      if (
        post.link.includes("vt.tiktok") ||
        post.link.includes("vm.tiktok") ||
        post.link.includes("tiktok.com/t/") ||
        post.link.includes("tiktok.com")
      ) {
        const pLink = post.link.includes("https://tiktok.com") ? post.link.replace("https://", "https://www.") : post.link;
        fetch(
          `https://social-media-data-tt.p.rapidapi.com/live/post/meta/v2?video=${pLink}`,
          {
            method: "GET",
            headers: {
              "x-rapidapi-key":
                "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
              "x-rapidapi-host": "social-media-data-tt.p.rapidapi.com",
            },
          }
        )
          .then((res) => res.text())
          .then(async (response) => {
            const resultObj = JSON.parse(response);

            setLink(
              `https://tiktok.com/@${resultObj.authorMeta.unique_id}/video/${resultObj.id}`
            );
            setCountry(resultObj.country);
            if (!isValidCountry(resultObj.country)) {
              await handleOnlyDelete();
              setError("Invalid Country");
            }
            setVideoId(resultObj.id);
            setViews(resultObj.playCount);
            setLikes(resultObj.diggCount);
            const startDateMidnight = new Date(startDate);
            startDateMidnight.setHours(0, 0, 0, 0);
            if (
              new Date(startDateMidnight).getTime() >
              new Date(calcDateValue(resultObj.createTime)).getTime()
            ) {
              await handleOnlyDelete();
              setError("Invalid Post Date");
            }
            if (resultObj.diggCount > resultObj.playCount && resultObj.diggCount > 10) {
              await handleOnlyDelete();
              setError("CHEATING! Likes exceed views");
            } else if ((resultObj.diggCount / resultObj.playCount) > .5 && resultObj.diggCount > 1000) {
              await handleOnlyDelete();
              setError("Investigate further for cheating");
            }
          })
          .catch(async (err) => {
            console.error("yoyoyoyo",err);
            await fetch(
              `https://tiktok-scraper7.p.rapidapi.com/?url=${pLink}&hd=1`,
              {
                method: "GET",
                headers: {
                  "x-rapidapi-key":
                    "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
                  "x-rapidapi-host": "tiktok-scraper7.p.rapidapi.com",
                },
              }
            )
              .then(async (res) => {
                if (res.ok) {
                  return res.text();
                } else {
                  console.log("failed");
                }
                throw res;
              })
              .then(async (result) => {
                const resultObj = JSON.parse(result);
                setLink(
                  `https://tiktok.com/@${resultObj.data.author.unique_id}/video/${resultObj.data.id}`
                );
                setCountry(resultObj.data.region);
                if (!isValidCountry(resultObj.data.region)) {
                  await handleOnlyDelete();
                  setError("Invalid Country");
                }
                setVideoId(resultObj.data.id);
                setViews(resultObj.data.play_count);
                setLikes(resultObj.data.digg_count);
                const startDateMidnight = new Date(startDate);
                startDateMidnight.setHours(0, 0, 0, 0);
                if (
                  new Date(startDateMidnight).getTime() >
                  new Date(calcDateValue(resultObj.data.create_time)).getTime()
                ) {
                  await handleOnlyDelete();
                  setError("Invalid Post Date");
                }
                if (resultObj.data.digg_count > resultObj.data.play_count && resultObj.data.digg_count > 10) {
                  await handleOnlyDelete();
                  setError("CHEATING! Likes exceed views");
                } else if ((resultObj.data.digg_count / resultObj.data.play_count) > .5 && resultObj.data.digg_count > 1000) {
                  await handleOnlyDelete();
                  setError("Investigate further for cheating");
                }
              })
          });
      } else {
        if (post.link.includes("tiktok")) {
          setLink(post.link);
          if (post.link.includes("/video/")) {
            setVideoId(post.link.split("/video/")[1].split("?")[0]);
          }
        }
      }
    } else if (post.link.includes("instagram")) {
      const regex = /(?:reels|reel)\/([\w-]+)[\/?]?/i;
      const match = post.link.match(regex);
      if (match && match[1]) {
        setVideoId(match[1]);
      } else {
        return null;
      }
      const resp = fetch(
        `https://instagram-scraper-api2.p.rapidapi.com/v1/post_info?code_or_id_or_url=https://www.instagram.com/p/${match[1]}&include_insights=true`,
        {
          method: "GET",

          headers: {
            "X-RapidAPI-Host": "instagram-scraper-api2.p.rapidapi.com",
            "X-RapidAPI-Key":
              "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
          },
        }
      )
        .then((response) => response.text())
        .then(async (result) => {

          const resultObj = JSON.parse(result);
          console.log("result", resultObj);
          const likeNum = get(
            resultObj,
            ["data","metrics","like_count"],
            0
          );
          setLikes(likeNum);
          const viewNum = get(
            resultObj,
            ["data","metrics","play_count",],
            0
          );
          setViews(viewNum);
          const commentNum = get(
            resultObj,
            ["data","metrics","comment_count"],
            0
          );
          setComments(commentNum)
          const startDateMidnight = new Date(startDate);
          startDateMidnight.setHours(0, 0, 0, 0);
          if (
            new Date(startDateMidnight).getTime() >
            new Date(calcDateValue(resultObj.taken_at_timestamp)).getTime()
          ) {
            await handleOnlyDelete();
            setError("Invalid Post Date");
          }
          if (likeNum > viewNum && likeNum > 10) {
            await handleOnlyDelete();
            setError("CHEATING! Likes exceed views");
          } else if ((likeNum / viewNum) > .7 && likeNum > 1000) {
            await handleOnlyDelete();
            setError("Investigate further for cheating");
          }
        })
        .catch((error) => {
          console.log(error);
        });

    } else {
      setVideoId(getYTID(post.link));
      console.log("post link",post.link);
      const resp = fetch(
        `https://youtube138.p.rapidapi.com/video/details?id=${getYTID(post.link)}`,
        {
          method: "GET",

          headers: {
            "X-RapidAPI-Host": "youtube138.p.rapidapi.com",
            "X-RapidAPI-Key":
              "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
          },
        }
      )
        .then((response) => response.text())
        .then(async (result) => {

          const resultObj = JSON.parse(result);
          console.log("result YT", resultObj);
          const likeNum = get(
            resultObj,
            ["stats","likes"],
            0
          );
          setLikes(likeNum);
          const viewNum = get(
            resultObj,
            ["stats","views"],
            0
          );
          setViews(viewNum);
          const commentNum = get(
            resultObj,
            ["stats","comments"],
            0
          );
          setComments(commentNum);
          const publishedAt = get(resultObj, ["items",0,"snippet","publishedAt"],"");
          const startDateMidnight = new Date(startDate);
          startDateMidnight.setHours(0, 0, 0, 0);
          if (
            new Date(startDateMidnight).getTime() >
            new Date(publishedAt).getTime()
          ) {
            await handleOnlyDelete();
            setError("Invalid Post Date");
          }
          if (parseInt(likeNum) > parseInt(viewNum) && parseInt(likeNum) > 10) {
            await handleOnlyDelete();
            setError("CHEATING! Likes exceed views");
          } else if ((parseInt(likeNum) / parseInt(viewNum)) > .7 && parseInt(likeNum) > 1000) {
            await handleOnlyDelete();
            setError("Investigate further for cheating");
          }

        })
        .catch((error) => {
          console.log(error);
        });
    }



    return false;
  }
  console.log(error);

  const getIsBanned = async () => {
    const myWinners = [];
    let done = false;
    let nToken = false;
    while (!done) {
      const apiData = await API.graphql({
        query: listWinnerss,
        variables: {
          limit: 1000,
          filter: { isBanned: { eq: true } },
          nextToken: !nToken ? null : nToken,
        },
      });
      const myWinnersTemp = get(apiData, ["data", "listWinnerss", "items"], []);
      myWinnersTemp.map((e) => {
        if (e._deleted !== true) {
          myWinners.push(e);
        }
      });

      nToken = apiData.data.listWinnerss.nextToken;
      if (nToken === null) {
        done = true;
      }
    }

    const apiData = myWinners.filter(function (e) {
      return e._deleted !== true && e.email === post.email;
    });
    if (apiData && apiData.length > 0 && apiData.filter(e => e.isBanned === true).length > 0) {
      await handleOnlyDelete();
      setError("Banned User");
    }
  }

  useEffect(() => {
    getIsBanned();

    // const myHeaders = new Headers();
    // myHeaders.append("apikey", "eKf13aXEAZYaYH34Gcgg5fYMqlb6GJT2");

    // const requestOptions = {
    //   method: "GET",
    //   redirect: "follow",
    //   headers: myHeaders,
    // };
    // if (getVideoId() && post.bonusLikes !== null) {
    //   fetch(
    //     `https://api.promptapi.com/tiktok/video/${getVideoId()}`,
    //     requestOptions
    //   )
    //     .then((response) => response.text())
    //     .then(async (result) => {
    //       const resultObj = JSON.parse(result);
    //       const likeCount = get(
    //         resultObj,
    //         ["itemStruct", "stats", "diggCount"],
    //         value
    //       );
    //       if (likeCount !== value) {
    //         setValue(likeCount);
    //         updateNewVal(likeCount);
    //       }
    //     })
    //     .catch((error) => console.log("error", error));
    // }
    getVideoId();

  }, []);


  const updateLove = async () => {
    const variables = {
      input: {
        id: post.id,
        likes: parseFloat(value) + parseFloat(bonus),
        bonusLikes: bonus,
        creativityScore: 100,
        link: link,
        _version: post._version,
      },
    };
    const createPostResult = await API.graphql(
      graphqlOperation(updatePost, variables)
    );
    setHasUpdated(true);
  };
  const update = async () => {
    const variables = {
      input: {
        id: post.id,
        likes: parseFloat(value) + parseFloat(bonus),
        bonusLikes: bonus,
        creativityScore: 10,
        link: link,
        _version: post._version,
      },
    };
    const createPostResult = await API.graphql(
      graphqlOperation(updatePost, variables)
    );
    setHasUpdated(true);
  };

  const updateNewVal = async (newVal) => {
    const variables = {
      input: {
        id: post.id,
        likes: parseFloat(newVal) + parseFloat(bonus),
        bonusLikes: bonus,
        link: link,
        _version: post._version,
      },
    };
    const createPostResult = await API.graphql(
      graphqlOperation(updatePost, variables)
    );
    setHasUpdated(true);
  };

  function getIGEmbedLink() {
    const regex = /(?:reels|reel)\/([\w-]+)[\/?]?/i;
    const match = post.link.match(regex);

    if (match && match[1]) {
      const id = match[1];
      return `https://instagram.com/reel/${id}/embed`;
    } else {
      return null;
    }
  }

  function getYTEmbed() {
    if (post.link.includes("shorts/")) {
      if (post.link.includes("?")) {
        return post.link.split("shorts/")[1].split("?")[0];
      } else {
        return post.link.split("shorts/")[1];
      }
    } else {
      return '';
    }
  }
  useEffect(async () => {
    console.log("idArr", idArr, videoId);
    if (idArr.includes(videoId) && videoId !== null) {
      console.log("554",idArr,videoId);
      await handleOnlyDelete();
      setError("Possible Duplicate");
    }
  }, [videoId])


  const returnIFrame = () => {
    if (post.platform === "tiktok" && videoId) {
      return (
        <>
          <iframe
            src={`https://www.tiktok.com/embed/${videoId}`}
            scrolling="no"
            width="330"
            height="740"
            style={{
              maxWidth: "100%",
              width: 330,
              height: 740,
              border: "none",
            }}
          ></iframe>
          <span>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <a href={post.link} target="_blank" rel="noreferrer">
                Link{" "}
              </a>
              <a style={{ marginLeft: 8 }} href={`/preffy/winners?LoginToken=${params.LoginToken}&q=${post.email}`} target="_blank" rel="noreferrer">
                Vet{" "}
              </a>
            </div>
            {post.email}
            <br />
            {country === post.country ? (
              <span className="text-success">tiktok:{country} - preffy:{post.country}</span>
            ) : (
              <span className="text-danger">tiktok:{country} - preffy:{post.country}</span>
            )}
            <br/>
            <span style={{color:'black'}}>
                <span>Views: {views}</span><br/>
                <span>Likes: {likes}</span><br/>
            </span>
          <span className="text-danger">{error}</span>
          </span>
          <input value={link} onChange={handleLinkChange} />
          {referralsNum > 0 ? referralsNum : ""}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="btn btn-md bg-danger text-white"
              onClick={updateLove}
              style={{ width: "100%" }}
            >
              {hasUpdated ? "Done" : "Love"}
            </div>
            <div
              className="btn btn-md bg-success text-white"
              onClick={update}
              style={{ width: "100%" }}
            >
              {hasUpdated ? "Done" : "Like"}
            </div>
          </div>
          <Button
            variant="outlined"
            color="primary"
            className="btn-outline-secondary text-white"
            onClick={handleClickOpen}
          >
            {wasDeleted}
          </Button>
        </>
      );
    } else {
      if (post.platform === "tiktok") {
        return (
          <>
            <div style={{
              maxWidth: '330px',
              width: 330,
              height: 740,
              border: "none",
              marginRight: '4px',
              marginLeft: '4px',
              background: '#fff',
              display: 'flex',
              alignItems: 'center'
            }}>
              <div className="loader" style={{ margin: "auto" }}></div>

            </div>
            <span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <a href={post.link} target="_blank" rel="noreferrer">
                  Link{" "}
                </a>
                <a style={{ marginLeft: 8 }} href={`/preffy/winners?${params.LoginToken}&q=${post.email}`} target="_blank" rel="noreferrer">
                  Vet{" "}
                </a>
              </div>
              <br />
              {post.email}
              <br />
              <span className="text-danger">{error}</span>
            </span>
            <input value={link} onChange={handleLinkChange} />
          {referralsNum > 0 ? referralsNum : ""}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="btn btn-md bg-danger text-white"
              onClick={updateLove}
              style={{ width: "100%" }}
            >
              {hasUpdated ? "Done" : "Love"}
            </div>
            <div
              className="btn btn-md bg-success text-white"
              onClick={update}
              style={{ width: "100%" }}
            >
              {hasUpdated ? "Done" : "Like"}
            </div>
          </div>
          <Button
            variant="outlined"
            color="primary"
            className="btn-outline-secondary text-white"
            onClick={handleClickOpen}
          >
            {wasDeleted}
          </Button>
          </>
        )

      } else if (post.platform !== "tiktok") {
        console.log("not tiktok");
        return (
          <>
            <iframe
              src={
                link.includes("instagram")
                  ? `${getIGEmbedLink()}`
                  : `https://www.youtube.com/embed/${getYTEmbed()}`
              }
              scrolling="no"
              width="330"
              height="740"
              style={{
                maxWidth: "100%",
                width: 370,
                height: 740,
                border: "none",
              }}
            ></iframe>
            <span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <a href={post.link} target="_blank" rel="noreferrer">
                  Link{" "}
                </a>
                <a style={{ marginLeft: 8 }} href={`/preffy/winners?${params.LoginToken}&q=${post.email}`} target="_blank" rel="noreferrer">
                  Vet{" "}
                </a>
              </div>
              <span style={{color:'black'}}>Preffy Country: {post.country}</span><br/>
              <span style={{color:'black'}}>
                <span>Views: {views}</span><br/>
                <span>Likes: {likes}</span><br/>
                <span>Comments: {comments}</span><br/>
            </span>
              
              
              
              {post.email}
              <br />
              <span className="text-danger">{error}</span>
            </span>
            <input value={link} onChange={handleLinkChange} />
            {referralsNum > 0 ? referralsNum : ""}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="btn btn-md bg-danger text-white"
                onClick={updateLove}
                style={{ width: "100%" }}
              >
                {hasUpdated ? "Done" : "Love"}
              </div>
              <div
                className="btn btn-md bg-success text-white"
                onClick={update}
                style={{ width: "100%" }}
              >
                {hasUpdated ? "Done" : "Like"}
              </div>
            </div>
            <Button
              variant="outlined"
              color="primary"
              className="btn-outline-secondary text-white"
              onClick={handleClickOpen}
            >
              {wasDeleted}
            </Button>


          </>
        );
      }

    }
  }
  return (
    <div className="mb-4" style={{ display: "flex", flexDirection: "column" }}>
      {returnIFrame()}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Confirm deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are You Sure You Want To Do This?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOnlyDelete}
            color="secondary"
            className="btn-outline-secondary text-white"
          >
            No Email
          </Button>
          <Button
            onClick={handleDeleteGeneral}
            color="warning"
            className="btn-outline-secondary text-white"
          >
            General Disqualification
          </Button>

          <Button
            onClick={handleDeleteCD}
            color="warning"
            className="btn-outline-secondary text-white"
          >
            Creative Direction Issue
          </Button>

          <Button
            onClick={handleDelete}
            color="warning"
            className="btn-outline-secondary text-white"
          >
            Volume Issue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PostBlock;
