import axios from "axios";

export const DisqualificationEmail = (email, link) => {
  const welcomeCode = `Hi!<br/><br/>

Thank you for entering the Preffy competition. This email is to inform you that the following post has been flagged by our system because the song was not used loudly enough to hear in a valid a TikTok or Reel: ${link}

<br/><br/>In the future, we ask that you ensure that the music can be heard clearly in the video. Artists sponsor our competitions as a way to gain exposure for their music, which is why we have this rule. If you have any questions or believe we made a mistake, please email contact@preffy.com.

<br/><br/>I apologize if this has caused any inconvenience and hope that you'll continue to use Preffy!
<br/><br/>
Best,<br/>
Preffy team`;

  axios
    .post("https://mandrillapp.com/api/1.0/messages/send.json", {
      key: "md-fhneX5PkBIP4iGyWiZ_BeQ",
      message: {
        from_email: "info@genni.com",
        to: [{ email:email }],
        subject: `Preffy Post Disqualification`,
        html: welcomeCode,
      },
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};
