import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import {
  Button,
} from "reactstrap";
import { listWinnerss } from "../../graphql/queries";
import MuiButton from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {DisqualificationEmail} from "./disqualification-email";
import {
  updatePost,
  updateCompetition,
  deletePost,
} from "../../graphql/mutations";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PostBlock({ post, startDate, idArr, winners }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    await DisqualificationEmail(post.email, post.link);
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const handleOnlyDelete = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const [isBanned, setIsBanned] = useState(false);
  const [wasDeleted, setWasDeleted] = useState("Delete");
  const bonusLikes = get(post, ["bonusLikes"], 0);
  const [value, setValue] = useState(post.likes - bonusLikes);
  const [newValue, setNewValue] = useState(null);
  const [error, setError] = useState(null);
  const [bonus, setBonus] = useState(0);
  const [link, setLink] = useState(post.link);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [referralsNum, setReferralsNum] = useState(post.referrals.items.length);
  const [videoId, setVideoId] = useState();
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleBonusChange = (e) => {
    setBonus(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    return t.toDateString();
  };
  function getVideoId() {
    if (
      post.link.includes("vt.tiktok") ||
      post.link.includes("vm.tiktok") ||
      post.link.includes("tiktok.com/t/")
    ) {
      fetch(
        `https://social-media-data-tt.p.rapidapi.com/live/post/meta/v2?video=${post.link}`,
        {
          method: "GET",
          headers: {
            "x-rapidapi-key":
              "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
            "x-rapidapi-host": "social-media-data-tt.p.rapidapi.com",
          },
        }
      )
        .then((res) => res.text())
        .then(async (response) => {
          const resultObj = JSON.parse(response);

          setLink(
            `https://tiktok.com/@${resultObj.authorMeta.unique_id}/video/${resultObj.id}`
          );

          setVideoId(resultObj.id);
          const startDateMidnight = new Date(startDate);
          startDateMidnight.setHours(0, 0, 0, 0);
          if (
            new Date(startDateMidnight).getTime() >
            new Date(calcDateValue(resultObj.createTime)).getTime()
          ) {
            await handleOnlyDelete();
            setError("Invalid Post Date");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      if (post.link.includes("tiktok")) {
        setLink(post.link);
        if (post.link.includes("/video/")) {
          setVideoId(post.link.split("/video/")[1].split("?")[0]);
        }
      }
    }

    return false;
  }

  const getIsBanned = async () => {
    const myWinners = [];
    let done = false;
    let nToken = false;
    while (!done) {
      const apiData = await API.graphql({
        query: listWinnerss,
        variables: {
          limit: 1000,
          nextToken: !nToken ? null : nToken,
        },
      });
      const myWinnersTemp = get(apiData, ["data", "listWinnerss", "items"], []);
      myWinnersTemp.map((e) => {
        if (e._deleted !== true) {
          myWinners.push(e);
        }
      });

      nToken = apiData.data.listWinnerss.nextToken;
      if (nToken === null) {
        done = true;
      }
    }
  
  const apiData = myWinners.filter(function (e) {
    return e._deleted !== true && e.email === post.email;
  });
  if (apiData && apiData.length > 0 && apiData.filter(e => e.isBanned === true).length > 0) {
    await handleOnlyDelete();
    setError("Banned User");
  } 
}

  useEffect(() => {
    getIsBanned();
    
    const myHeaders = new Headers();
    myHeaders.append("apikey", "eKf13aXEAZYaYH34Gcgg5fYMqlb6GJT2");

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    if (getVideoId() && post.bonusLikes !== null) {
      fetch(
        `https://api.promptapi.com/tiktok/video/${getVideoId()}`,
        requestOptions
      )
        .then((response) => response.text())
        .then(async (result) => {
          const resultObj = JSON.parse(result);
          const likeCount = get(
            resultObj,
            ["itemStruct", "stats", "diggCount"],
            value
          );
          if (likeCount !== value) {
            setValue(likeCount);
            updateNewVal(likeCount);
          }
        })
        .catch((error) => console.log("error", error));
    }
    getVideoId();
    
  }, []);
  const update = async () => {
    const variables = {
      input: {
        id: post.id,
        likes: parseFloat(value) + parseFloat(bonus),
        bonusLikes: bonus,
        creativityScore: 10,
        link: link,
        _version: post._version,
      },
    };
    const createPostResult = await API.graphql(
      graphqlOperation(updatePost, variables)
    );
    setHasUpdated(true);
  };

  const updateLove = async () => {
    const variables = {
      input: {
        id: post.id,
        likes: parseFloat(value) + parseFloat(bonus),
        bonusLikes: bonus,
        creativityScore: 100,
        link: link,
        _version: post._version,
      },
    };
    const createPostResult = await API.graphql(
      graphqlOperation(updatePost, variables)
    );
    setHasUpdated(true);
  };

  const updateNewVal = async (newVal) => {
    const variables = {
      input: {
        id: post.id,
        likes: parseFloat(newVal) + parseFloat(bonus),
        bonusLikes: bonus,
        link: link,
        _version: post._version,
      },
    };
    const createPostResult = await API.graphql(
      graphqlOperation(updatePost, variables)
    );
    setHasUpdated(true);
  };

  function getIGEmbedLink() {
    if (post.link.includes("?")) {
      return post.link.split("?")[0];
    } else {
      return post.link + "/";
    }
  }
  useEffect(async () => {
    if (idArr.includes(videoId)) {
      await handleOnlyDelete();
      setError("Possible Duplicate");
    }
  }, [videoId])
  return (
    <div className="mb-4" style={{ display: "flex", flexDirection: "column" }}>
      {post.platform === "tiktok" && videoId ? (
        <>
          <iframe
            src={`https://www.tiktok.com/embed/${videoId}`}
            scrolling="no"
            width="240"
            height="500"
            style={{
              maxWidth: "100%",
              width: 240,
              height: 500,
              border: "none",
            }}
          ></iframe>
          <span>
            <a href={post.link} target="_blank" rel="noreferrer">
              Link{" "}
            </a>
            <br />
            {post.email}
            <br />
            <span className="text-danger">{error}</span>
          </span>
          <input value={link} onChange={handleLinkChange} />
          {referralsNum > 0 ? referralsNum : ""}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="btn btn-md bg-danger text-white"
              onClick={updateLove}
              style={{ width: "100%" }}
            >
              {hasUpdated ? "Done" : "Love"}
            </div>
            <div
              className="btn btn-md bg-success text-white"
              onClick={update}
              style={{ width: "100%" }}
            >
              {hasUpdated ? "Done" : "Like"}
            </div>
          </div>
          <Button
            variant="outlined"
            color="primary"
            className="btn-outline-secondary text-white"
            onClick={handleClickOpen}
          >
            {wasDeleted}
          </Button>
        </>
      ) : (
        <>
          <iframe
            src={`${getIGEmbedLink()}embed`}
            scrolling="no"
            width="240"
            height="500"
            style={{
              maxWidth: "100%",
              width: 240,
              height: 500,
              border: "none",
            }}
          ></iframe>
          <span>
            <a href={post.link} target="_blank" rel="noreferrer">
              Link{" "}
            </a>
            <br />
            {post.email}
            <br />
            <span className="text-danger">{error}</span>
          </span>
          <input value={link} onChange={handleLinkChange} />
          {referralsNum > 0 ? referralsNum : ""}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="btn btn-md bg-danger text-white"
              onClick={updateLove}
              style={{ width: "100%" }}
            >
              {hasUpdated ? "Done" : "Love"}
            </div>
            <div
              className="btn btn-md bg-success text-white"
              onClick={update}
              style={{ width: "100%" }}
            >
              {hasUpdated ? "Done" : "Like"}
            </div>
          </div>
          <Button
            variant="outlined"
            color="secondary"
            className="btn-outline-secondary text-white"
            onClick={handleClickOpen}
          >
            {wasDeleted}
          </Button>
        </>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Confirm deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are You Sure You Want To Do This?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="danger" className="text-white">
            Cancel
          </Button>
          <Button
            onClick={handleOnlyDelete}
            color="secondary"
            className="btn-outline-secondary text-white"
          >
            Delete w/out Email
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            className="btn-outline-secondary text-white"
          >
            {wasDeleted}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PostBlock;
