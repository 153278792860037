import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get, update } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import {
  updatePost,
  updateCompetition,
  deleteCompetition,
} from "../../graphql/mutations";
import request from "ajax-request";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Menu,
  MenuItem,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
} from "reactstrap";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { listCompetitions, getCompetition } from "../../graphql/queries";

function getVideoId(post, platform) {
  if (post !== undefined) {
    if (post.includes("/video/")) {
      if (platform === "tiktok") {
        if (post.includes("?")) {
          return post.split("/video/")[1].split("?")[0];
        } else {
          return post.split("/video/")[1];
        }
      }
    }
  }
  return false;
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function AllCompetitions() {
  const [isDone, setIsDone] = useState(false);
  let location = useLocation();
  const [userInfo, setUserInfo] = useState();

  let params = queryString.parse(location.search);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [sortByKey, setSortByKey] = useState("End Date");
  const [competitionsState, setCompetitionsState] = useState("upcoming");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const handleChangePage = (event, newPage) => {
    let competitionsArr = [];
    setPage(newPage);
  };

  const filterByArtist = (e) => {
    setFilter(e.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [competitions, setCompetitions] = useState([]);

  function compare(a, b) {
    if (sortByKey === "End Date") {
      if (a.endDate > b.endDate) {
        return -1;
      }
      if (a.endDate < b.endDate) {
        return 1;
      }
      return 0;
    } else if (sortByKey === "Song") {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    } else if (sortByKey === "Last Update") {
      if (a.lastUpdated < b.lastUpdated) {
        return -1;
      }
      if (a.lastUpdated > b.lastUpdated) {
        return 1;
      }
      return 0;
    } else if (sortByKey === "Artist") {
      if (a.organizer < b.organizer) {
        return -1;
      }
      if (a.organizer > b.organizer) {
        return 1;
      }
      return 0;
    }
  }
  const determineState = (comp) => {
    if (comp.hasEnded === true) {
      return "completed";
    } else if (comp.isActive === true) {
      return "active";
    } else {
      return "preview";
    }
  };

  const determineIsConfirmed = (comp) => {
    if (comp.isConfirmed) {
      return "confirmed";
    } else {
      return "hold";
    }
  };
  const returnFilter = () => {
    if (competitionsState === "upcoming") {
      return { hasEnded: { eq: false }, isActive: { eq: false } };
    } else if (competitionsState === "active") {
      return { hasEnded: { eq: false }, isActive: { eq: true } };
      
    } else {
      return {
        hasEnded: { eq: true },
        isActive: { eq: false },
        endDate: { gt: 1716696000000 },
      };
    }
  };
  const fetchCompetitions = async () => {
    let nextToken = null;
    let allItems = [];

    setIsLoading(true);

    do {
      const apiData = await API.graphql({
        query: listCompetitions,
        variables: {
          limit: 100000,
          filter: returnFilter(),
          nextToken: nextToken, // Pass the nextToken for pagination
        },
      });

      const items = apiData.data.listCompetitions.items;

      // Filter out deleted items
      const filteredItems = items.filter((e) => e._deleted !== true);

      // Accumulate all filtered items
      allItems = allItems.concat(filteredItems);

      // Update nextToken
      nextToken = apiData.data.listCompetitions.nextToken;
    } while (nextToken); // Continue while there is a nextToken

    // Sort and set the competitions state with all accumulated items
    setCompetitions(allItems.sort(compare));
    setIsLoading(false);
  };

  const columns = [
    {
      id: 1,
      label: "ID",
    },
    {
      id: 2,
      label: "Song",
    },
    {
      id: 2,
      label: "Email",
    },

    {
      id: 3,
      label: "Artist",
    },
    {
      id: 4,
      label: "Start Date",
    },
    {
      id: 5,
      label: "End Date",
    },

    {
      id: 7,
      label: "State",
    },
    {
      id: 8,
      label: "Hold",
    },
    {
      id: 9,
      label: "Actions",
    },
  ];

  const updateCompetitionState = async (newVal, r) => {
    let vs = {};
    if (newVal === "completed") {
      vs = {
        input: {
          id: r.id,
          hasEnded: true,
          isActive: false,
          _version: r._version,
        },
      };
    } else if (newVal === "active") {
      vs = {
        input: {
          id: r.id,
          hasEnded: false,
          isActive: true,
          _version: r._version,
        },
      };
    } else {
      vs = {
        input: {
          id: r.id,
          hasEnded: false,
          isActive: false,
          _version: r._version,
        },
      };
    }
    const updateComp = await API.graphql(
      graphqlOperation(updateCompetition, vs)
    );
    window.location.reload();
  };
  const removeComp = async (comp) => {
    if (window.confirm("Are you sure?")) {
      await API.graphql(
        graphqlOperation(deleteCompetition, {
          input: { id: comp.id, _version: comp._version },
        })
      );
      window.location.reload();
    }
  };

  const updateIsConfirmed = async (newVal, r) => {
    let vs = {};
    if (newVal === "confirmed") {
      vs = {
        input: {
          id: r.id,
          isConfirmed: true,
          _version: r._version,
        },
      };
    } else {
      vs = {
        input: {
          id: r.id,
          isConfirmed: false,
          _version: r._version,
        },
      };
    }
    const updateComp = await API.graphql(
      graphqlOperation(updateCompetition, vs)
    );
    window.location.reload();
  };

  let competitionsArr = competitions
    .filter(function (e) {
      return (
        e.name.toLowerCase().includes(filter.toLowerCase()) ||
        e.organizer.toLowerCase().includes(filter.toLowerCase())
      );
    })
    .sort(compare);

  useEffect(() => {
    fetchCompetitions();
    request(
      {
        url: `https://www.songfluencer.me/portal2/inquiries/GetUser.cfm/?Token=${params.LoginToken}`,
        method: "POST",
        crossDomain: true,
      },
      function (err, res, body) {
        const bodyInJSON = JSON.parse(body);
        setUserInfo(bodyInJSON.Data);
      }
    );
  }, [competitionsState]);
  return (
    <React.Fragment>
      <br />
      <br />
      <h4 className="mt-5 ml-2" style={{ marginLeft: 15 }}>
        Competitions
      </h4>

      <section className="container pb-4 mt-2" id="home">
        <Paper style={{ width: "100%" }}>
          <TableContainer>
            <div
              className="d-flex shadow"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex">
                <h4
                  className={`mt-2 ml-3 p-3 mb-1 cursor-pointer ${
                    competitionsState === "upcoming" && "text-primary"
                  }`}
                  onClick={() => {
                    setIsLoading(true);
                    setCompetitionsState("upcoming");
                  }}
                  style={{
                    marginBottom: 0,
                    cursor: "pointer",
                  }}
                >
                  Upcoming
                </h4>
                <h4
                  className={`mt-2 ml-3 p-3 mb-1 cursor-pointer ${
                    competitionsState === "active" && "text-primary"
                  }`}
                  onClick={() => {
                    setIsLoading(true);
                    setCompetitionsState("active");
                  }}
                  style={{
                    marginBottom: 0,
                    cursor: "pointer",
                  }}
                >
                  Active
                </h4>
                <h4
                  className={`mt-2 ml-3 p-3 mb-1 cursor-pointer ${
                    competitionsState === "completed" && "text-primary"
                  }`}
                  onClick={() => {
                    setIsLoading(true);
                    setCompetitionsState("completed");
                  }}
                  style={{
                    marginBottom: 0,
                    cursor: "pointer",
                  }}
                >
                  Completed
                </h4>
              </div>
              <input
                type="text"
                className="shadow p-2 mr-2 mt-3"
                placeholder="Search Artist or Song"
                onChange={filterByArtist}
                style={{
                  maxHeight: 40,
                  border: "1px solid rgba(224, 224, 224, 1)",
                  borderRadius: 6,
                }}
              />
            </div>
            <Table stickyHeader aria-label="sticky table">
              {isLoading ? (
                <>
                  <div
                    id=""
                    className=""
                    style={{
                      width: "100%",
                      height: "300px",
                      background: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: 16,
                      borderRadius: 4,
                    }}
                  >
                    <div className="loader" style={{ margin: "auto" }}></div>
                  </div>
                </>
              ) : (
                <>
                  <TableHead>
                    <TableRow
                      className="bg-white"
                      style={{ backgroundColor: "white !important" }}
                    >
                      {columns.map((column) => {
                        return (
                          <TableCell key={column.id}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSortByKey(column.label);
                              }}
                            >
                              {column.label === sortByKey ? (
                                <b>{column.label}</b>
                              ) : (
                                column.label
                              )}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {competitionsArr
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, i) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              // onClick={() =>
                              //   window.open(`https://preffy.com/c/${row.id}`, "_blank")
                              // }
                              tabIndex={-1}
                              key={row.id}
                            >
                              <TableCell key={row.id}>{row.id}</TableCell>
                              <TableCell key={row.id}>{row.name}</TableCell>
                              <TableCell key={row.id}>
                                {row.organizerEmail}
                              </TableCell>
                              <TableCell key={row.id}>
                                {row.organizer}
                              </TableCell>
                              <TableCell key={row.id}>
                                {formatDate(row.startDate)}
                              </TableCell>
                              <TableCell key={row.id}>
                                {formatDate(row.endDate)}
                              </TableCell>

                              <TableCell key={row.id}>
                                <select
                                  className="form-control custom-select"
                                  id="Sortbylist-job"
                                  value={determineState(row)}
                                  onChange={(a) => {
                                    updateCompetitionState(a.target.value, row);
                                  }}
                                >
                                  <option value="preview">Preview</option>
                                  <option value="active">Active</option>
                                  <option value="completed">Completed</option>
                                </select>
                              </TableCell>
                              <TableCell key={row.id}>
                                <select
                                  className="form-control custom-select"
                                  id="Sortbylist-job"
                                  value={determineIsConfirmed(row)}
                                  onChange={(a) => {
                                    updateIsConfirmed(a.target.value, row);
                                  }}
                                >
                                  <option value="hold">Hold</option>
                                  <option value="confirmed">Confirmed</option>
                                </select>
                              </TableCell>
                              <TableCell key={row.id}>
                                <PopupState
                                  variant="popover"
                                  popupId="demo-popup-menu"
                                >
                                  {(popupState) => (
                                    <React.Fragment>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        {...bindTrigger(popupState)}
                                      >
                                        Menu
                                      </Button>
                                      <Menu {...bindMenu(popupState)}>
                                        <MenuItem
                                          onClick={() =>
                                            window.open(
                                              `https://preffy.com/c/${row.id}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          View
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            window.open(
                                              `/reports/?id=${row.id}&LoginToken=${params.LoginToken}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          Report
                                        </MenuItem>

                                        <MenuItem
                                          onClick={() =>
                                            window.open(
                                              `/correct/?id=${row.id}&LoginToken=${params.LoginToken}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          Errors
                                        </MenuItem>

                                        <MenuItem
                                          onClick={() =>
                                            window.open(
                                              `/update/?id=${row.id}&LoginToken=${params.LoginToken}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          Update
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            window.open(
                                              `/preffy/manual/?id=${row.id}&LoginToken=${params.LoginToken}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          Review
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            window.open(
                                              `/edit/?id=${row.id}&LoginToken=${params.LoginToken}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          Edit
                                        </MenuItem>
                                        {userInfo &&
                                          (userInfo[0].EMAILADDRESS_PRIMARY ===
                                            "meghan@songfluencer.com" ||
                                            userInfo[0].EMAILADDRESS_PRIMARY ===
                                              "charlie@songfluencer.com") && (
                                            <MenuItem
                                              onClick={() => {
                                                removeComp(row);
                                              }}
                                            >
                                              Delete
                                            </MenuItem>
                                          )}
                                      </Menu>
                                    </React.Fragment>
                                  )}
                                </PopupState>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </>
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={competitionsArr.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </section>
    </React.Fragment>
  );
}

export default AllCompetitions;
