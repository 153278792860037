import React, { Component, useEffect, useState, useLayoutEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { get } from "lodash";
import { getCompetition } from "../../graphql/queries";
import { actionButton, API } from "aws-amplify";
import { Container, Row, Col, UncontrolledTooltip, Progress, Collapse } from "reactstrap";
import ReactPlayer from "react-player/soundcloud";
import ReactPlayerFile from "react-player/file";
import moment from "moment";
import queryString from "query-string";
import Skeleton from "react-skeleton-loader";

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

function getVideoId(post, platform) {
  if (post !== undefined) {
    if (post.includes("/video/")) {
      if (platform === "tiktok") {
        if (post.includes("?")) {
          return post.split("/video/")[1].split("?")[0];
        } else {
          return post.split("/video/")[1];
        }
      }
    }
  }

  return false;
}

function CompetitionDetail() {
  const [competition, setCompetition] = useState(null);
  const location = useLocation();
  const [isPlaying, setIsPlaying] = useState(0);
  const [copied,setCopied]= useState(false);
  const search = get(location, ['search'], '');
  let params = queryString.parse(location.search);
  const hash = get(location, ['hash'],'');
  const competitionId = params.id;
  const fetchCompetitions = async () => {
    const apiData = await API.graphql({ query: getCompetition,variables: { id: competitionId }});
    setCompetition(apiData);
  };
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  
  const name = get(competition, ['data', 'getCompetition','name'], null);
  const prizes = get(competition, ["data", "getCompetition", "prizes"], null);
  const spotifyUrl = get(competition, ["data", "getCompetition", "spotify"], null);
  const invoiceAmount = get(
    competition,
    ["data", "getCompetition", "invoiceAmount"],
    0
  );
  
  const [posts,setPosts] = useState([]);
  const [views, setViews] = useState(0);
  const postsArr = posts && posts.filter(function (e) {
    return e._deleted !== true;
  });

  const organizer = get(competition, ["data", "getCompetition", "organizer"], null);
  

  const fetchIndividualCompetition = async () => {
    const apiData = await API.graphql({
      query: getCompetition,
      variables: { id: competitionId },
    });
    setPosts(apiData.data.getCompetition.posts.items.filter(e => {return !e._deleted;}));
    const thesePosts = apiData.data.getCompetition.posts.items.filter((e) => {
      return !e._deleted;
    });
    let tempViews = 0;
    console.log("these posts",apiData);
    thesePosts.map(e=>{
      console.log("e",e);
      tempViews += e.views;
    });
    setViews(tempViews);
  };

 

  useEffect(() => {
    fetchCompetitions();
    fetchIndividualCompetition();
    // getIGPostLikes();
  }, []);
  console.log(views);
  const firstPrizeKey = prizes ? prizes[0].split("$")[0] : null;
  const secondPrizeKey = prizes && prizes.length > 1 ? prizes[1].split("$")[0] : null;
  const thirdPrizeKey = prizes && prizes.length > 2 ? prizes[2].split("$")[0] : null;
  const fourthPrizeKey = prizes && prizes.length > 3 ? prizes[3].split("$")[0] : null;
    return (
      <React.Fragment>
        <section className="bg-profile d-table w-100 mt-4 pt-4">
          <Container>
            <Row className="cover-bg mt-4" style={{ maxHeight: "300px" }}>
              <Col lg="12">
                <div
                  className="public-profile bg-white overflow-hidden rounded shadow p-4 mt-4 "
                  style={{ zIndex: "1" }}
                >
                  <Row className="">
                    <Col lg="11" md="10" className="profileTitleName">
                      <Row className="align-items-center">
                        <Col md="7" className="text-md-left mt-2 mt-sm-0">
                          <h4
                            className="title mb-0"
                            style={{ lineHeight: 1.2 }}
                          >
                            {competition ? (
                              name
                            ) : (
                              <Skeleton
                                width="150px"
                                height="24px"
                                widthRandomness={0}
                                color="#ced5e0"
                              />
                            )}
                          </h4>
                          <a href={spotifyUrl ? spotifyUrl : ""}>
                            <small className="text-muted h6 mr-2">
                              {competition ? (
                                organizer
                              ) : (
                                <Skeleton
                                  width="90px"
                                  height="20px"
                                  widthRandomness={0}
                                  color="#ced5e0"
                                />
                              )}
                            </small>
                          </a>
                          {/* <ul className="list-inline mb-0 mt-3">
                                                        <li className="list-inline-item mr-2"><Link to="#" className="text-muted" title="Linkedin"><i className="mdi mdi-instagram mr-2"></i>krista_joseph</Link></li>
                                                        <li className="list-inline-item"><Link to="#" className="text-muted" title="Skype"><i className="mdi mdi-skype mr-2"></i>@krista_joseph</Link></li>
                                                    </ul> */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <br />
            {views > 0 ? (
              <Row className="mt-3" style={{ maxHeight: "300px" }}>
                <Col lg="4" md="4" xs="12" className="mt-sm-0 pt-sm-0">
                  <div
                    className="card rounded mb-2 shadow border-none p-2"
                    style={{ border: "none" }}
                  >
                    <b className="text-muted">Total Views</b>
                    <h3>{nFormatter(views)}</h3>
                  </div>
                </Col>
                <Col lg="4" md="4" xs="12" className="mt-sm-0 pt-sm-0">
                  <div
                    className="card rounded mb-2 shadow border-none p-2"
                    style={{ border: "none" }}
                  >
                    <b className="text-muted">UGC</b>
                    <h3>{posts.length}</h3>
                  </div>
                </Col>
                <Col lg="4" md="4" xs="12" className="mt-sm-0 pt-sm-0">
                  <div
                    className="card rounded mb-2 shadow border-none p-2"
                    style={{ border: "none" }}
                  >
                    <b className="text-muted">Invoice Amount</b>
                    <h3>${invoiceAmount}</h3>
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Container>
        </section>
      </React.Fragment>
    );
}

export default CompetitionDetail;