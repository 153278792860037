import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import request from "ajax-request";
import queryString from "query-string";
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  Button,
  DropdownToggle,
} from "reactstrap";
import { get } from "lodash";
import moment from "moment";
//SimpleBar
import SimpleBar from "simplebar-react";
// import images
import user1 from "../../../assets/images/users/avatar-2.jpg";
import AddANoteModal from "common/AddANoteModal";

const Activity = (props) => {
  const [modal, setModal] = useState(false);
  const [activity, setActivity] = useState([]);
  const location = useLocation();
  let params = queryString.parse(location.search);
  const toggleViewModal = () => setModal(!modal);
  useEffect(() => {
    request(
      {
        url: `https://www.songfluencer.me/portal2/inquiries/GetActivity.cfm/?id=${params.id}&Token=${params.LoginToken}`,
        method: "POST",
        crossDomain: true,
      },
      function (err, res, body) {
        // console.log("activity",body);
        const bodyInJSON = JSON.parse(body);
        setActivity(bodyInJSON);
        console.log(bodyInJSON);
      }
    );
  }, [location]);

  return (
    <React.Fragment>
      
      <Col xl={12} lg={12}>
        <div className="card">
          <div className="card-body">
            <div className="mb-5 h4 card-title">Activity</div>
            <ul className="verti-timeline list-unstyled">
              {activity.map((a,i) => {
                return (
                  <li key={i} className="event-list">
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"></i>
                    </div>
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <h5 className="font-size-14">
                          {new Date(a.CREATEDDATETIME).toLocaleDateString("en-US")}
                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                        </h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{a.ACTIVITYNOTE}</div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default Activity;
