import axios from "axios";

export const GeneralDisqualification = (email, link) => {
  const welcomeCode = `Hi!<br/><br/>

Thank you for entering the Preffy competition. This is an automated email to inform you that the following post has been flagged by our system for violating our post guidelines: ${link}

<br/><br/>
Post Guidelines:<br/>
1. The song must be heard clearly in the video<br/>
2. The post must be original content, meaning it cannot contain aggregated or unlicensed material.<br/>
3. The post must be a video and cannot be a meme<br/>
4. The post must not contain inappropriate content<br/>
5. The post must have used the correct audio version<br/>
6. The post must not be considered spam and/or violate the integrity of our competitions<br/><br/>
In the future, we ask that you only use original video content. If you have any questions or believe we made a mistake, please email contact@preffy.com.

<br/><br/>I apologize if this has caused any inconvenience and hope that you'll continue to use Preffy!
<br/><br/>
Best,<br/>
Preffy team`;

  axios
    .post("https://mandrillapp.com/api/1.0/messages/send.json", {
      key: "md-fhneX5PkBIP4iGyWiZ_BeQ",
      message: {
        from_email: "info@genni.com",
        to: [{ email:email }],
        subject: `Preffy Post Disqualification`,
        html: welcomeCode,
      },
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};
