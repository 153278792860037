import React, { Component, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { get } from "lodash";
import queryString from "query-string";
import { Storage, API, graphqlOperation } from "aws-amplify";
import {
  updatePost,
  updateCompetition,
  updatePayment,
} from "../../graphql/mutations";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {

  Button,
} from "reactstrap";
import { listAllWinners, listWinnerss, listPayments } from "../../graphql/queries";

function Payments() {
  const [isDone, setIsDone] = useState(false);
  const [page, setPage] = React.useState(0);
  const [winners, setWinners] = useState([]);
  const [payments, setPayments] = useState([]);
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [paymentsState, setPaymentsState] = useState("To Be Paid");
  const [isLoading, setIsLoading] = useState(true);
  const [sortByKey, setSortByKey] = useState("Status");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    let paymentsArr = [];

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [competitions, setCompetitions] = useState([]);
  const determineState = (comp) => {
    if (comp.hasEnded === true) {
      return "completed";
    } else if (comp.isActive === true) {
      return "active";
    } else {
      return "preview";
    }
  };

  const [filter, setFilter] = useState("");
  const filterByEmail = (e) => {
    setFilter(e.target.value);
  };

  const paid = async (payment) => {
    const vs = {
      input: {
        id: payment.id,
        Status: "Paid",
        _version: payment._version,
      },
    };
    console.log("vs",vs);
    const upPayment = await API.graphql(graphqlOperation(updatePayment, vs));
    setPayments([]);
    fetchPayments();
  };

  const unpay = async (payment) => {
    const vs = {
      input: {
        id: payment.id,
        Status: "To Be Paid",
        _version: payment._version,
      },
    };
    console.log("vs",vs);
    const upPayment = await API.graphql(graphqlOperation(updatePayment, vs));
    setPayments([]);
    fetchPayments();
  };

  const check = async (row) => {
    console.log("row status",row.Source);
    if (row.Source !== "Genni") {
      winners.map(e=>{
        if (e.email === row.ContactEmail){
          alert(e.paypal)
        }
      })
    } else {
      fetch(
        `https://songfluencer.me/preffy/get_paypal_email.cfm?Token=779DEA53-9C6E-11B1-1526D7FE288AF8F4&Task=GetPaymentEmail&UserEmail=${row.ContactEmail}`,
        { method: "GET" }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          alert(data.PaymentEmail)
        });
    }
    
  };
  function compare(a, b) {
    if (sortByKey === "Email") {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    } else if (sortByKey === "PayPal") {
      if (a.paypal < b.paypal) {
        return -1;
      }
      if (a.paypal > b.paypal) {
        return 1;
      }
      return 0;
    } else if (sortByKey === "Balance") {
      if (a.balance > b.balance) {
        return -1;
      }
      if (a.balance < b.balance) {
        return 1;
      }

      return 0;
    }
  }

  function comparePayment(a, b) {
    if (sortByKey === "ContactEmail") {
      if (a.ContactEmail < b.ContactEmail) {
        return -1;
      }
      if (a.ContactEmail > b.ContactEmail) {
        return 1;
      }
      return 0;
    } else if (sortByKey === "PaymentEmail") {
      if (a.paypal < b.paypal) {
        return -1;
      }
      if (a.paypal > b.paypal) {
        return 1;
      }
      return 0;
    } else if (sortByKey === "Amount") {
      if (a.Amount > b.Amount) {
        return -1;
      }
      if (a.Amount < b.Amount) {
        return 1;
      }
      return 0;
    } else {
      if (a.Status > b.Status) {
        return -1;
      }
      if (a.Status < b.Status) {
        return 1;
      }
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      return 0;
    }
  }
  const fetchWinners = async () => {
    const myWinners = [];
    let done = false;
    let nToken = false;
    while (!done) {
      const apiData = await API.graphql({
        query: listWinnerss,
        variables: {
          limit: 1000,
          nextToken: !nToken ? null : nToken,
        },
      });
      const myWinnersTemp = get(apiData, ["data", "listWinnerss", "items"], []);
      myWinnersTemp.map((e) => {
        if (e._deleted !== true) {
          myWinners.push(e);
        }
      });

      nToken = apiData.data.listWinnerss.nextToken;
      if (nToken === null) {
        done = true;
      }
    }
    setWinners(myWinners.filter(function (e) {
        return e._deleted !== true;
      }).sort(compare));
  };

  const returnFilter = () => {
    if (paymentsState === "To Be Paid") {
      return { Status: {eq: "To Be Paid"} };
    } else {
      return { Status: { eq: "Paid" } };
    }
  };
  const fetchPayments = async () => {
    const myPayments = [];
    let done = false;
    let nToken = false;
    while (!done) {
      const apiData = await API.graphql({
        query: listPayments,
        variables: {
          limit: 1000,
          nextToken: !nToken ? null : nToken,
          filter: returnFilter(),
        },
      });
      const myPaymentsTemp = get(apiData, ["data", "listPayments", "items"], []);
      myPaymentsTemp.map((e) => {
        if (e._deleted !== true) {
          myPayments.push(e);
        }
      });

      nToken = apiData.data.listPayments.nextToken;
      if (nToken === null) {
        done = true;
      }
    }
    if (done  && nToken === null) {
      setIsLoading(false);
      setPayments(
        myPayments.filter(function (e) {
          return e._deleted !== true;
        }).sort(comparePayment)
      );
    }
  };

  const columns = [
    {
      id: 2,
      label: "CompetitionID",
    },
    {
      id: 1,
      label: "ContactEmail",
    },
    {
      id: 2,
      label: "PaymentEmail",
    },

    {
      id: 2,
      label: "Amount",
    },
    {
      id: 2,
      label: "Source",
    },

    {
      id: 2,
      label: "Status",
    },
    {
      id: 2,
      label: "Check",
    },
    {
      id: 2,
      label: "Action",
    },
  ];
  const updateCompetitionState = async (newVal, r) => {
    let vs = {};
    if (newVal === "completed") {
      vs = {
        input: {
          id: r.id,
          hasEnded: true,
          isActive: false,
          _version: r._version,
        },
      };
    } else if (newVal === "active") {
      vs = {
        input: {
          id: r.id,
          hasEnded: false,
          isActive: true,
          _version: r._version,
        },
      };
    } else {
      vs = {
        input: {
          id: r.id,
          hasEnded: false,
          isActive: false,
          _version: r._version,
        },
      };
    }
    const updateComp = await API.graphql(
      graphqlOperation(updateCompetition, vs)
    );
    window.location.reload();
  };

  useEffect(() => {
    fetchWinners();
    fetchPayments();
  }, [paymentsState]);

  const tempPayments = payments;
  let paymentsArr = tempPayments
    .filter(function (e) {
      if (e.ContactEmail && e.PaymentEmail){
        return (
          e.ContactEmail.toLowerCase().includes(filter.toLowerCase()) ||
          e.PaymentEmail.toLowerCase().includes(filter.toLowerCase()) ||
          e.CompetitionID.toLowerCase().includes(filter.toLowerCase())
        );
      } else if (e.ContactEmail) {
        return (
          e.ContactEmail.toLowerCase().includes(filter.toLowerCase()) ||
          e.CompetitionID.toLowerCase().includes(filter.toLowerCase())
        );
      }
      return false;

    })
    .sort(compare);
  return (
    <React.Fragment>
      <br />
      <br />
      <h4 className="mt-5 ml-2" style={{ marginLeft: 15 }}>
        Payments
      </h4>

      <section className="container pb-4" id="home">
        <Paper style={{ width: "100%" }}>
          <TableContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h4
                  className={`mt-2 ml-3 p-3 mb-1 cursor-pointer ${
                    paymentsState === "To Be Paid" && "text-primary"
                  }`}
                  onClick={() => {
                    setIsLoading(true);
                    setPaymentsState("To Be Paid");
                  }}
                  style={{
                    marginBottom: 0,
                    cursor: "pointer",
                  }}
                >
                  To Be Paid
                </h4>
                <h4
                  className={`mt-2 ml-3 p-3 mb-1 cursor-pointer ${
                    paymentsState === "Paid" && "text-primary"
                  }`}
                  onClick={() => {
                    setIsLoading(true);
                    setPaymentsState("Paid");
                  }}
                  style={{
                    marginBottom: 0,
                    cursor: "pointer",
                  }}
                >
                  Sent To CSV
                </h4>
              </div>
              <input
                type="text"
                className="shadow p-2 mr-2 mt-3"
                placeholder="Search"
                onChange={filterByEmail}
                style={{
                  maxHeight: 40,
                  border: "1px solid rgba(224, 224, 224, 1)",
                  borderRadius: 6,
                }}
              />
            </div>
            <Table stickyHeader aria-label="sticky table">
              {isLoading ? (
                <>
                  <div
                    id=""
                    className=""
                    style={{
                      width: "100%",
                      height: "300px",
                      background: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: 16,
                      borderRadius: 4,
                    }}
                  >
                    <div className="loader" style={{ margin: "auto" }}></div>
                  </div>
                </>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => {
                        return (
                          <TableCell key={column.id}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSortByKey(column.label);
                              }}
                            >
                              {column.label === sortByKey ? (
                                <b>{column.label}</b>
                              ) : (
                                column.label
                              )}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentsArr
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            // onClick={() =>
                            //   window.open(`https://preffy.com/c/${row.id}`, "_blank")
                            // }
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell key={row.id}>
                              {row.CompetitionID}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.ContactEmail}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.PaymentEmail}
                            </TableCell>
                            <TableCell key={row.id}>{row.Amount}</TableCell>
                            <TableCell key={row.id}>{row.Source}</TableCell>
                            <TableCell key={row.id}>{row.Status === "Paid" ? "Sent To CSV" : row.Status}</TableCell>
                            <TableCell key={row.id}>
                              <Button
                                size="sm"
                                onClick={() => {
                                  check(row);
                                }}
                              >
                                Check
                              </Button>
                            </TableCell>
                            <TableCell key={row.id}>
                              <Button
                                size="sm"
                                onClick={() => {
                                  if (paymentsState === "To Be Paid") {
                                    paid(row);
                                  } else {
                                    unpay(row);
                                  }
                                  
                                }}
                              >
                                {paymentsState === "To Be Paid" ? "Sent" : "Unsend"}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={paymentsArr.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </section>
    </React.Fragment>
  );
}

export default Payments;
