import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import request from "ajax-request";
// //Import Scrollbar
import SimpleBar from "simplebar-react"
import queryString from "query-string";
// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter, useLocation } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const loc = useLocation()
  let params = queryString.parse(loc.search);
  const [userInfo, setUserInfo] = useState();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
  }, [props.location.pathname])
  useEffect(() => {
    
    request(
      {
        url: `https://www.songfluencer.me/portal2/inquiries/GetContacts.cfm/?Token=${params.LoginToken}`,
        method: "POST",
        crossDomain: true,
      },
      function (err, res, body) {
        // console.log("get contacts",body);
        const bodyInJSON = JSON.parse(body);
        console.log("made it");
        if (bodyInJSON.Request !== "Valid") {
           window.location.href = "https://songfluencer.me/login_admin.cfm";
        } 
        
      });
      request(
        {
          url: `https://www.songfluencer.me/portal2/inquiries/GetUser.cfm/?Token=${params.LoginToken}`,
          method: "POST",
          crossDomain: true,
        },
        function (err, res, body) {
          const bodyInJSON = JSON.parse(body);
          setUserInfo(bodyInJSON.Data);
        }
      );
     ref.current.recalculate()
  },[])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled side-menu">
            <li className="menu-title">{props.t("Menu")}</li>

          
          </ul>
          <ul className="metismenu list-unstyled" id="side-menu2">
            {/* <li>
              <Link to={`/inquiries?LoginToken=${params.LoginToken}`}>
                <i className="bx bx-message"></i>
                Inquiries
              </Link>
            </li> */}
            <li>
              <Link
                to={`/preffy/view?LoginToken=${params.LoginToken}`}
                className="has-arrow "
              >
                <i className="bx bx-trophy"></i>
                <span>{props.t("Preffy")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link
                    to={`/preffy/view?LoginToken=${params.LoginToken}`}
                    className={
                      loc.pathname.includes("view") ? "isActive" : "inactive"
                    }
                  >
                    {props.t("All Competitions")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/preffy/posts?LoginToken=${params.LoginToken}`}
                    className={
                      loc.pathname.includes("posts") ? "isActive" : "inactive"
                    }
                  >
                    {props.t("Posts")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/preffy/add?&LoginToken=${params.LoginToken}`}
                    className={
                      loc.pathname.includes("add") ? "isActive" : "inactive"
                    }
                  >
                    {props.t("Add")}
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      loc.pathname.includes("finish") ? "isActive" : "inactive"
                    }
                    to={`/preffy/finish?&LoginToken=${params.LoginToken}`}
                  >
                    {props.t("Finish")}
                  </Link>
                </li>
                {userInfo &&
                  userInfo[0].EMAILADDRESS_PRIMARY ===
                    "charlie@songfluencer.com" && (
                    <li>
                      <Link
                        className={
                          loc.pathname.includes("email")
                            ? "isActive"
                            : "inactive"
                        }
                        to={`/preffy/email?&LoginToken=${params.LoginToken}`}
                      >
                        {props.t("Email")}
                      </Link>
                    </li>
                  )}
                <li>
                  <Link
                    className={
                      loc.pathname.includes("payments")
                        ? "isActive"
                        : "inactive"
                    }
                    to={`/preffy/payments?&LoginToken=${params.LoginToken}`}
                  >
                    {props.t("Payments")}
                  </Link>
                </li>

                <li>
                  <Link
                    to={`/preffy/winners?&LoginToken=${params.LoginToken}`}
                    className={
                      loc.pathname.includes("winners") ? "isActive" : "inactive"
                    }
                  >
                    {props.t("Winners")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/preffy/analytics?&LoginToken=${params.LoginToken}`}
                    className={
                      loc.pathname.includes("analytics")
                        ? "isActive"
                        : "inactive"
                    }
                  >
                    {props.t("Analytics")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/preffy/calendar?LoginToken=${params.LoginToken}`}
                    className={
                      loc.pathname.includes("calendar")
                        ? "isActive"
                        : "inactive"
                    }
                  >
                    {props.t("Calendar")}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
