import React, { Component, useEffect, useState } from "react";
import * as AWS from "aws-sdk";
import request from "ajax-request";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

function PreffyAPI() {
  const [results, setResults] = useState();
  let location = useLocation();
  let params = queryString.parse(location.search);
  // useEffect(() => {
    
  //   // eslint-disable-next-line no-import-assign
  //   AWS.config = {
  //     accessKeyId: "AKIAXVVWZFLBMLSWJHG2",
  //     secretAccessKey: "",
  //     region: "us-east-2",
  //   };
  //   let dynamoDB = new AWS.DynamoDB();

  //   async function queryWithPartiQL() {
  //     const statement = params.Query;
  //     const res = await dynamoDB
  //       .executeStatement({ Statement: statement })
  //       .promise();
  //     setResults(res.Items);
  //   }
  //   queryWithPartiQL();
  // }, []);
  return (
    <React.Fragment>
     {results && JSON.stringify(results)}
    </React.Fragment>
  );
}

export default PreffyAPI;
