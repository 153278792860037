import React from "react"
import { Redirect } from "react-router-dom"
import Inquiries from "../pages/Inquiries/Inquiries/index"
import Finish from "../pages/Preffy/finish";
import Email from "../pages/Preffy/email";
import Calendar from "../pages/Preffy/calendar";
import API from "../pages/Preffy/api";
import Winners from "../pages/Preffy/winners";
import Promotion from "../pages/Preffy/promotion";
import Referrals from "../pages/Preffy/referrals";
import Reports from '../pages/Reports/competition-detail';
import Update from "../pages/Update/index";
import Correct from "../pages/Correct/index";
import Analytics from "../pages/Preffy/analytics";
import AllCompetitions from "../pages/Preffy/view";
import Posts from "../pages/Preffy/posts";
import Payments from "../pages/Preffy/payments";
import AddCompetitions from "../pages/Preffy/add";
import EditCompetition from "../pages/Preffy/edit";
import Manual from "../pages/Preffy/manual";
import ContactsProfile from "../pages/Profile/Profile/contacts-profile"

const userRoutes = [
  { path: "/reports/", component: Reports },
  { path: "/contacts-profile", component: ContactsProfile },
  { path: "/preffy/view", component: AllCompetitions },
  { path: "/preffy/posts", component: Posts },
  { path: "/preffy/add", component: AddCompetitions },
  { path: "/preffy/referrals", component: Referrals },
  { path: "/preffy/api", component: API },
  { path: "/preffy/manual", component: Manual },
  { path: "/edit", component: EditCompetition },
  { path: "/preffy/finish", component: Finish },
  { path: "/preffy/email", component: Email },
  { path: "/preffy/calendar", component: Calendar },
  { path: "/preffy/winners", component: Winners },
  { path: "/preffy/payments", component: Payments },
  { path: "/preffy/promotion", component: Promotion },
  { path: "/preffy/analytics", component: Analytics },
  { path: "/update", component: Update },
  { path: "/correct", component: Correct },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/preffy/view" /> },
];

export { userRoutes }
