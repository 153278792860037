import React, { Component, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { v4 as uuid } from "uuid";
import { getCompetition } from "../../graphql/queries";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import S3FileUpload from "react-s3";
import { first, get } from "lodash";
import { Storage, Auth, API, graphqlOperation } from "aws-amplify";
import "./styles.css";
import {
  updateCompetition,
} from "../../graphql/mutations";
import queryString from "query-string";
import config from "../../aws-exports";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import "react-datepicker/dist/react-datepicker.css";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

//Import Profile Picture edit functionality
// import ReactAvatarEditor from 'react-avatar-editor';

//Import components

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
function isUrl(s) {
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return regexp.test(s);
}

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;


function StartACompetition() {
  const [secretAccessKey, setSecretAccessKey] = useState();
  useEffect(() => {
    if (!secretAccessKey) {
      setSecretAccessKey(process.env.REACT_APP_SECRET_ACCESS_KEY);
    }
  }, [process.env])
const s3Config = {
  bucketName: bucket,
  dirName: "images",
  region: region,
  accessKeyId: "AKIAXVVWZFLBMLSWJHG2",
  secretAccessKey: `${secretAccessKey}`,
};
  const [competition, setCompetition] = useState(null);
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));
  const [startDate, setStartDate] = useState(new Date());
  const [title, setTitle] = useState("");
  const [competitionType, setCompetitionType] = useState("");
  const [platforms, setPlatforms] = useState(["TikTok"]);
  const [version, setVersion] = useState(0);
  const [isClosed, setIsClosed] = useState();
  const [secondPlace, setSecondPlace] = useState(0);
  const [mostCreative, setMostCreative] = useState(0);
  const [hundredK, setHundredK] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [linkError, setLinkError] = useState("");
  const [creativeDirection, setCreativeDirection] = useState();
  const [notes, setNotes] = useState();
  const [email, setEmail] = useState("");
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isDirect, setIsDirect] = useState(false);
  const [artist, setArtist] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");
  const [prize, setPrize] = useState("200");
  const [prizes,setPrizes]= useState([]);
  const [continents, setContinents] = useState(["North America","South America", "Asia", "Oceania", "Europe", "Africa"]);
  const [countries, setCountries] = useState([]);
  const [file, updateFile] = useState(null);
  const [modal, setModal] = useState(false);
  const [invoiceAmount, setInvoiceAmount] = useState(false);

  const togglemodal = () => {
    setModal(!modal);
  };
  const [fileName, updateFileName] = useState("Upload Image");
  const [fileResult, updateFileResult] = useState(null);

 

  const handleIsDirect = () => {
    setIsDirect(!isDirect);
  }

  const totalPrizes = () => {
    if (prizes) {
      let sum = 0;
      // let sum = prizes[0].includes("4th") ? 300 : 0;
      prizes.forEach((element, i) => {
        let val = element.split("$")[1];
        let label = element.split("$")[0];
        if (label.includes("-") && !label.includes("Followers")) {
          const rangeLow = element.split("-")[0];
          const rangeLowVal = rangeLow.substring(0, rangeLow.length - 2);
          const rangeHigh = element.split("-")[1].split(" Place")[0];
          const rangeHighVal = rangeHigh.substring(0, rangeHigh.length - 2);
          const multiplier = parseInt(rangeHighVal) - parseInt(rangeLowVal) + 1;
          val = val * multiplier;
        } else if (label.includes("Top")) {
          const amountOfWinners = parseInt(label.split(" ")[1]);
          val = amountOfWinners * val;
        }

        sum += parseFloat(val);
      });
      return "$" + sum;
    }
  };
  const fetchIndividualCompetition = async () => {
    const apiData = await API.graphql({
      query: getCompetition,
      variables: { id: params.id },
    });
    setCompetition(apiData.data.getCompetition);
    setArtist(apiData.data.getCompetition.organizer);
    setIsDirect(apiData.data.getCompetition.isDirect);
    setEmail(apiData.data.getCompetition.organizerEmail);
    setTitle(apiData.data.getCompetition.name);
    setCreativeDirection(apiData.data.getCompetition.creativeDirection);
    setNotes(apiData.data.getCompetition.notes);
    setStartDate(apiData.data.getCompetition.startDate);
    setEndDate(apiData.data.getCompetition.endDate);
    setLink(apiData.data.getCompetition.audio);
    setImage(apiData.data.getCompetition.image);
    setInvoiceAmount(apiData.data.getCompetition.invoiceAmount);
    setIsClosed(apiData.data.getCompetition.isClosed);
    setVersion(apiData.data.getCompetition._version);
    setPrizes(apiData.data.getCompetition.prizes);
    setContinents(apiData.data.getCompetition.continents ? apiData.data.getCompetition.continents : ["North America","South America", "Asia", "Oceania", "Europe", "Africa"]);
    setCountries(apiData.data.getCompetition.countries ? apiData.data.getCompetition.countries : []);
    setPlatforms(apiData.data.getCompetition.platforms ? apiData.data.getCompetition.platforms : ["TikTok"]);
    setCompetitionType(apiData.data.getCompetition.competitionType);
    // setCompetitions(
    //   apiData.data.listCompetitions.items.filter(function (e) {
    //     return e._deleted !== true && e.hasEnded !== true;
    //   })
    // );
  };

  function handleImageChange(event) {
    const {
      target: { value, files },
    } = event;
    const fileForUpload = files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileForUpload);

    reader.onloadend = function (e) {
      updateFileResult([reader.result]);
    }.bind(this);
    if (fileForUpload) {
      updateFileName(fileForUpload.name.split(".")[0]);
      updateFile(fileForUpload || value);
    }
  }

  function returnPrizeDistribution(ia) {
    const totalPrizes = Math.floor(ia * .667);
    if (competitionType === "Creative") {
      if (parseFloat(totalPrizes) < 3000) {
        setPrizes([
          `Top 20 Most Creative: $${Math.floor((totalPrizes * 0.8) / 20)}`,
          `Best Lip Sync: $${Math.floor(totalPrizes * 0.1)}`,
          `Best Dance: $${Math.floor(totalPrizes * 0.1)}`,
        ]);
      } else {
        setPrizes([
          `Top 50 Most Creative: $${Math.floor((totalPrizes * 0.8) / 50)}`,
          `Best Lip Sync: $${Math.floor(totalPrizes * 0.1)}`,
          `Best Dance: $${Math.floor(totalPrizes * 0.1)}`,
        ]);
      }
    } else {
      if (parseFloat(totalPrizes) < 2000) {
        setPrizes([
          `1st-5th Place: $${Math.floor((totalPrizes * 0.3) / 5)}`,
          `6th-10th Place: $${Math.floor((totalPrizes * 0.2) / 5)}`,
          `11th-15th Place: $${Math.floor((totalPrizes * 0.1) / 5)}`,
          `Top 40 Most Creative: $${Math.floor((totalPrizes * 0.4) / 40)}`,
        ]);
      } else {
        setPrizes([
          `1st-5th Place: $${Math.floor((totalPrizes * 0.25) / 5)}`,
          `6th-10th Place: $${Math.floor((totalPrizes * 0.2) / 5)}`,
          `11th-20th Place: $${Math.floor((totalPrizes * 0.2) / 10)}`,
          `20th-30th Place: $${Math.floor((totalPrizes * 0.1) / 10)}`,
          `Top 48 Most Creative: $${Math.floor((totalPrizes * 0.25) / 50)}`,
        ]);
      }
    }
  }
  const handleInvoiceAmountChange = (e) => {
    setInvoiceAmount(e.target.value);
    returnPrizeDistribution(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handlePlatformsChange = (e) => {
    if (e.target.value.includes(",")) {
      setPlatforms(e.target.value.split(","));
    } else {
      setPlatforms([e.target.value]);
    }
  };

  const handleCompetitionType = (e) => {
    setCompetitionType(e.target.value);
  }

  const handleSecondPlaceChange = (e) => {
    setSecondPlace(e.target.value);
  };

  const handleMostCreativeChange = (e) => {
    setMostCreative(e.target.value);
  };

  const handleHundredKChange = (e) => {
    setHundredK(e.target.value);
  };
  const updateCompetitionState = async () => {
    if (file) {
      try {
        S3FileUpload.uploadFile(file, s3Config)
          .then(async (data) => {
            const newStartDate = new Date(startDate);
            newStartDate.setHours(15, 0);
            newStartDate.setMinutes(0);
            const newEndDate = new Date(endDate);
            newEndDate.setHours(15);
            newEndDate.setMinutes(0);
            const inputData = {
              id: params.id,
              name: title,
              image: data.location,
              audio: link,
              startDate: newStartDate.getTime(),
              endDate: newEndDate.getTime(),
              isClosed: isClosed,
              creativeDirection: creativeDirection,
              notes: notes,
              invoiceAmount: invoiceAmount,
              platforms:
                platforms,
              organizer: artist,
              prizes: prizes,
              continents:continents,
              countries: countries && countries.length > 0 ? countries : null,
              organizerEmail: email,
              competitionType: competitionType,
              isDirect: isDirect,
              _version: version,
            };
            await API.graphql(
              graphqlOperation(updateCompetition, { input: inputData })
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log("error: ", err);
      }
    } else {
      const newStartDate = new Date(startDate);
      newStartDate.setHours(15);
      newStartDate.setMinutes(0);
      const newEndDate = new Date(endDate);
      newEndDate.setHours(15);
      newEndDate.setMinutes(0);

      const inputData = {
        id: params.id,
        name: title,
        audio: link,
        startDate: newStartDate.getTime(),
        endDate: newEndDate.getTime(),
        creativeDirection: creativeDirection,
        notes: notes,
        isClosed: isClosed,
        platforms: platforms,
        organizer: artist,
        isDirect:isDirect,
        prizes: prizes,
        continents: continents && continents.length > 0 ? continents : null,
        countries: countries && countries.length > 0 ? countries : null,
        invoiceAmount: invoiceAmount,
        organizerEmail: email,
        competitionType: competitionType,
        _version: version,
      };
      await API.graphql(
        graphqlOperation(updateCompetition, { input: inputData })
      );
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleArtistChange = (e) => {
    setArtist(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const prizeError = () => {
    if (prize < 50) {
      return "Minimum $50";
    }
    if (prize > 10000) {
      return "Maximum $10000";
    }
    return "";
  };

  const isValidUrl = () => {
    if (isUrl(link)) {
      setLinkError("");
      return true;
    } else {
      return false;
    }
  };

  const handleClick = async (event) => {
    // Get Stripe.js instance
    // Call your backend to create the Checkout Session
    setIsLoading(true);
    updateCompetitionState();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        const userEmail = get(user, ["attributes", "email"], null);
        if (userEmail) {
          setEmail(userEmail);
          setIsLoggedIn(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    fetchIndividualCompetition();
  }, []);

  console.log("prizes",prizes);

  return (
    <React.Fragment>
      <Container>
        <br />
        <br />
        <Row className="justify-content-center mt-5">
          <Col lg="12">
            <div className="p-4 rounded shadow bg-white mt-4-mobile">
              <h3 className="text-md-left mb-0 font-weight-bold">
                Update A Competition
              </h3>
              <AvForm>
                {!isLoading ? (
                  <Row className="mt-4">
                    <Col md="5" className="mb-3 mx-2">
                      <AvGroup className="form-group position-relative">
                        <Label>Email</Label>

                        <AvField
                          type="text"
                          className="form-control pl-5"
                          name="email"
                          id="email"
                          placeholder="Ex. example@gmail.com"
                          value={email}
                          onChange={handleEmailChange}
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter the artist's email",
                            },
                            pattern: {
                              value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                              errorMessage: "Email is not valid!",
                            },
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col md="5" className="mb-3 mx-2">
                      <AvGroup className="form-group position-relative">
                        <Label>Song Title</Label>

                        <AvField
                          type="text"
                          className="form-control pl-5"
                          name="title"
                          id="title"
                          placeholder="Ex. Hey Jude"
                          value={title}
                          onChange={handleTitleChange}
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                            },
                          }}
                        />
                      </AvGroup>
                    </Col>

                    <Col md="5" className="mb-3 mx-2">
                      <AvGroup className="form-group position-relative">
                        <Label>Artist Name</Label>
                        <AvField
                          type="text"
                          className="form-control pl-5"
                          name="artist"
                          id="artist"
                          placeholder="Ex. The Beatles"
                          value={artist}
                          onChange={handleArtistChange}
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter the artist's name",
                            },
                          }}
                        />
                      </AvGroup>
                    </Col>

                    <Col md="5" className="mb-3 mx-2">
                      <AvGroup className="form-group position-relative">
                        <Label>Link To Song</Label>
                        <AvField
                          type="text"
                          className="form-control pl-5"
                          name="link"
                          id="link"
                          placeholder="Public SoundCloud or Audio File Link"
                          value={link}
                          onChange={handleLinkChange}
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter the song's link",
                            },
                          }}
                        />
                        {linkError.length > 0 && (
                          <p
                            className="text-danger text-sm"
                            style={{ marginTop: -10 }}
                          >
                            {linkError}
                          </p>
                        )}
                      </AvGroup>
                    </Col>

                    <Col md="5" className="mb-3 mx-2">
                      <FormGroup
                        className="position-relative"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Label>Start Date</Label>
                        <DatePicker
                          selected={startDate}
                          className="form-control"
                          onChange={(date) => setStartDate(date)}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="5" className="mb-3 mx-2">
                      <FormGroup
                        className="position-relative"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Label>End Date</Label>
                        <DatePicker
                          selected={endDate}
                          className="form-control"
                          onChange={(date) => setEndDate(date)}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="5" className="mb-3 mx-2">
                      <AvGroup className="form-group position-relative">
                        <Label>Platforms</Label>
                        <br />
                        <select
                          className="form-control"
                          value={platforms && platforms.toString()}
                          onChange={handlePlatformsChange}
                        >
                          <option value="TikTok">TikTok</option>
                          <option value="Instagram Reel">Instagram Reel</option>
                          <option value="YouTube Short">YouTube Short</option>
                          <option value="TikTok,YouTube Short">
                            TikTok,YouTube Short
                          </option>
                          <option value="TikTok,Instagram Reel">
                            TikTok,Instagram Reel
                          </option>
                          <option value="Instagram Reel,YouTube Short">
                            Instagram Reel,YouTube Short
                          </option>
                          <option value="TikTok,Instagram Reel,YouTube Short">
                            TikTok,Instagram Reel,YouTube Short
                          </option>
                          <option value="CapCut">CapCut</option>
                        </select>
                      </AvGroup>
                    </Col>
                    <Col md="5" className="mb-3 mx-2">
                      <FormGroup
                        className="position-relative"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Label>Album Image</Label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <label
                            htmlFor="file-upload"
                            className="upload-album border"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "36px",
                              backgroundSize: "cover",
                              backgroundImage: `url('${image ? image : ""}')`,
                            }}
                          >
                            {fileResult ? (
                              <img
                                src={fileResult}
                                style={{ height: "100%", width: "100%" }}
                              />
                            ) : (
                              <i className="mdi mdi-plus"></i>
                            )}
                          </label>
                          <input
                            type="file"
                            id="file-upload"
                            onChange={handleImageChange}
                            style={{ width: "140px" }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="5" className="mb-3 mx-2">
                      <AvGroup className="form-group position-relative">
                        <Label>Invoice Amount</Label>
                        <AvField
                          type="number"
                          className="form-control pl-5"
                          name="invoiceAmount"
                          id="invoiceAmount"
                          placeholder="Ex. 1500"
                          value={invoiceAmount}
                          onChange={handleInvoiceAmountChange}
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter the invoiceAmount",
                            },
                          }}
                        />
                      </AvGroup>
                    </Col>

                    <Col md="5" className="mb-3 mx-2">
                      <AvGroup className="form-group position-relative">
                        <Label>Prizes ({totalPrizes()})</Label>
                        {prizes &&
                          prizes.map((e, i) => {
                            return (
                              <div
                                key={i}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="text"
                                  value={e}
                                  onChange={(a) => {
                                    const shallowCopy = [...prizes];
                                    shallowCopy[i] = a.target.value;
                                    setPrizes(shallowCopy);
                                  }}
                                  className=" p-2 mr-2 mb-1"
                                  style={{
                                    maxHeight: 40,
                                    width: "80%",
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    borderRadius: 6,
                                  }}
                                />
                                <div
                                  className="cursor-pointer ml-2"
                                  style={{ marginLeft: 8 }}
                                  onClick={() => {
                                    const prizesWithRemoval = prizes.filter(
                                      (prize, count) => count !== i
                                    );
                                    setPrizes(prizesWithRemoval);
                                  }}
                                >
                                  <i className="mdi mdi-close" />
                                </div>
                              </div>
                            );
                          })}
                        <div
                          className="mt-2 text-primary "
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const shallowCopy = [...prizes];
                            shallowCopy.push("New Prize");
                            setPrizes(shallowCopy);
                          }}
                        >
                          Add Prize
                        </div>
                      </AvGroup>
                    </Col>

                    <Col md="5" className="mb-3 mx-2">
                      <AvGroup className="form-group position-relative">
                        <Label>Prizing Type</Label>
                        <br />
                        <select
                          name="charts"
                          id="charts"
                          className="form-control"
                          value={competitionType}
                          onChange={handleCompetitionType}
                        >
                          <option value="Hybrid">Hybrid</option>
                          <option value="Engagement">Engagement</option>
                          <option value="Creative">Creative</option>
                        </select>
                      </AvGroup>
                    </Col>
                    
                    <Col md="5" className="mb-3 mx-2">
                      <AvGroup className="form-group position-relative">
                        <Label>Continents</Label>
                        {continents &&
                          continents.map((e, i) => {
                            return (
                              <div
                                key={i}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="text"
                                  value={e}
                                  onChange={(a) => {
                                    const shallowCopy = [...continents];
                                    shallowCopy[i] = a.target.value;
                                    setContinents(shallowCopy);
                                  }}
                                  className=" p-2 mr-2 mb-1"
                                  style={{
                                    maxHeight: 40,
                                    width: "80%",
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    borderRadius: 6,
                                  }}
                                />
                                <div
                                  className="cursor-pointer ml-2"
                                  style={{ marginLeft: 8 }}
                                  onClick={() => {
                                    const continentsWithRemoval =
                                      continents.filter(
                                        (continent, count) => count !== i
                                      );
                                    setContinents(continentsWithRemoval);
                                  }}
                                >
                                  <i className="mdi mdi-close" />
                                </div>
                              </div>
                            );
                          })}
                        <div
                          className="mt-1 text-primary "
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const shallowCopy = [...continents];
                            shallowCopy.push("North America");
                            setContinents(shallowCopy);
                          }}
                        >
                          Add continent
                        </div>
                      </AvGroup>
                    </Col>
                    <Col md="5" className="mb-3 mx-2">
                      <AvGroup className="form-group position-relative">
                        <Label>Countries</Label>
                        {countries &&
                          countries.map((e, i) => {
                            return (
                              <div
                                key={i}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="text"
                                  value={e}
                                  onChange={(a) => {
                                    const shallowCopy = [...countries];
                                    shallowCopy[i] = a.target.value;
                                    setCountries(shallowCopy);
                                  }}
                                  className=" p-2 mr-2 mb-1"
                                  style={{
                                    maxHeight: 40,
                                    width: "80%",
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    borderRadius: 6,
                                  }}
                                />
                                <div
                                  className="cursor-pointer ml-2"
                                  style={{ marginLeft: 8 }}
                                  onClick={() => {
                                    const countriesWithRemoval =
                                      countries.filter(
                                        (country, count) => count !== i
                                      );
                                    setCountries(countriesWithRemoval);
                                  }}
                                >
                                  <i className="mdi mdi-close" />
                                </div>
                              </div>
                            );
                          })}
                        <div
                          className="mt-1 text-primary "
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const shallowCopy = [...countries];
                            shallowCopy.push("US");
                            setCountries(shallowCopy);
                          }}
                        >
                          Add countries
                        </div>
                      </AvGroup>
                    </Col>
                    <Col md="5" className="mb-3 mx-2">
                      <AvGroup className="form-group position-relative">
                        <Label>Is Direct?</Label>
                        <br />
                        <input
                          type="checkbox"
                          checked={isDirect}
                          onChange={handleIsDirect}
                        />
                      </AvGroup>
                    </Col>

                    <Row>
                      <Col md="5" className="mb-3 mx-2">
                        <Label>Creative Direction</Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={creativeDirection ? creativeDirection : ""}
                          height="500"
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            setCreativeDirection(data);
                          }}
                          onBlur={(event, editor) => {
                            console.log("Blur.", editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log("Focus.", editor);
                          }}
                        />
                      </Col>
                      <Col md="5" className="mb-3 mx-2">
                        <Label>Notes</Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={notes ? notes : ""}
                          height="500"
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            setNotes(data);
                          }}
                          onBlur={(event, editor) => {
                            console.log("Blur.", editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log("Focus.", editor);
                          }}
                        />
                      </Col>
                    </Row>

                    {/* <Col md="12">
                        <FormGroup className="fposition-relative">
                          <Label>Description</Label>
                          <textarea
                            name="comments"
                            id="comments"
                            rows="4"
                            className="form-control"
                            placeholder="Description :"
                          ></textarea>
                        </FormGroup>
                      </Col> */}
                  </Row>
                ) : (
                  <h3>Succesfully Added</h3>
                )}

                <br />
                <Row>
                  <Col sm="12">
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      className="btn btn-secondary float-right"
                      value={isLoading ? "Done" : "Submit"}
                      onClick={handleClick}
                      disabled={isLoading}
                    />
                  </Col>
                </Row>
              </AvForm>
              {/* <div className="mt-3 text-md-left d-sm-flex">

                    <img
                      src={profile}
                      className="avatar float-md-left avatar-medium br-4 rounded shadow mr-md-4"
                      alt=""
                    />
                    <div className="mt-md-4 mt-3 mt-sm-0">
                      <Link to="#" className="btn btn-outline-secondary mt-2">
                        {" "}
                        Change Picture{" "}
                      </Link>
                    </div>
                  </div> */}
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
    </React.Fragment>
  );
}

export default StartACompetition;
