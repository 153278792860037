import React, { Component, useEffect, useState, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { get } from "lodash";
import axios from "axios";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { getCompetition, listWinnerss } from "../../graphql/queries";
import { actionButton } from "aws-amplify";
import {
  Container,
  Pagination, PaginationItem, PaginationLink
} from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactPlayer from "react-player/soundcloud";
import ReactPlayerFile from "react-player/file";
import moment from "moment";
import "moment-timezone";
import {
  updatePost,
  updateCompetition,
  deletePost,
} from "../../graphql/mutations";
import PostBlock from "./post-block.js";
import CreativePostBlock from "./creative-post-block.js";
import queryString from "query-string";
import Skeleton from "react-skeleton-loader";
import { Post } from "models";
import { post } from "ajax-request";

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

function getYTID(pLink) {
  const pattern = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([\w.-]+)(?:\/|\?|$)/;

  const match = pLink.match(pattern);
    
    if (match && match[1]) {
        return match[1];
    } else {
        return null; // Return null if no match is found
    }
}

function getVideoId(post, platform) {
  if (post !== undefined) {
    if (post.includes("/video/")) {
      if (platform === "tiktok") {
        if (post.includes("?")) {
          return post.split("/video/")[1].split("?")[0];
        } else {
          return post.split("/video/")[1];
        }
      }
    } else {
      if (platform === "tiktok") {
        return extractTikTokPhotoId(post);
      }
    }
  }
  return false;
}

const extractTikTokPhotoId = (url) => {
  const regex = /photo\/(\d+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

function CompetitionDetail() {
  const [competition, setCompetition] = useState(null);
  const location = useLocation();
  const [limit, setLimit] = useState(10);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isPlaying, setIsPlaying] = useState(0);
  const [copied, setCopied] = useState(false);
  const [completionPct, setCompletionPct] = useState(0);

  const search = get(location, ["search"], "");
  let params = queryString.parse(location.search);
  const hash = get(location, ["hash"], "");
  const competitionId = params.id;

  const name = get(competition, ["name"], null);
  const prizes = get(competition, ["prizes"], null);
  const spotifyUrl = get(competition, ["spotify"], null);
  const totalPrizes = () => {
    if (prizes) {
      let sum = prizes[0].includes("4th") ? 300 : 0;
      prizes.forEach((element) => {
        const val = element.split("$")[1];

        sum += parseFloat(val);
      });
      return sum;
    }
  };
  const [posts, setPosts] = useState([]);
  const [newPosts, setNewPosts] = useState([]);
  const [views, setViews] = useState(0);
  const postsArr =
    posts &&
    posts.filter(function (e) {
      return e._deleted !== true;
    });
  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00-0:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    const momentT = moment(t);
    return momentT.tz(moment.tz.guess()).format("M/D/YY h:mm A z");
  };

  const fetchIndividualCompetition = async () => {
    const apiData = await API.graphql({
      query: getCompetition,
      variables: { id: competitionId },
    });
    setCompetition(apiData.data.getCompetition);
    setPosts(
      apiData.data.getCompetition.posts.items.filter(function (e) {
        return e._deleted !== true;
      })
    );
    setNewPosts(
      apiData.data.getCompetition.posts.items.filter(function (e) {
        return e._deleted !== true && e.bonusLikes === null;
      })
    );
  };
  const organizer = get(competition, ["organizer"], null);
  const competitionType = get(competition, ["competitionType"], null);
  const creativeDirection = get(competition, ["creativeDirection"], '');
  const continents = get(competition, ["continents"], []);
  const countries = get(competition, ["countries"], []);
  const platforms = get(competition, ["platforms"], '');
  const returnVs = (type, e, bonusLikesNum, viewNum, shareNum, commentNum, postedDate, postLink, likeNum) => {
    if (type === "leaderboard") {
      return parseFloat(viewNum) > 0 ? {
        input: {
          id: e.id,
          bonusLikes: bonusLikesNum,
          likes: bonusLikesNum + parseFloat(likeNum),
          link: postLink,
          shares: parseFloat(shareNum),
          comments: parseFloat(commentNum),
          postedDate: postedDate,
          views: parseFloat(viewNum),
          _version: e._version,
        },
      } : {
        input: {
          id: e.id,
          bonusLikes: bonusLikesNum,
          likes: bonusLikesNum + parseFloat(likeNum),
          link: postLink,
          shares: parseFloat(shareNum),
          comments: parseFloat(commentNum),
          postedDate: postedDate,
          _version: e._version,
        },
      };
    } else {
      return parseFloat(viewNum) > 0 ? {
        input: {
          id: e.id,
          views: parseFloat(viewNum),
          _version: e._version,
        },
      } : {
        input: {
          id: e.id,
          views: parseFloat(viewNum),
          _version: e._version,
        },
      };
    }
  }
  
  const updateLeaderboard = async (type) => {
    const apiData = await API.graphql({
      query: getCompetition,
      variables: { id: competitionId },
    });
    const delayNum = platforms && platforms[0].toLowerCase().includes("youtube") ? 5000 : 0;
    const listOfPosts = apiData.data.getCompetition.posts.items.filter(
      function (e) {
        const postLink = get(e, ["link"], "");
        
        const postPlatform = get(e, ["platform"], "");
        if (postPlatform === "tiktok") {
          return (
            getVideoId(postLink, postPlatform) &&
            e.bonusLikes !== null &&
            !e._deleted
          );
        } else {
          return e.bonusLikes !== null && !e._deleted;
        }
      }
    );
    let n = 0;
    const timer = 
      listOfPosts.map( (e, i) => {
        setTimeout(async () => {
          let counterDelay = 1000;
          if (e.platform === "tiktok") {
            counterDelay = 0;
            const postLink = get(e, ["link"], "");
            const postPlatform = get(e, ["platform"], "");
            const referralNumber = get(e, ["referrals", "items"], []);
            if (postLink.includes("/photo/")) {
              const postPhotoId = extractTikTokPhotoId(postLink);
              await fetch(
                `https://tiktok82.p.rapidapi.com/getPostDetails?post_id=${postPhotoId}`,
                {
                  method: "GET",
                  headers: {
                    "x-rapidapi-key":
                      "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
                    "x-rapidapi-host": "tiktok82.p.rapidapi.com",
                  },
                }
              )
                .then(async (res) => {
                  if (res.ok) {
                    return res.text();
                  } else {
                    console.log("failed");
                  }
                  throw res;
                })
                .then(async (result) => {
                  const resultObj = JSON.parse(result);
                  const likeNum = get(
                    resultObj,
                    ["data", "stats", "diggCount"],
                    0
                  );
                  const viewNum = get(
                    resultObj,
                    ["data", "stats", "playCount"],
                    0
                  );
                  const shareNum = get(resultObj, ["data", "stats", "shareCount"], 0);
                  const commentNum = get(resultObj, ["data", "stats", "commentCount"], 0);
                  const postedDateTime = get(resultObj, ["data","createTime"], 0);
                  const postedDate = calcDateValue(parseInt(postedDateTime));
  
                  let bonusLikesNum = 0;
                  
                  const variables =  returnVs(type, e, bonusLikesNum, viewNum, shareNum, commentNum, postedDate, postLink, likeNum);
                  n++;
                  const createPostResult = await API.graphql(
                    graphqlOperation(updatePost, variables)
                  );
  
                  await setCompletionPct((n / listOfPosts.length) * 100);
                  // console.log("like arr length", likeArrLength, listOfPostsLength);
                })
            } else {
              await fetch(
                `https://social-media-data-tt.p.rapidapi.com/live/post/meta/v2?video=${!e.link.includes("www.") ? e.link.replace("tiktok.com","www.tiktok.com") : e.link}`,
                {
                  method: "GET",
                  headers: {
                    "x-rapidapi-key":
                      "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
                    "x-rapidapi-host": "social-media-data-tt.p.rapidapi.com",
                  },
                }
              )
                .then(async (res) => {
                  if (res.ok) {
                    return res.text();
                  } else {
                    console.log("failed");
                  }
                  throw res;
                })
                .then(async (result) => {
                  const resultObj = JSON.parse(result);
                  const likeNum = get(
                    resultObj,
                    ["diggCount"],
                    0
                  );
                  const viewNum = get(
                    resultObj,
                    ["playCount"],
                    0
                  );
                  const shareNum = get(resultObj, ["shareCount"], 0);
                  const commentNum = get(resultObj, ["commentCount"], 0);
                  const postedDateTime = get(resultObj, ["createTime"], 0);
                  const postedDate = calcDateValue(parseInt(postedDateTime));
  
                  let bonusLikesNum = 0;
                  
                  const variables =  returnVs(type, e, bonusLikesNum, viewNum, shareNum, commentNum, postedDate, postLink, likeNum);
                  n++;
                  const createPostResult = await API.graphql(
                    graphqlOperation(updatePost, variables)
                  );
  
                  await setCompletionPct((n / listOfPosts.length) * 100);
                  // console.log("like arr length", likeArrLength, listOfPostsLength);
                }).catch(async (error)=>{
                  console.log(error);
                  await fetch(
                    `https://tiktok-scraper7.p.rapidapi.com/?url=${!e.link.includes("www.") ? e.link.replace("tiktok.com","www.tiktok.com") : e.link}&hd=1`,
                    {
                      method: "GET",
                      headers: {
                        "x-rapidapi-key":
                          "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
                        "x-rapidapi-host": "tiktok-scraper7.p.rapidapi.com",
                      },
                    }
                  )
                    .then(async (res) => {
                      if (res.ok) {
                        return res.text();
                      } else {
                        console.log("failed");
                      }
                      throw res;
                    })
                    .then(async (result) => {
                      const resultObj = JSON.parse(result);
                      console.log("result obkj",resultObj)

                      const likeNum = get(
                        resultObj,
                        ["data","digg_count"],
                        0
                      );
                      const viewNum = get(
                        resultObj,
                        ["data","play_count"],
                        0
                      );
                      const shareNum = get(resultObj, ["data","share_count"], 0);
                      const commentNum = get(resultObj, ["data","comment_count"], 0);
                      const postedDateTime = get(resultObj, ["data","create_time"], 0);
                      const postedDate = calcDateValue(parseInt(postedDateTime));

                      let bonusLikesNum = 0;

                      const variables = returnVs(type, e, bonusLikesNum, viewNum, shareNum, commentNum, postedDate, postLink, likeNum);
                      n++;
                      const createPostResult = await API.graphql(
                        graphqlOperation(updatePost, variables)
                      );

                      await setCompletionPct((n / listOfPosts.length) * 100);
                      
                    })
                  
                });
            }
            
          } else if (e.platform === "instagram") {
            counterDelay = 1000;
            const postLink = get(e, ["link"], "");   
            const regex = /(?:reels|reel)\/([\w-]+)[\/?]?/i;
            const match = e.link.match(regex);
                
            const resp = await fetch(
              `https://instagram-scraper-api2.p.rapidapi.com/v1/post_info?code_or_id_or_url=https://www.instagram.com/p/${match[1]}&include_insights=true`,
              {
                method: "GET",
      
                headers: {
                  "X-RapidAPI-Host": "instagram-scraper-api2.p.rapidapi.com",
                  "X-RapidAPI-Key":
                    "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
                },
              }
            )
              .then((response) => response.text())
              .then(async (result) => {
      
                const resultObj = JSON.parse(result);
                const likeNum = get(
                  resultObj,
                  ["data","metrics","like_count"],
                  0
                );
                const viewNum = get(
                  resultObj,
                  ["data","metrics","play_count",],
                  0
                );
                const commentNum = get(
                  resultObj,
                  ["data","metrics","comment_count"],
                  0
                );

                const variables = {
                  input: {
                    id: e.id,
                    bonusLikes: 0,
                    likes: likeNum,
                    link: postLink,
                    views: viewNum,
                    comments: commentNum,
                    _version: e._version,
                  },
                };
                n++;
                const createPostResult = await API.graphql(
                  graphqlOperation(updatePost, variables)
                );

                await setCompletionPct((n / listOfPosts.length) * 100);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            counterDelay = 1000;
            const postLink = get(e, ["link"], "");
            
            const postPlatform = get(e, ["platform"], "");
            const referralNumber = get(e, ["referrals", "items"], []);
            const resp = await fetch(
              `https://youtube138.p.rapidapi.com/video/details?id=${getYTID(postLink)}`,
              {
                method: "GET",
                
                headers: {
                  "X-RapidAPI-Host": "youtube138.p.rapidapi.com",
                  "X-RapidAPI-Key":
                    "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
                },
              }
            )
              .then((response) => response.text())
              .then(async (result) => {
                
                const resultObj = JSON.parse(result);
                console.log("result", resultObj);
                const likeNum = get(
                  resultObj,
                  ["stats","likes"],
                  0
                );
                const viewNum = get(
                  resultObj,
                  ["stats","views"],
                  0
                );
                const commentNum = get(
                  resultObj,
                  ["stats","comments"],
                  0
                );
              

                const variables = {
                  input: {
                    id: e.id,
                    bonusLikes: 0,
                    likes: likeNum,
                    link: postLink,
                    views: viewNum,
                    comments: commentNum,
                    _version: e._version,
                  },
                };
                n++;
                const createPostResult = await API.graphql(
                  graphqlOperation(updatePost, variables)
                );

                await setCompletionPct((n / listOfPosts.length) * 100);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }, `${e.platform === "tiktok" ? 0 : (1000 * i)}`); // Use i to stagger the log output by 2 seconds for each index
      });
    return () => clearInterval(timer);
  };
  const updateTime = async () => {
    const vs = {
      input: {
        id: competition.id,
        name: competition.name,
        lastUpdated: new Date().getTime(),
        _version: competition._version,
      },
    };
    const updateComp = await API.graphql(
      graphqlOperation(updateCompetition, vs)
    );
  };
  useEffect(() => {
    // fetchCompetitions();
    fetchIndividualCompetition();
    // getIGPostLikes();
  }, []);
  // console.log(views);
  const postIdArr = [];
  postsArr.map((e) => {
    
    if (
      e.link.includes("tiktok") &&
      !e.link.includes("vm.tikok") &&
      !e.link.includes("vt.tiktok") &&
      e.link.includes("/video/") &&
      e.bonusLikes !== null
    ) {
      postIdArr.push(e.link.split("/video/")[1].split("?")[0]);
    } else if (e.link.includes("youtube") && e.bonusLikes !== null) {
      postIdArr.push(getYTID(e.link));
    } else if (e.link.includes("instagram") && e.bonusLikes !== null) {
      const regex = /(?:reels|reel)\/([\w-]+)[\/?]?/i;
      const match = e.link.match(regex);
      if (match && match[1]) {
        postIdArr.push(match[1]);
      } else {
        return null;
      }
    }
  });
  console.log("posts id arr",postIdArr);
  return (
    <React.Fragment>
      <section className="bg-profile d-table w-100 mt-4 pt-4">
        <Container>
          <br />
          <div
            className="border bg-white p-4 d-flex mt-4"
            style={{
              
              borderRadius: 8,
              flexDirection:'column'
            }}
          >
            <div style={{display:'flex',flexDirection:'row',justifyContent: "space-between",
              alignItems: "center",}}>
              <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                <h4 className="title mb-0" style={{ lineHeight: 1.2 }}>
                  {competition ? (
                    name
                  ) : (
                    <Skeleton
                      width="150px"
                      height="24px"
                      widthRandomness={0}
                      color="#ced5e0"
                    />
                  )}
                </h4>
                <div>
                  <button
                    className="btn btn-secondary" style={{marginRight:4}}
                    onClick={async () => {
                      setIsUpdating(true);
                      await updateLeaderboard("views");
                      updateTime();
                    }}
                  >
                    {isUpdating && completionPct < 100 ? (
                      <CircularProgress
                        variant="determinate"
                        value={completionPct}
                        color="white"
                      />
                    ) : (
                      "Update Views"
                    )}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={async () => {
                      setIsUpdating(true);
                      await updateLeaderboard("leaderboard");
                      updateTime();
                    }}
                  >
                    {isUpdating && completionPct < 100 ? (
                      <CircularProgress
                        variant="determinate"
                        value={completionPct}
                        color="white"
                      />
                    ) : (
                      "Update Leaderboard"
                    )}
                  </button>
                </div>
              </div>
            
            </div>
            <p className="text-muted border-bottom mt-2" style={{width:'100%'}}></p>
            <div dangerouslySetInnerHTML={{__html: creativeDirection}} />

          </div>
          <div
            style={{
              display: "grid",
              margin: "auto",
              gridTemplateColumns: '330px 330px 330px',
              gridColumnGap: '4px',
            }}
          >
            {newPosts.map((e,i) => {
              function getIGEmbedLink() {
                if (e.link.includes("?")) {
                  return e.link.split("?")[0];
                } else {
                  return e.link + "/";
                }
              }
              if (e.bonusLikes === null && i < limit) {
                return (<PostBlock
                  key={i}
                  post={e}
                  startDate={competition.startDate}
                  idArr={postIdArr}
                  continents={continents}
                  countries={countries}
                />);
              }
            })}
          </div>
          {
            limit+10 < newPosts.length &&(
              <div className="btn btn-primary full-width" style={{width:'100%'}} onClick={()=>{setLimit(limit+10)}}>Load More</div>
            )
          }
        </Container>
      </section>
    </React.Fragment>
  );
}

export default CompetitionDetail;
