import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter, useHistory, useLocation } from "react-router-dom"
import request from "ajax-request";
import UpdateModal from "../../../common/UpdateModal";
import {get} from "lodash"
import { getProjectDetail } from "store/projects/actions"
import { map } from "lodash"
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Badge,
  Button,
  Container,
  Media,
  Row,
  Table,
} from "reactstrap"
import Comments from "./comments";
import Activity from "./activity";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import queryString from "query-string";
//Import mini card widgets

//Import Images

// import charts
import { getUserProfile } from "store/actions"


const ContactsProfile = props => {
  const { userProfile, onGetUserProfile } = props
  const [modal, setModal] = useState(false);
  const [inquiry, setInquiry] = useState(null);
  const [inquiryNew, setInquiryNew] = useState(null);
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [contacts, setContacts] = useState([]);

  const determineBadgeColor = (stat) => {
    if (stat === "In Progress") {
      return "warning";
    } else if (stat === "Completed") {
      return "success";
    } else {
      return "danger";
    }
  };
  const toggle = () => {
    setModal(!modal);
  };
  // eslint-disable-next-line no-unused-vars
  const handleDelete = () => {
    request(
      {
        url: `https://www.songfluencer.me/portal2/inquiries/DeleteInquiryById.cfm/?id=${params.id}&Token=${params.LoginToken}`,
        method: "POST",
        crossDomain: true,
      },
      function (err, res, body) {
        window.location.href = "/inquiries";
      }
    );
  };

  useEffect(() => {
    request(
      {
        url: `https://www.songfluencer.me/portal2/inquiries/GetInquiryByID.cfm/?id=${params.id}&Token=${params.LoginToken}`,
        method: "POST",
        crossDomain: true,
      },
      function (err, res, body) {
        // console.log("inquiry by id",body);
        const bodyInJSON = JSON.parse(body);
        setInquiry(bodyInJSON);
        setInquiryNew({
          id: bodyInJSON.INQUIRYID,
          phone: bodyInJSON.PHONE,
          firstName: bodyInJSON.FIRSTNAME,
          lastName: bodyInJSON.LASTNAME,
          email: bodyInJSON.EMAIL,
          assignedTo: bodyInJSON.ASSIGNEDTO,
          status: bodyInJSON.INQUIRYSTATUS,
        });
      }
    );
    request(
      {
        url: `https://www.songfluencer.me/portal2/inquiries/GetContacts.cfm/?Token=${params.LoginToken}`,
        method: "POST",
        crossDomain: true,
      },
      function (err, res, body) {
        // console.log("get contacts",body);
        const bodyInJSON = JSON.parse(body);
        if (bodyInJSON.Request === "Valid") {
          setContacts(bodyInJSON.Data);
        } else {
          window.location.href = "https://songfluencer.me/login_admin.cfm";
        }
      }
    );

    
  }, [location]);
  const message = get(inquiry, ["INQUIRYMESSAGE"], "");
  const phone = get(inquiry, ["PHONE"], "");
  const fullName =
    get(inquiry, ["FIRSTNAME"], "") + " " + get(inquiry, ["LASTNAME"], "");
  const assignedTo =
    get(inquiry, ["ASSIGNEDTO"], "");
  const status = get(inquiry, ["INQUIRYSTATUS"], "Unseen");
  const platforms = get(inquiry, ["PLATFORMS"], "");
  const email = get(inquiry, ["EMAIL"], "");
  const date = get(inquiry, ["DATE"], "");
  const source = get(inquiry, ["SOURCE"], "");
  const type = get(inquiry, ["INQUIRYTYPE"], "");
  useEffect(() => {
    onGetUserProfile()
  }, [onGetUserProfile])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Inquiry Detail</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Inquiries" breadcrumbItem={fullName} />

          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Message</CardTitle>
                  <p className="text-muted mb-4">{message}</p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{fullName}</td>
                        </tr>

                        <tr>
                          <th scope="row">Status:</th>
                          <td>
                            <Badge
                              className={
                                "font-size-12 badge-soft-" +
                                determineBadgeColor(status || "Unseen")
                              }
                              color={determineBadgeColor(status || "Unseen")}
                              pill
                            >
                              {status || "Unseen"}
                            </Badge>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">Email:</th>
                          <td>{email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Phone:</th>
                          <td>{phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">Date:</th>
                          <td>{date}</td>
                        </tr>

                        <tr>
                          <th scope="row">Source:</th>
                          <td>{source}</td>
                        </tr>
                        <tr>
                          <th scope="row">Type:</th>
                          <td>{type}</td>
                        </tr>
                        <tr>
                          <th scope="row">Platforms:</th>
                          <td>{platforms}</td>
                        </tr>
                        <tr>
                          <th scope="row">Assigned To:</th>
                          <td>{assignedTo}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                <div className="text-center mb-4">
                  <Button
                    color="primary"
                    onClick={() => {
                      toggle();
                    }}
                    className="btn btn-primary me-2 w-md"
                  >
                    Update
                  </Button>
                  <Button
                    className="btn btn-outline-light me-2 w-md bg-white text-primary"
                    color="danger"
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>{" "}
                  <UpdateModal
                    modal={modal}
                    toggle={toggle}
                    assigned2={assignedTo}
                    inquiryNew={inquiryNew}
                    setInquiryNew={setInquiryNew}
                    contacts={contacts}
                  />
                </div>
              </Card>
            </Col>

            <Col xl="8">
              <Comments />
              <Activity />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
})

const mapDispatchToProps = dispatch => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile))
