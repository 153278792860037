import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import request from "ajax-request";
import queryString from "query-string";
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link, useLocation } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [userInfo, setUserInfo] = useState()
  let location = useLocation();
  let params = queryString.parse(location.search);
  useEffect(() => {
     request(
       {
         url: `https://www.songfluencer.me/portal2/inquiries/GetUser.cfm/?Token=${params.LoginToken}`,
         method: "POST",
         crossDomain: true,
       },
       function (err, res, body) {
         const bodyInJSON = JSON.parse(body);
         console.log("body", bodyInJSON);
         setUserInfo(bodyInJSON.Data);
       }
     );
  }, [])
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {userInfo && userInfo[0].EMAILADDRESS_PRIMARY}
          </span>
        </DropdownToggle>
      </Dropdown>
    </React.Fragment>
  );
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
