import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Storage, API, graphqlOperation } from "aws-amplify";
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import request from "ajax-request"
import { Link,withRouter, useHistory, useLocation } from "react-router-dom";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import queryString from "query-string";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from 'moment';
import UpdateModal from "../../../common/UpdateModal"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getInquiries,
  addNewOrder,
  updateOrder,
  deleteOrder
} from "store/actions"
import { listAllCompetitions, listCompetitions } from "../../../graphql/queries";
import AddANoteModal from "common/AddANoteModal"

const EcommerceInquiries = props => {

  const location = useLocation();
  let params = queryString.parse(location.search);
  const [competitions, setCompetitions] = useState([]);
  const [inquiries, setInquiries] = useState([]);
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const determineBadgeColor = (stat) =>{
    if (stat === "In Progress") {
      return "warning";
    } else if (stat === "Completed") {
      return "success";
    } else {
      return "danger";
    }
  }
  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    emailSize: inquiries.length, // replace later with size(inquiries),
    custom: true,
  }
  const { SearchBar } = Search
  // const toggleModal = () => {
  //   setModal1(!modal1)
  // }
  const toggleViewModal = () => setModal1(!modal1);

  const InquiryColumns = (toggleModal) => [
    {
      dataField: "FIRSTNAME",
      text: "Full Name",
      formatter: (cellContent, row) => (
        <>{row.FIRSTNAME + " " + row.LASTNAME}</>
      ),
    },
    {
      dataField: "DATE",
      text: "Date",
      formatter: (cellContent, row) => handleValidDate(row.DATE),
    },
    {
      dataField: "EMAIL",
      text: "Email",
    },
    {
      dataField: "SOURCE",
      text: "Source",
    },
    {
      dataField: "ASSIGNEDTO",
      text: "Assigned To",
    },
    {
      dataField: "INQUIRYTYPE",
      text: "Inquiry Type",
    },
    {
      dataField: "inquiryStatus",
      text: "Status",
      formatter: (cellContent, row) => (
        <Badge
          className={
            "font-size-12 badge-soft-" + determineBadgeColor(row.INQUIRYSTATUS)
          }
          color={determineBadgeColor(row.INQUIRYSTATUS)}
          pill
        >
          {row.INQUIRYSTATUS ? row.INQUIRYSTATUS : "Unseen"}
        </Badge>
      ),
    },

    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      formatter: (cellContent, row) => (
        <Link
          to={`/contacts-profile/?id=${row.INQUIRYID}&LoginToken=${params.LoginToken}`}
        >
          <Button
            type="button"
            color="light"
            className="btn-sm btn-outline-primary btn-rounded bg-white text-primary"
          >
            View Details
          </Button>
        </Link>
      ),
    },
  ];

  const fetchCompetitions = async () => {
    const apiData = await API.graphql({
      query: listCompetitions,
      variables: { limit: 10000, filter: { _deleted: { neq: true } } },
    });
    setCompetitions(
      apiData.data.listCompetitions.items
        .filter(function (e) {
          return e._deleted !== true;
        })
    );
  };

  useEffect(() => {
    request(
      {
        url: `https://www.songfluencer.me/portal2/inquiries/GetInquiries.cfm?Token=${params.LoginToken}`,
        method: "POST",
        crossDomain: true,
      },
      function (err, res, body) {
        const bodyInJSON = JSON.parse(body);
        console.log(bodyInJSON);
        if (bodyInJSON.Request === "Valid") {
          if (params.status === "unseen") {
            setInquiries(
              bodyInJSON.Data.filter(
                (obj) =>
                  obj.INQUIRYSTATUS === "Unseen" || obj.INQUIRYSTATUS === ""
              ).reverse()
            );
          } else if (params.status === "inprogress") {
            setInquiries(
              bodyInJSON.Data.filter(
                (obj) => obj.INQUIRYSTATUS === "In Progress"
              ).reverse()
            );
          } else if (params.status === "completed") {
            setInquiries(
              bodyInJSON.Data.filter(
                (obj) => obj.INQUIRYSTATUS === "Completed"
              ).reverse()
            );
          } else {
            setInquiries(bodyInJSON.Data.reverse());
          }
        } else {
          //  window.location.href= "https://songfluencer.me/login_admin.cfm"
        }
      }
    );
    fetchCompetitions();
  }, [])

  console.log("competitions",competitions);

  const toggle = () => {
    setModal(!modal)
  }

  const toLowerCase1 = (str) => {
    return str.toLowerCase();
  }

  const handleOrderClick = arg => {
    const order = arg

    setOrderList({
      id: order.id,
      fullName: order.fullName,
      orderdate: order.orderdate,
      email: order.email,
      inquiryStatus: order.inquiryStatus,
      badgeclass: order.badgeclass
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = (order) => {
    const { onDeleteOrder } = props
    onDeleteOrder(order)
  }

  const handleValidOrderSubmit = (e, values) => {
    const { onAddNewOrder, onUpdateOrder } = props

    if (isEdit) {
      const updateOrder = {
        id: orderList.id,
        fullName: values.fullName,
        orderdate: values.orderdate,
        email: values.email,
        inquiryStatus: values.inquiryStatus,
        badgeclass: values.badgeclass
      }

      // update order
      onUpdateOrder(updateOrder)

    } else {

      const newOrder = {
        id: Math.floor(Math.random() * (30 - 20)) + 20,
        fullName: values["fullName"],
        orderdate: values["orderdate"],
        email: values["email"],
        inquiryStatus: values["inquiryStatus"],
        badgeclass: values['badgeclass']
      }
      // save new order
      onAddNewOrder(newOrder)

    }
    toggle()
  }

  const handleOrderClicks = () => {
    setOrderList('')
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = (date) => {
    const date1 = moment(date).format('YYYY-MM-DD');
    return date1;
  }

  
  const returnTitle = () => {
    if (params.status === "unseen") {
      return "Unseen Inquiries";
    } else if (params.status === "inprogress") {
      return "In Progress Inquiries";
    } if (params.status === "completed") {
      return "Completed Inquiries";
    } else {
      return "All Inquiries";
    }
  }
  return (
    <React.Fragment>
      <AddANoteModal modal={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <MetaTags>
          <title>Inquiries</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Inquiries" breadcrumbItem={returnTitle()} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={InquiryColumns(toggle)}
                    data={inquiries}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={inquiries}
                        columns={InquiryColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-white"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                                <UpdateModal modal={modal} toggle={toggle} />
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EcommerceInquiries.propTypes = {
  inquiries: PropTypes.array,
  onGetInquiries: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func
}

const mapStateToProps = state => ({
  inquiries: state.ecommerce.inquiries,
})

const mapDispatchToProps = dispatch => ({
  onGetInquiries: () => dispatch(getInquiries()),
  onAddNewOrder: order => dispatch(addNewOrder(order)),
  onUpdateOrder: order => dispatch(updateOrder(order)),
  onDeleteOrder: order => dispatch(deleteOrder(order)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EcommerceInquiries))