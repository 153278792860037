import React, { Component, useEffect, useState, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { get } from "lodash";
import axios from "axios";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { getCompetition, listWinnerss } from "../../graphql/queries";
import { actionButton } from "aws-amplify";
import {
  Container,
  Pagination, PaginationItem, PaginationLink
} from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactPlayer from "react-player/soundcloud";
import ReactPlayerFile from "react-player/file";
import moment from "moment";
import "moment-timezone";
import {
  updatePost,
  updateCompetition,
  deletePost,
} from "../../graphql/mutations";
import PostBlock from "./post-block.js";
import queryString from "query-string";
import Skeleton from "react-skeleton-loader";
import { Post } from "models";
import { post } from "ajax-request";

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

function getYTEmbed(pLink) {
  if (pLink.includes("shorts/")) {
    if (pLink.includes("?")) {
      return pLink.split("shorts/")[1].split("?")[0];
    } else {
      return pLink.split("shorts/")[1];
    }
  } else {
    return "";
  }
}

function getVideoId(post, platform) {
  if (post !== undefined) {
    if (post.includes("/video/")) {
      if (platform === "tiktok") {
        if (post.includes("?")) {
          return post.split("/video/")[1].split("?")[0];
        } else {
          return post.split("/video/")[1];
        }
      }
    }
  }
  return false;
}

function CompetitionDetail() {
  const [competition, setCompetition] = useState(null);
  const location = useLocation();
  const [limit, setLimit] = useState(10);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isPlaying, setIsPlaying] = useState(0);
  const [copied, setCopied] = useState(false);
  const [completionPct, setCompletionPct] = useState(0);

  const search = get(location, ["search"], "");
  let params = queryString.parse(location.search);
  const hash = get(location, ["hash"], "");
  const competitionId = params.id;

  const name = get(competition, ["name"], null);
  const prizes = get(competition, ["prizes"], null);
  const spotifyUrl = get(competition, ["spotify"], null);
  const totalPrizes = () => {
    if (prizes) {
      let sum = prizes[0].includes("4th") ? 300 : 0;
      prizes.forEach((element) => {
        const val = element.split("$")[1];

        sum += parseFloat(val);
      });
      return sum;
    }
  };
  const [posts, setPosts] = useState([]);
  const [newPosts, setNewPosts] = useState([]);
  const [views, setViews] = useState(0);
  const postsArr =
    posts &&
    posts.filter(function (e) {
      return e._deleted !== true;
    });
  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00-0:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    const momentT = moment(t);
    return momentT.tz(moment.tz.guess()).format("M/D/YY h:mm A z");
  };

  const fetchIndividualCompetition = async () => {
    const apiData = await API.graphql({
      query: getCompetition,
      variables: { id: competitionId },
    });
    setCompetition(apiData.data.getCompetition);
    setPosts(
      apiData.data.getCompetition.posts.items.filter(function (e) {
        return e._deleted !== true;
      })
    );
    setNewPosts(
      apiData.data.getCompetition.posts.items.filter(function (e) {
        return e._deleted !== true && e.likes === 0;
      })
    );
  };
  console.log(newPosts);
  const organizer = get(competition, ["organizer"], null);
  const competitionType = get(competition, ["competitionType"], null);
  
  const updateLeaderboard = async () => {
    const apiData = await API.graphql({
      query: getCompetition,
      variables: { id: competitionId },
    });
    const listOfPosts = apiData.data.getCompetition.posts.items.filter(
      function (e) {
        const postLink = get(e, ["link"], "");
        const postPlatform = get(e, ["platform"], "");
        if (postPlatform === "tiktok") {
          return (
            getVideoId(postLink, postPlatform)  &&
            !e._deleted
          );
        } else {
          return  !e._deleted;
        }
      }
    );
    let n = 0;
    const delay = 1000 / 3; // 3 requests per second
    const results = [];
    listOfPosts.map(async (e, i) => {
      if (e.platform === "tiktok") {
        const postLink = get(e, ["link"], "");
        const postPlatform = get(e, ["platform"], "");
        const referralNumber = get(e, ["referrals", "items"], []);
        await fetch(
          `https://social-media-data-tt.p.rapidapi.com/live/post/meta/v2?video=${!e.link.includes("www.") ? e.link.replace("tiktok.com","www.tiktok.com") : e.link}`,
          {
            method: "GET",
            headers: {
              "x-rapidapi-key":
                "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
              "x-rapidapi-host": "social-media-data-tt.p.rapidapi.com",
            },
          }
        )
          .then(async (res) => {
            if (res.ok) {
              return res.text();
            } else {
              console.log("failed");
            }
            throw res;
          })
          .then(async (result) => {
            const resultObj = JSON.parse(result);
            const likeNum = get(
              resultObj,
              ["diggCount"],
              0
            );
            const viewNum = get(
              resultObj,
              ["playCount"],
              0
            );
            const shareNum = get(resultObj, ["shareCount"], 0);
            const commentNum = get(resultObj, ["commentCount"], 0);
            const postedDateTime = get(resultObj, ["createTime"], 0);
            const postedDate = calcDateValue(parseInt(postedDateTime));
            console.log("like count", resultObj, likeNum, viewNum, postedDate);

            let bonusLikesNum = 0;
            
            const variables = {
              input: {
                id: e.id,
                bonusLikes: bonusLikesNum,
                likes: bonusLikesNum + parseFloat(likeNum),
                link: postLink,
                shares: parseFloat(shareNum),
                comments: parseFloat(commentNum),
                postedDate: postedDate,
                views: parseFloat(viewNum),
                _version: e._version,
              },
            };
            n++;
            const createPostResult = await API.graphql(
              graphqlOperation(updatePost, variables)
            );

            await setCompletionPct((n / listOfPosts.length) * 100);
            // console.log("like arr length", likeArrLength, listOfPostsLength);
          }).catch(async (error)=>{
            console.log(error);
            await fetch(
              `https://social-media-data-tt.p.rapidapi.com/live/post/meta/v2?video=${!e.link.includes("www.") ? e.link.replace("tiktok.com","www.tiktok.com") : e.link}`,
              {
                method: "GET",
                headers: {
                  "x-rapidapi-key":
                    "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
                  "x-rapidapi-host": "social-media-data-tt.p.rapidapi.com",
                },
              }
            )
              .then(async (res) => {
                if (res.ok) {
                  return res.text();
                } else {
                  console.log("failed");
                }
                throw res;
              })
              .then(async (result) => {
                const resultObj = JSON.parse(result);
                const likeNum = get(
                  resultObj,
                  ["diggCount"],
                  0
                );
                const viewNum = get(
                  resultObj,
                  ["playCount"],
                  0
                );
                const shareNum = get(resultObj, ["shareCount"], 0);
                const commentNum = get(resultObj, ["commentCount"], 0);
                const postedDateTime = get(resultObj, ["createTime"], 0);
                const postedDate = calcDateValue(parseInt(postedDateTime));
                console.log("like count", resultObj, likeNum, viewNum, postedDate);
    
                let bonusLikesNum = 0;
                
                const variables = {
                  input: {
                    id: e.id,
                    bonusLikes: bonusLikesNum,
                    likes: bonusLikesNum + parseFloat(likeNum),
                    link: postLink,
                    shares: parseFloat(shareNum),
                    comments: parseFloat(commentNum),
                    postedDate: postedDate,
                    views: parseFloat(viewNum),
                    _version: e._version,
                  },
                };
                n++;
                const createPostResult = await API.graphql(
                  graphqlOperation(updatePost, variables)
                );
    
                await setCompletionPct((n / listOfPosts.length) * 100);
                // console.log("like arr length", likeArrLength, listOfPostsLength);
              })
            
          });
      } else if (e.platform === "instagram") {
        const scrapBee =
          "https://app.scrapingbee.com/api/v1/?api_key=D9PGR3XEYE8QHV8KXQ9PP13XDX20OG2UJR923XXWQ2D2XBJEQPEQ5EJ8HMJ7Q1GM07Q26SLARE52I5SK&url=";
        const newLink =
          e.link.includes("?") && e.link.includes("instagram.com")
            ? scrapBee + e.link.split("?")[0]
            : scrapBee + e.link;
        axios
          .get(
            `${
              newLink.substring(newLink.length - 1) === "/"
                ? newLink
                : newLink + "/"
            }embed`
          )
          .then(async (data) => {
            const dataStr = data.data;
            let likeNum = 0;
            if (dataStr !== undefined) {
              likeNum = dataStr.split('edge_liked_by":{"count":')[1];
              if (likeNum !== undefined && likeNum.split("}}})")[0]) {
                likeNum = likeNum.split("}}})")[0];
              } else {
                if (dataStr.includes("likeCountClick")) {
                  likeNum = parseFloat(
                    dataStr
                      .split("likeCountClick")[1]
                      .split('target="_blank">')[1]
                      .split("likes")[0]
                      .replace(/,/g, "")
                  );
                }
              }
              if (likeNum === undefined) {
                likeNum = 0;
              }
              let bonusLikesNum = 0;
              const variables = {
                input: {
                  id: e.id,
                  bonusLikes: bonusLikesNum,
                  likes: bonusLikesNum + parseFloat(likeNum),
                  views: parseFloat(likeNum) * 4,
                  link: e.link,
                  _version: e._version,
                },
              };
              const createPostResult = await API.graphql(
                graphqlOperation(updatePost, variables)
              );
            }
            n++;
            await setCompletionPct((n / listOfPosts.length) * 100);
          });
      } else {
        const postLink = get(e, ["link"], "");
        
        const postPlatform = get(e, ["platform"], "");
        const referralNumber = get(e, ["referrals", "items"], []);
        const resp = await fetch(
          `https://youtube-v31.p.rapidapi.com/videos?params='contentDetails,snippet,statistics'&id=${getYTEmbed(postLink)}`,
          {
            method: "GET",
            
            headers: {
              "X-RapidAPI-Host": "youtube-v31.p.rapidapi.com",
              "X-RapidAPI-Key":
                "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
            },
          }
        )
          .then((response) => response.text())
          .then(async (result) => {
            
            const resultObj = JSON.parse(result);
            console.log("result", resultObj);
            const likeNum = get(
              resultObj,
              ["items", 0, "statistics", "likeCount"],
              0
            );
            const viewNum = get(
              resultObj,
              ["items", 0, "statistics", "viewCount"],
              0
            );
           

            console.log("likenum", likeNum);
            console.log("viewnum", viewNum);

            const variables = {
              input: {
                id: e.id,
                bonusLikes: 0,
                likes: likeNum,
                link: postLink,
                views: viewNum,
                _version: e._version,
              },
            };
            n++;
            const createPostResult = await API.graphql(
              graphqlOperation(updatePost, variables)
            );

            await setCompletionPct((n / listOfPosts.length) * 100);
          })
          .catch((error) => {
            console.log(error);
          });

      }
    });
  };
  const updateTime = async () => {
    const vs = {
      input: {
        id: competition.id,
        name: competition.name,
        lastUpdated: new Date().getTime(),
        _version: competition._version,
      },
    };
    const updateComp = await API.graphql(
      graphqlOperation(updateCompetition, vs)
    );
  };
  useEffect(() => {
    // fetchCompetitions();
    fetchIndividualCompetition();
    // getIGPostLikes();
  }, []);
  // console.log(views);
  const postIdArr = [];
  postsArr.map((e) => {
    if (
      e.link.includes("tiktok") &&
      !e.link.includes("vm.tikok") &&
      !e.link.includes("vt.tiktok") &&
      e.link.includes("/video/")
    ) {
      postIdArr.push(e.link.split("/video/")[1].split("?")[0]);
    }
  });
  return (
    <React.Fragment>
      <section className="bg-profile d-table w-100 mt-4 pt-4">
        <Container>
          <br />
          <div
            className="border bg-white p-4 d-flex mt-4"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: 8,
            }}
          >
            <div>
              <h4 className="title mb-0" style={{ lineHeight: 1.2 }}>
                {competition ? (
                  name
                ) : (
                  <Skeleton
                    width="150px"
                    height="24px"
                    widthRandomness={0}
                    color="#ced5e0"
                  />
                )}
              </h4>
              <p className="text-muted">Review New Posts</p>
            </div>
            <button
              className="btn btn-primary"
              onClick={async () => {
                setIsUpdating(true);
                await updateLeaderboard();
                updateTime();
              }}
            >
              {isUpdating && completionPct < 100 ? (
                <CircularProgress
                  variant="determinate"
                  value={completionPct}
                  color="white"
                />
              ) : (
                "Update"
              )}
            </button>

          </div>
          <div
            style={{
              display: "grid",
              margin: "auto",
              gridTemplateColumns: '330px 330px 330px',
              gridColumnGap: '4px',
            }}
          >
            {newPosts.map((e,i) => {
              function getIGEmbedLink() {
                if (e.link.includes("?")) {
                  return e.link.split("?")[0];
                } else {
                  return e.link + "/";
                }
              }
              if (e.likes === 0 && i < limit) {
                return (<PostBlock
                  key={i}
                  post={e}
                  startDate={competition.startDate}
                  idArr={postIdArr}
                />);
              }
            })}
          </div>
          {
            limit+10 < newPosts.length &&(
              <div className="btn btn-primary full-width" style={{width:'100%'}} onClick={()=>{setLimit(limit+10)}}>Load More</div>
            )
          }
          
        </Container>
      </section>
    </React.Fragment>
  );
}

export default CompetitionDetail;
