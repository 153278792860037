import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get, indexOf } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import {
  updatePost,
  updateCompetition,
  updateWinners,
} from "../../graphql/mutations";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import * as moment from "moment";
import { Button } from "reactstrap";
import { listCompetitions, listPosts } from "../../graphql/queries";
import Chart from "./chart.js";

function compareStart(a, b) {
   if (a.createdAt < b.createdAt) {
     return -1;
   }
   if (a.createdAt > b.createdAt) {
     return 1;
   }

   return 0;
}

function Analytics() {
  const [isDone, setIsDone] = useState(false);
  const [page, setPage] = React.useState(0);
  const [competitions, setCompetitions] = useState([]);
  const [posts, setPosts] = useState([]);
  const [chartType, setChartType] = useState("All");
  const daysInts = [];
  let postsOverTime = [];
  let newOverTime = [];
  let newCreatorsOverTime = [];
  const [postsOverTimeArr, setPostsOverTimeArr] = useState([]);
  const [newOverTimeArr, setNewOverTimeArr] = useState([]);
  const start = moment("2021-03-01");
  const end = moment(new Date());
  for (let index = end.diff(start, "days"); index >= 1; index--) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const newDate = today.setDate(today.getDate() - index);
    daysInts.push(newDate);
    postsOverTime.push({ date: newDate, value: 0 });
    newOverTime.push({ date: newDate, value: 0 });
  }

  const [sortByKey, setSortByKey] = useState("Balance");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const determineState = (comp) => {
    if (comp.hasEnded === true) {
      return "completed";
    } else if (comp.isActive === true) {
      return "active";
    } else {
      return "preview";
    }
  };

  function compare(a, b) {
    if (a.endDate > b.endDate) {
      return -1;
    }
    if (a.endDate < b.endDate) {
      return 1;
    }
    return 0;
  }
  const fetchCompetitions = async () => {

    const myCompetitions = [];
    let done = false;
    let nToken = false;
    while (!done) {
      const apiData = await API.graphql({
        query: listCompetitions,
        variables: {
          limit: 10000,
          nextToken: !nToken ? null : nToken,
        },
      });
      const myCompetitionsTemp = get(
        apiData,
        ["data", "listCompetitions", "items"],
        []
      );
      myCompetitionsTemp.map((e) => {
        if (e._deleted !== true) {
          myCompetitions.push(e);
        }
      });

      nToken = apiData.data.listCompetitions.nextToken;
      if (nToken === null) {
        done = true;
      }
    }
    await setCompetitions(
      myCompetitions
        .filter(function (e) {
          return (
            e._deleted !== true && (e.hasEnded === true || e.isActive === true)
          );
        })
        .sort(compare)
    );
    await fetchPosts(
      myCompetitions
        .filter(function (e) {
          return (
            e._deleted !== true && (e.hasEnded === true || e.isActive === true)
          );
        })
        .sort(compare)
    );
  };
  const calcTotalPrizes = (prizes) => {
    if (prizes) {
      let sum = 0;
      // let sum = prizes[0].includes("4th") ? 300 : 0;
      prizes.forEach((element, i) => {
        let val = element.split("$")[1];
        let label = element.split("$")[0];
        if (label.includes("-") && !label.includes("Followers")) {
          const rangeLow = element.split("-")[0];
          const rangeLowVal = rangeLow.substring(0, rangeLow.length - 2);
          const rangeHigh = element.split("-")[1].split(" Place")[0];
          const rangeHighVal = rangeHigh.substring(0, rangeHigh.length - 2);
          const multiplier = parseInt(rangeHighVal) - parseInt(rangeLowVal) + 1;
          val = val * multiplier;
        } else if (label.includes("Top")) {
          const amountOfWinners = parseInt(label.split(" ")[1]);
          val = amountOfWinners * val;
        }
        sum += parseFloat(val);
      });
      return sum;
    }
  };
  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();
  const fetchPosts = async (comps) => {
    const apiData = [];
    comps.sort(compareStart).map((e) => {
      e.posts.items.sort(compareStart).map((post) => {
        if (post._deleted !== true) {
          apiData.push(post);
        }
      });
    });

    console.log("api data", apiData.sort(compareStart));
    apiData.sort(compareStart).map(function (e, i) {
      if (e._deleted !== true && e.bonusLikes !== null) {
        const postDate = new Date(e.createdAt);
        postDate.setHours(0, 0, 0, 0);
        const indexOfDay = daysInts.indexOf(new Date(postDate).getTime());

        if (indexOfDay >= 0) {
          postsOverTime[indexOfDay] = {
            date: postDate,
            value: postsOverTime[indexOfDay].value + 1,
          };
        }
        if (indexOfDay >= 0 && !newCreatorsOverTime.includes(e.email)) {
          newOverTime[indexOfDay] = {
            date: postDate,
            value: newOverTime[indexOfDay].value + 1,
          };
          newCreatorsOverTime.push(e.email);
        }
      }
      if (i === apiData.length - 1) {
        setPostsOverTimeArr(postsOverTime);
        setNewOverTimeArr(newOverTime);
      }
    });

    setPosts(
      apiData.filter(function (e) {
        return e._deleted !== true;
      })
    );
  };

  const handleChartChange = (e) => {
    setChartType(e.target.value);
  }

  const columns = [
    {
      id: 1,
      label: "Name",
    },
    {
      id: 1,
      label: "Artist",
    },
    {
      id: 1,
      label: "Prizes",
    },
    {
      id: 2,
      label: "UGC",
    },
  ];


  useEffect(() => {
    fetchCompetitions();
  }, []);
  return (
    <React.Fragment>
      <br />
      <br />
      <section className="container pb-4 mt-5" id="home">
        {postsOverTimeArr.length > 0 && newOverTimeArr.length > 0 ? (
          <>
            <select
              name="charts"
              id="charts"
              className="form-control"
              style={{ maxWidth: 300, marginBottom: 8 }}
              value={chartType}
              onChange={handleChartChange}
            >
              <option value="All">All UGC Over Time</option>
              <option value="New">New Creators Over Time</option>
            </select>
            {chartType === "All" ? (
              <>
                <Chart data={postsOverTimeArr} />
              </>
            ) : (
              <Chart data={newOverTimeArr} />
            )}
          </>
        ) : (
          <>
            <div
              id="chartdiv"
              className="shadow"
              style={{
                width: "100%",
                height: "300px",
                background: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 16,
                borderRadius: 4,
              }}
            >
              <div className="loader"></div>
            </div>
          </>
        )}
        <Paper style={{ width: "100%" }}>
          <TableContainer>
            <h4 className="mt-2 ml-3 p-3" style={{ marginBottom: 0 }}>
              Competitions
            </h4>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.id}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSortByKey(column.label);
                          }}
                        >
                          {column.label === sortByKey ? (
                            <b>{column.label}</b>
                          ) : (
                            column.label
                          )}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {competitions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        // onClick={() =>
                        //   window.open(`https://preffy.com/c/${row.id}`, "_blank")
                        // }
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell key={row.id}>{row.name}</TableCell>
                        <TableCell key={row.id}>{row.organizer}</TableCell>
                        <TableCell key={row.id}>
                          ${calcTotalPrizes(row.prizes)}
                        </TableCell>
                        <TableCell key={row.id}>
                          {
                            row.posts.items.filter(function (e) {
                              return e._deleted !== true;
                            }).length
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={competitions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </section>
    </React.Fragment>
  );
}

export default Analytics;
