import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { v4 as uuid } from "uuid";

import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import S3FileUpload from "react-s3";
import { first, get } from "lodash";
import { Storage, Auth, API, graphqlOperation } from "aws-amplify";
import "./styles.css";
import { createCompetition as CreateCompetition } from "../../graphql/mutations";
import config from "../../aws-exports";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import "react-datepicker/dist/react-datepicker.css";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

//Import Profile Picture edit functionality
// import ReactAvatarEditor from 'react-avatar-editor';

//Import components

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
function isUrl(s) {
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return regexp.test(s);
}

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;


function StartACompetition() {
  const [secretAccessKey, setSecretAccessKey] = useState();
  useEffect(() => {
    if (!secretAccessKey) {
      setSecretAccessKey(process.env.REACT_APP_SECRET_ACCESS_KEY);
    }
  }, [process.env])
const s3Config = {
  bucketName: bucket,
  dirName: "images",
  region: region,
  accessKeyId: "AKIAXVVWZFLBMLSWJHG2",
  secretAccessKey: `${secretAccessKey}`,
};
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));
  const [startDate, setStartDate] = useState(new Date());
  const [title, setTitle] = useState("");
  const [isClosed, setIsClosed] = useState(false);
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [totalPrizes, setTotalPrizes] = useState(0);
  const [noOfWinners, setNoOfWinners] = useState(6);
  const [secondPlace, setSecondPlace] = useState(0);
  const [mostCreative, setMostCreative] = useState(0);
  const [hundredK, setHundredK] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [linkError, setLinkError] = useState("");
  const [email, setEmail] = useState("");
  const [prizes, setPrizes] = useState([
    "1st Place: $200",
    "2nd Place: $100",
    "First 30 Creators: $10",
  ]);
  const history = useHistory();
  const [productName, updateProductName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [artist, setArtist] = useState("");
  const [link, setLink] = useState("");
  const [prize, setPrize] = useState("200");
  const [paymentLink, setPaymentLink] = useState("200");

  const [file, updateFile] = useState(null);
  const [modal, setModal] = useState(false);
  const togglemodal = () => {
    setModal(!modal);
  };
  const [fileName, updateFileName] = useState("Upload Image");
  const [fileResult, updateFileResult] = useState(null);



  function handleImageChange(event) {
    const {
      target: { value, files },
    } = event;
    const fileForUpload = files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileForUpload);

    reader.onloadend = function (e) {
      updateFileResult([reader.result]);
    }.bind(this);
    if (fileForUpload) {
      updateFileName(fileForUpload.name.split(".")[0]);
      updateFile(fileForUpload || value);
    }
  }

  async function createCompetition() {
    if (file) {
      try {
        S3FileUpload.uploadFile(file, s3Config)
          .then(async (data) => {
            const inputData = {
              name: title,
              image: data.location,
              audio: link,
              endDate: new Date(endDate).getTime(),
              startDate: new Date(startDate).getTime(),
              organizer: artist,
              organizerEmail: email,
              isActive: false,
              hasEnded: false,
              prizes: prizes,
              invoiceAmount: invoiceAmount,
              lastUpdated: new Date().getTime(),
              isClosed: isClosed,
              isConfirmed: isClosed,
            };
            await API.graphql(
              graphqlOperation(CreateCompetition, { input: inputData })
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log("error: ", err);
      }
    } else {
      const inputData = {
        name: title,
        image:
          "https://preffy-webbcb877c98a0749c083b216aad631f2df162954-staging.s3.us-east-2.amazonaws.com/public/images/Screen+Shot+2021-04-12+at+2.47.25+PM.png",
        audio: link,
        endDate: new Date(endDate).getTime(),
        startDate: new Date(startDate).getTime(),
        organizer: artist,
        organizerEmail: email,
        isActive: false,
        hasEnded: false,
        invoiceAmount: invoiceAmount,
        prizes: prizes,
        lastUpdated: new Date().getTime(),
        isClosed: isClosed,
        isConfirmed: isClosed,
      };
      await API.graphql(
        graphqlOperation(CreateCompetition, { input: inputData })
      );
    }
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleInvoiceAmountChange = (e) => {
    if (parseFloat(e.target.value)) {
      if (parseFloat(e.target.value) > 1500) {
        setTotalPrizes(Math.round(parseFloat(e.target.value) * 0.667));
      } else {
        setTotalPrizes(parseFloat(e.target.value) - 500);
      }
      setInvoiceAmount(parseFloat(e.target.value));
    } else {
      setTotalPrizes();
      setInvoiceAmount();
    }
  };

  const handleNoOfWinnersChange = (e) => {
    setNoOfWinners(e.target.value);
  };

  const handleIsClosed = (e) => {
    setIsClosed(!isClosed);
  };

  const handleSecondPlaceChange = (e) => {
    setSecondPlace(e.target.value);
  };

  const handleMostCreativeChange = (e) => {
    setMostCreative(e.target.value);
  };

  const handleHundredKChange = (e) => {
    setHundredK(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleArtistChange = (e) => {
    setArtist(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const prizeError = () => {
    if (prize < 50) {
      return "Minimum $50";
    }
    if (prize > 10000) {
      return "Maximum $10000";
    }
    return "";
  };

  const isValidUrl = () => {
    if (isUrl(link)) {
      setLinkError("");
      return true;
    } else {
      setLinkError("Invalid link must be http");
      return false;
    }
  };

  const handleClick = async (event) => {
    // Get Stripe.js instance
    // Call your backend to create the Checkout Session
    if (isValidUrl()) {
      setIsLoading(true);
      await createCompetition();
      // window.location.reload();
      // if (isLoggedIn){
      //   welcomeCodeReturningOrganizers(email);
      // } else {
      //   welcomeCodeNewOrganizers(email);
      // }
    }
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        const userEmail = get(user, ["attributes", "email"], null);
        if (userEmail) {
          setEmail(userEmail);
          setIsLoggedIn(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <React.Fragment>
      <Container>
        <br />
        <br />
        <Row className="justify-content-center mt-5">
          <Col lg="12">
            <div className="p-4 rounded shadow bg-white mt-4-mobile">
              <h3 className="text-md-left mb-0 font-weight-bold">
                Start A Competition
              </h3>

              <AvForm>
                {!isLoading ? (
                  <Row className="mt-4">
                    <Col md="6" className="mb-3">
                      <AvGroup className="form-group position-relative">
                        <Label>Email</Label>

                        <AvField
                          type="text"
                          className="form-control pl-5"
                          name="email"
                          id="email"
                          placeholder="Ex. example@gmail.com"
                          value={email}
                          onChange={handleEmailChange}
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter the artist's email",
                            },
                            pattern: {
                              value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                              errorMessage: "Email is not valid!",
                            },
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col md="6" className="mb-3">
                      <AvGroup className="form-group position-relative">
                        <Label>Song Title</Label>

                        <AvField
                          type="text"
                          className="form-control pl-5"
                          name="title"
                          id="title"
                          placeholder="Ex. Hey Jude"
                          value={title}
                          onChange={handleTitleChange}
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                            },
                          }}
                        />
                      </AvGroup>
                    </Col>

                    <Col md="6" className="mb-3">
                      <AvGroup className="form-group position-relative">
                        <Label>Artist Name</Label>
                        <AvField
                          type="text"
                          className="form-control pl-5"
                          name="artist"
                          id="artist"
                          placeholder="Ex. The Beatles"
                          value={artist}
                          onChange={handleArtistChange}
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter the artist's name",
                            },
                          }}
                        />
                      </AvGroup>
                    </Col>

                    <Col md="6" className="mb-3">
                      <AvGroup className="form-group position-relative">
                        <Label>Link To Song</Label>
                        <AvField
                          type="text"
                          className="form-control pl-5"
                          name="link"
                          id="link"
                          placeholder="Public TikTok"
                          value={link}
                          onChange={handleLinkChange}
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter the song's link",
                            },
                          }}
                        />
                        {linkError.length > 0 && (
                          <p
                            className="text-danger text-sm"
                            style={{ marginTop: -10 }}
                          >
                            {linkError}
                          </p>
                        )}
                      </AvGroup>
                    </Col>
                    <Col md="6" className="mb-3">
                      <FormGroup
                        className="position-relative"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Label>Start Date</Label>
                        <DatePicker
                          selected={startDate}
                          className="form-control"
                          onChange={(date) => setStartDate(date)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" className="mb-3">
                      <FormGroup
                        className="position-relative"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Label>End Date</Label>
                        <DatePicker
                          selected={endDate}
                          className="form-control"
                          onChange={(date) => setEndDate(date)}
                          minDate={addDays(new Date(), 7)}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" className="mb-3">
                      <AvGroup className="form-group position-relative">
                        <Label>Invoice Amount</Label>

                        <AvField
                          type="number"
                          className="form-control pl-5"
                          name="invoiceAmount"
                          id="invoiceAmount"
                          placeholder="1000"
                          value={invoiceAmount}
                          onChange={handleInvoiceAmountChange}
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                            },
                          }}
                        />
                      </AvGroup>
                    </Col>

                    <Col md="6" className="mb-3">
                      <AvGroup className="form-group position-relative">
                        <Label>
                          Prizes{" "}
                          <i
                            className="mdi mdi-help-circle"
                            onClick={() => {
                              togglemodal();
                            }}
                          ></i>
                        </Label>
                        {prizes &&
                          prizes.map((e, i) => {
                            return (
                              <div
                                key={i}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="text"
                                  value={e}
                                  onChange={(a) => {
                                    const shallowCopy = [...prizes];
                                    shallowCopy[i] = a.target.value;
                                    setPrizes(shallowCopy);
                                  }}
                                  className=" p-2 mr-2 mt-1"
                                  style={{
                                    maxHeight: 40,
                                    width: "80%",
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    borderRadius: 6,
                                  }}
                                />
                                <div
                                  className="cursor-pointer ml-2"
                                  style={{ marginLeft: 8 }}
                                  onClick={() => {
                                    const prizesWithRemoval = prizes.filter(
                                      (prize, count) => count !== i
                                    );
                                    setPrizes(prizesWithRemoval);
                                  }}
                                >
                                  <i className="mdi mdi-close" />
                                </div>
                              </div>
                            );
                          })}
                        <div
                          className="mt-2 text-primary "
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const shallowCopy = [...prizes];
                            shallowCopy.push("New Prize");
                            setPrizes(shallowCopy);
                          }}
                        >
                          Add Prize
                        </div>
                      </AvGroup>
                    </Col>

                    <Col md="6" className="mb-3">
                      <FormGroup
                        className="position-relative"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Label>Album Image</Label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <label
                            htmlFor="file-upload"
                            className="upload-album border"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "36px",
                            }}
                          >
                            {fileResult ? (
                              <img
                                src={fileResult}
                                style={{ height: "100%", width: "100%" }}
                              />
                            ) : (
                              <i className="mdi mdi-plus"></i>
                            )}
                          </label>
                          <input
                            type="file"
                            id="file-upload"
                            onChange={handleImageChange}
                            style={{ width: "140px" }}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="6" className="mb-3">
                      <AvGroup className="form-group position-relative">
                        <Label>Is Closed?</Label>
                        <br />
                        <input
                          type="checkbox"
                          checked={isClosed}
                          onChange={handleIsClosed}
                        />
                      </AvGroup>
                    </Col>

                    {/* <Col md="12">
                        <FormGroup className="fposition-relative">
                          <Label>Description</Label>
                          <textarea
                            name="comments"
                            id="comments"
                            rows="4"
                            className="form-control"
                            placeholder="Description :"
                          ></textarea>
                        </FormGroup>
                      </Col> */}
                  </Row>
                ) : (
                  <h3>Succesfully Added</h3>
                )}

                <br />
                <Row>
                  <Col sm="12">
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      className="btn btn-secondary float-right"
                      value={isLoading ? "Done" : "Submit"}
                      onClick={handleClick}
                      disabled={isLoading}
                    />
                  </Col>
                </Row>
              </AvForm>
              {/* <div className="mt-3 text-md-left d-sm-flex">
                  
                    <img
                      src={profile}
                      className="avatar float-md-left avatar-medium br-4 rounded shadow mr-md-4"
                      alt=""
                    />
                    <div className="mt-md-4 mt-3 mt-sm-0">
                      <Link to="#" className="btn btn-outline-secondary mt-2">
                        {" "}
                        Change Picture{" "}
                      </Link>
                    </div>
                  </div> */}
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <Modal isOpen={modal} toggle={togglemodal}>
        <ModalHeader toggle={togglemodal} tag="h4">
          Prize Options
        </ModalHeader>
        <ModalBody>
          1st Place: $100
          <br />
          1st-5th Place: $100
          <br />
          First 30 Creators: $100<br/> Most Creative: $100<br/> Top 20 Most Creative: $100<br/>
          Best Dance: $100<br/> Top 20 Best Dances: $100<br/>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default StartACompetition;
