import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { Button } from "reactstrap";
import { listWinnerss } from "../../graphql/queries";
import MuiButton from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {
  updatePost,
  updateCompetition,
  deletePost,
} from "../../graphql/mutations";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PostBlock({ post, startDate }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const [isBanned, setIsBanned] = useState(false);
  const [winners, setWinners] = useState([]);
  const [wasDeleted, setWasDeleted] = useState("Delete");
  const bonusLikes = get(post, ["bonusLikes"], 0);
  const [value, setValue] = useState(post.likes - bonusLikes);
  const [newValue, setNewValue] = useState(null);
  const [error, setError] = useState(null);
  const [bonus, setBonus] = useState(0);
  const [link, setLink] = useState(post);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [videoId, setVideoId] = useState();
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleBonusChange = (e) => {
    setBonus(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    return t.toDateString();
  };
  function getVideoId() {
    if (post.includes("vt.tiktok") || post.includes("vm.tiktok")) {
      fetch(
        `https://social-media-data-tt.p.rapidapi.com/live/post/meta/v2?video=${post}`,
        {
          method: "GET",
          headers: {
            "x-rapidapi-key":
              "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
            "x-rapidapi-host": "social-media-data-tt.p.rapidapi.com",
          },
        }
      )
        .then((res) => res.text())
        .then(async (response) => {
          const resultObj = JSON.parse(response);

          setLink(
            `https://tiktok.com/@${resultObj.authorMeta.name}/video/${resultObj.id}`
          );

          setVideoId(resultObj.id);
          const startDateMidnight = new Date(startDate);
          startDateMidnight.setHours(0, 0, 0, 0);
          if (
            new Date(startDateMidnight).getTime() >
            new Date(calcDateValue(resultObj.createTime)).getTime()
          ) {
            setError("Invalid Post Date");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return false;
  }
  console.log(error);



  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("apikey", "eKf13aXEAZYaYH34Gcgg5fYMqlb6GJT2");

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    if (getVideoId()) {
      fetch(
        `https://api.promptapi.com/tiktok/video/${getVideoId()}`,
        requestOptions
      )
        .then((response) => response.text())
        .then(async (result) => {
          const resultObj = JSON.parse(result);
          const likeCount = get(
            resultObj,
            ["itemStruct", "stats", "diggCount"],
            value
          );
          if (likeCount !== value) {
            setValue(likeCount);
          }
        })
        .catch((error) => console.log("error", error));
    }
    getVideoId();
  }, []);



  function getIGEmbedLink() {
    if (post.includes("?")) {
      return post.split("?")[0];
    } else {
      return post + "/";
    }
  }
  return (
    <div className="mb-4" style={{ display: "flex", flexDirection: "column" }}>
      {post.includes("tiktok.com") && videoId ? (
        <>
          <iframe
            src={`https://www.tiktok.com/embed/${videoId}`}
            scrolling="no"
            width="240"
            height="500"
            style={{
              maxWidth: "100%",
              width: 240,
              height: 500,
              border: "none",
            }}
          ></iframe>
          <span>
            <a href={post} target="_blank" rel="noreferrer">
              Link{" "}
            </a>
            <br />
            <br />
            <span className="text-danger">{error}</span>
          </span>
         
        </>
      ) : (
        <>
          <iframe
            src={`${getIGEmbedLink()}embed`}
            scrolling="no"
            width="240"
            height="500"
            style={{
              maxWidth: "100%",
              width: 240,
              height: 500,
              border: "none",
            }}
          ></iframe>
          <span>
            <a href={post} target="_blank" rel="noreferrer">
              Link{" "}
            </a>

            <span className="text-danger">{error}</span>
          </span>
          
        </>
      )}
     
    </div>
  );
}

export default PostBlock;
