/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompetition = /* GraphQL */ `
  mutation CreateCompetition(
    $input: CreateCompetitionInput!
    $condition: ModelCompetitionConditionInput
  ) {
    createCompetition(input: $input, condition: $condition) {
      id
      name
      organizer
      prize
      image
      endDate
      audio
      hasEnded
      isActive
      prizes
      posts {
        items {
          id
          link
          email
          postId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompetition = /* GraphQL */ `
  mutation UpdateCompetition(
    $input: UpdateCompetitionInput!
    $condition: ModelCompetitionConditionInput
  ) {
    updateCompetition(input: $input, condition: $condition) {
      id
      name
      lastUpdated
      posts {
        items {
          id
          link
          email
          postId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateWinners = /* GraphQL */ `
  mutation UpdateWinners(
    $input: UpdateWinnersInput!
    $condition: ModelWinnersConditionInput
  ) {
    updateWinners(input: $input, condition: $condition) {
      id
      balance
      email
    }
  }
`;

export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createWinners = /* GraphQL */ `
  mutation CreateWinners(
    $input: CreateWinnersInput!
    $condition: ModelWinnersConditionInput
  ) {
    createWinners(input: $input, condition: $condition) {
      id
      balance
      email
    }
  }
`;

export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      CompetitionID
      ContactID
      VendorID
      ContactEmail
    }
  }
`;

export const deleteWinners = /* GraphQL */ `
  mutation deleteWinners(
    $input: DeleteWinnersInput!
    $condition: ModelWinnersConditionInput
  ) {
    deleteWinners(input: $input, condition: $condition) {
      id
      
    }
  }
`;

export const deleteCompetition = /* GraphQL */ `
  mutation DeleteCompetition(
    $input: DeleteCompetitionInput!
    $condition: ModelCompetitionConditionInput
  ) {
    deleteCompetition(input: $input, condition: $condition) {
      id
      name
      posts {
        items {
          id
          link
          email
          postId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      link
      email
      postId
      competition {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      link
      email
      postId
      likes
      competition {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      link
      email
      postId
      competition {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const createReferral = /* GraphQL */ `
  mutation CreateReferral(
    $input: CreateReferralInput!
    $condition: ModelReferralConditionInput
  ) {
    createReferral(input: $input, condition: $condition) {
      id
      postId
      referringLink 
    }
  }
`;
