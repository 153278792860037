import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import request from "ajax-request";
import ReactHtmlParser from "react-html-parser";
import queryString from "query-string";
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  Button,
  DropdownToggle,
} from "reactstrap";
import { get } from "lodash";
import moment from "moment";
//SimpleBar
import SimpleBar from "simplebar-react";
// import images
import user1 from "../../../assets/images/users/avatar-2.jpg";
import AddANoteModal from "common/AddANoteModal";

const Comments = (props) => {
  const [modal, setModal] = useState(false);
  const [comments, setComments] = useState([]);
  const [activity, setActivity] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const toggleViewModal = () => setModal(!modal);
  useEffect(() => {
    request(
      {
        url: `https://www.songfluencer.me/portal2/inquiries/GetNotes.cfm/?id=${params.id}&Token=${params.LoginToken}`,
        method: "POST",
        crossDomain: true,
      },
      function (err, res, body) {
        // console.log("comments",body)
        const bodyInJSON = JSON.parse(body);
        setComments(bodyInJSON);
      }
    );
    request(
      {
        url: `https://www.songfluencer.me/portal2/inquiries/GetUser.cfm/?Token=${params.LoginToken}`,
        method: "POST",
        crossDomain: true,
      },
      function (err, res, body) {
        const bodyInJSON = JSON.parse(body);
        setUserInfo(bodyInJSON.Data);
      }
    );
   
  }, [location]);
 
  return (
    <React.Fragment>
      <AddANoteModal modal={modal} toggle={toggleViewModal} InquiryID={params.id} token={params.LoginToken} DisplayName={userInfo && `${userInfo[0].FIRSTNAME} ${userInfo[0].LASTNAME}`}/>
      <Col xl={12} lg={12}>
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <div
                className="full-width mb-3"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h5 className="card-title mb-3">Notes</h5>
                <div className="full-width">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      toggleViewModal();
                    }}
                    className="btn-rounded mb-2 me-2"
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add Note
                  </Button>
                </div>
              </div>
            </div>
            <SimpleBar style={{ maxHeight: "500px" }}>
              <div>
                <ul className="list-group list-group-flush">
                  {comments.map((e, i) => {
                    const displayName = get(e, ["DISPLAYNAME"], "");
                    const authorImage = get(
                      e,
                      ["AUTHORIMAGE"],
                      "https://www.edmundsgovtech.com/wp-content/uploads/2020/01/default-picture_0_0.png"
                    );
                    const noteMessage = get(e, ["NOTEMESSAGE"], "");
                    return (
                      <li key={i} className="list-group-item py-3">
                        <div className="media">
                          <div className="avatar-xs me-3">
                            <div
                              style={{
                                backgroundImage: `url(${
                                  e.AUTHORIMAGE ||
                                  "https://i.stack.imgur.com/l60Hf.png"
                                })`,
                              }}
                              className="profile-img"
                            />
                          </div>
                          <div className="media-body">
                            <h5 className="font-size-14 mb-1">
                              {displayName}
                              <small className="text-muted float-end">
                                {moment(e.NOTEDATE).fromNow}
                              </small>
                            </h5>
                            <p className="text-muted">{ReactHtmlParser(noteMessage)}</p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
      
    </React.Fragment>
  );
};

export default Comments;
