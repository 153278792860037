import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get, update } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { updatePost,   deletePost,  updateCompetition } from "../../graphql/mutations";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Menu,
  MenuItem,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
} from "reactstrap";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { listCompetitions, listPosts, getCompetition } from "../../graphql/queries";

function getVideoId(post, platform) {
  if (post !== undefined) {
    if (post.includes("/video/")) {
      if (platform === "tiktok") {
        if (post.includes("?")) {
          return post.split("/video/")[1].split("?")[0];
        } else {
          return post.split("/video/")[1];
        }
      }
    }
  }
  return false;
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function AllCompetitions() {
  const [isDone, setIsDone] = useState(false);
  let location = useLocation();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  }
  const [email, setEmail] = useState();
  const [link, setLink] = useState();
  const [likes, setLikes] = useState();
  const [creator, setCreator] = useState();
  const [version, setVersion] = useState();
  const [isDeleted, setIsDeleted] = useState();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }
  const handleLikesChange = (e) => {
    setLikes(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const handleCreatorChange = (e) => {
    console.log("")
    setCreator(e.target.value);
  };
  const handleIsDeleted = (e) => {
    setIsDeleted(e.target.checked);
  };
  let params = queryString.parse(location.search);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [sortByKey, setSortByKey] = useState("End Date");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [postsState, setPostsState] = useState(true);
  const [activeIndex, setActiveIndex] = useState();
  const handleChangePage = (event, newPage) => {
    let competitionsArr = [];
    setPage(newPage);
  };

  const filterByArtist = (e) => {
    setFilter(e.target.value);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const handleClick = async () => {
    if (isDeleted) {
      const inputData = {
        id: activeIndex,
        
        _version: version,
      };
      await API.graphql(
        graphqlOperation(deletePost, { input: inputData })
      );
    } else {
      const inputData = {
        id: activeIndex,
        creator: creator,
        likes: parseFloat(likes),
        link: link,
        email:email,
        _version: version,
      };
      await API.graphql(
        graphqlOperation(updatePost, { input: inputData })
      );
    }
    
  }

  
  const fetchPosts = async () => {
    let myPosts=[];
    let apiPostCompetitionData = await API.graphql({
      query: listCompetitions,
      variables: {
        filter: { isActive: { eq:  postsState} },
        limit: 10000,
      },
    });
    let myPostsTemp = get(apiPostCompetitionData, ["data", "listCompetitions", "items"], []);
    myPostsTemp.map((e) => {
      e.posts.items.map(a=>{
        myPosts.push(a);
      })      
    });
    setPosts(myPosts);
  };

  const columns = [
    {
      id: 1,
      label: "ID",
    },

    {
      id: 2,
      label: "Creator",
    },

    {
      id: 3,
      label: "Email",
    },
    {
      id: 4,
      label: "Likes",
    },
    {
      id: 5,
      label: "Link",
    },
    {
      id: 5,
      label: "Deleted",
    },

    {
      id: 9,
      label: "Actions",
    },
  ];

  let postsArr = posts
    .filter(function (e) {
      return (
        e.link.toLowerCase().includes(filter.toLowerCase()) ||
        e.postId.toLowerCase().includes(filter.toLowerCase()) ||
        e.creator.toLowerCase().includes(filter.toLowerCase()) ||
        e.email.toLowerCase().includes(filter.toLowerCase())       );
    });  
  useEffect(() => {
    fetchPosts();
  }, [postsState]);
  return (
    <React.Fragment>
      <br />
      <br />
      <section className="container pb-4 pt-4 mt-4" id="home">
        {postsArr.length > 0 || filter.length > 0 ? (
          <>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                Edit Post
              </ModalHeader>
              <ModalBody>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <div className="p-4 rounded bg-white">
                      <AvForm>
                        <Row className="">
                          <Col md="6" className="mb-3">
                            <AvGroup className="form-group position-relative">
                              <Label>Email</Label>

                              <AvField
                                type="text"
                                className="form-control pl-5"
                                name="email"
                                id="email"
                                placeholder="Ex. example@gmail.com"
                                value={email}
                                onChange={handleEmailChange}
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      "Please enter the artist's email",
                                  },
                                  pattern: {
                                    value:
                                      "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                    errorMessage: "Email is not valid!",
                                  },
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col md="6" className="mb-3">
                            <AvGroup className="form-group position-relative">
                              <Label>Creator</Label>

                              <AvField
                                type="text"
                                className="form-control pl-5"
                                name="title"
                                id="title"
                                placeholder="Ex. Hey Jude"
                                value={creator}
                                onChange={handleCreatorChange}
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </AvGroup>
                          </Col>

                          <Col md="6" className="mb-3">
                            <AvGroup className="form-group position-relative">
                              <Label>Likes</Label>
                              <AvField
                                type="text"
                                className="form-control pl-5"
                                name="artist"
                                id="artist"
                                placeholder="Ex. The Beatles"
                                value={likes}
                                onChange={handleLikesChange}
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      "Please enter the artist's name",
                                  },
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col md="6" className="mb-3">
                            <AvGroup className="form-group position-relative">
                              <Label>Link</Label>
                              <AvField
                                type="text"
                                className="form-control pl-5"
                                name="link"
                                id="link"
                                placeholder="Link"
                                value={link}
                                onChange={handleLinkChange}
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      "Please enter the artist's name",
                                  },
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col md="6" className="mb-3">
                            <AvGroup
                              className="form-group position-relative"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Label>Is Deleted?</Label>
                              <input
                                type="checkbox"
                                checked={isDeleted}
                                onChange={handleIsDeleted}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col sm="12">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="btn btn-secondary float-right"
                              value={isLoading ? "Done" : "Submit"}
                              onClick={handleClick}
                            />
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
            <Paper style={{ width: "100%" }}>
              <TableContainer>
                <div
                  className="d-flex shadow"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                  >
                    <h4
                      className={`mt-2 ml-3 p-3 mb-1 cursor-pointer ${
                        postsState && "text-primary"
                      }`}
                      onClick={() => {
                        setPostsState(true);
                      }}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      Active Posts
                    </h4>
                    <h4
                      className={`mt-2 ml-3 p-3 mb-1 cursor-pointer ${
                        !postsState && "text-primary"
                      }`}
                      onClick={() => {
                        setPostsState(false);
                      }}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      Inactive Posts
                    </h4>
                  </div>

                  <input
                    type="text"
                    className="shadow p-2 mr-2 mt-3"
                    placeholder="Search"
                    onChange={filterByArtist}
                    style={{
                      maxHeight: 40,
                      border: "1px solid rgba(224, 224, 224, 1)",
                      borderRadius: 6,
                    }}
                  />
                </div>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow
                      className="bg-white"
                      style={{ backgroundColor: "white !important" }}
                    >
                      {columns.map((column) => {
                        return (
                          <TableCell key={column.id}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSortByKey(column.label);
                              }}
                            >
                              {column.label === sortByKey ? (
                                <b>{column.label}</b>
                              ) : (
                                column.label
                              )}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {postsArr
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            // onClick={() =>
                            //   window.open(`https://preffy.com/c/${row.id}`, "_blank")
                            // }
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell key={row.id}>{row.postId}</TableCell>
                            <TableCell key={row.id}>{row.creator}</TableCell>
                            <TableCell key={row.id}>{row.email}</TableCell>
                            <TableCell key={row.id}>{row.likes}</TableCell>
                            <TableCell
                              key={row.id}
                              style={{
                                width: "50px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <p
                                style={{
                                  width: "350px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row.link}
                              </p>
                            </TableCell>
                            <TableCell key={row.id}>{row._deleted ? 'true' : 'false'}</TableCell>

                            <TableCell key={row.id}>
                              <PopupState
                                variant="popover"
                                popupId="demo-popup-menu"
                              >
                                {(popupState) => (
                                  <React.Fragment>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      {...bindTrigger(popupState)}
                                    >
                                      Menu
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                      <MenuItem
                                        onClick={() =>
                                          window.open(`${row.link}`, "_blank")
                                        }
                                      >
                                        View
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          setCreator(row.creator);
                                          setEmail(row.email);
                                          setActiveIndex(row.id);
                                          setLink(row.link);
                                          setLikes(row.likes);
                                          setVersion(row._version);
                                          setIsDeleted(row._deleted);
                                          toggle();
                                        }}
                                      >
                                        Edit
                                      </MenuItem>
                                    </Menu>
                                  </React.Fragment>
                                )}
                              </PopupState>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={postsArr.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        ) : (
          <div
            style={{
              margin: "auto",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "center",
            }}
          >
            <div className="loader" style={{ margin: "auto" }}></div>
          </div>
        )}
      </section>
    </React.Fragment>
  );
}

export default AllCompetitions;
