import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  path: Path,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      // if (isAuthProtected && !localStorage.getItem("authUser")) {
      //   return (
      //     <Redirect
      //       to={{ pathname: "/ecommerce-inquiries", state: { from: props.location } }}
      //     />
      //   )
      // }
      console.log("location",Path);
      if (Path !== "/preffy/api") { 
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      } else {
        return <Component {...props} />;
      }
      
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
