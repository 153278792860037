import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import {
  Button,
} from "reactstrap";
import { listWinnerss } from "../../graphql/queries";
import MuiButton from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {DisqualificationEmail} from "../Update/disqualification-email";
import { NonOriginalContent } from "../Update/non-originalcontent";
import { CreativeDirectionIssue } from "../Update/creativedirectionissue";
import { GeneralDisqualification } from "../Update/generaldisqualification";
import "./styles.css";
import {
  updatePost,
  updateCompetition,
  deletePost,
} from "../../graphql/mutations";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PostBlock({ post, startDate, idArr, winners }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {

    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    await DisqualificationEmail(post.email, post.link);
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const handleDeleteOC = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    await NonOriginalContent(post.email, post.link);
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const handleDeleteCD = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    await CreativeDirectionIssue(post.email, post.link);
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const handleDeleteGeneral = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    await GeneralDisqualification(post.email, post.link);
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const handleDeleteDC = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    await CreativeDirectionIssue(post.email, post.link);
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };



  const handleOnlyDelete = async () => {
    const variables = {
      input: {
        id: post.id,
        _version: post._version,
      },
    };
    const deletePostResult = await API.graphql(
      graphqlOperation(deletePost, variables)
    );
    setWasDeleted("Was Deleted");
    setOpen(false);
  };

  const [isBanned, setIsBanned] = useState(false);
  const [wasDeleted, setWasDeleted] = useState("Delete");
  const bonusLikes = get(post, ["bonusLikes"], 0);
  const [value, setValue] = useState(post.likes - bonusLikes);
  const [newValue, setNewValue] = useState(null);
  const [error, setError] = useState(null);
  const [bonus, setBonus] = useState(0);
  const [link, setLink] = useState(post.link);
  const [likes, setLikes] = useState(post.likes);
  const [country, setCountry] = useState("");
  const [hasUpdated, setHasUpdated] = useState(false);
  const [referralsNum, setReferralsNum] = useState(post.referrals.items.length);
  const [videoId, setVideoId] = useState();
  
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const handleLikesChange = (e) => {
    setLikes(e.target.value);
  };
  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    return t.toDateString();
  };
  function getVideoId() {
    if (
      post.link.includes("vt.tiktok") ||
      post.link.includes("vm.tiktok") ||
      post.link.includes("tiktok.com/t/") ||
      post.link.includes("tiktok.com")
    ) {
      const pLink = post.link.includes("https://tiktok.com") ? post.link.replace("https://","https://www.") : post.link;
      fetch(
        `https://social-media-data-tt.p.rapidapi.com/live/post/meta/v2?video=${pLink}`,
        {
          method: "GET",
          headers: {
            "x-rapidapi-key":
              "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
            "x-rapidapi-host": "social-media-data-tt.p.rapidapi.com",
          },
        }
      )
        .then((res) => res.text())
        .then(async (response) => {
          const resultObj = JSON.parse(response);

          setLink(
            `https://tiktok.com/@${resultObj.authorMeta.unique_id}/video/${resultObj.id}`
          );
          setCountry(resultObj.country);
          console.log("result obj",resultObj)
          setVideoId(resultObj.id);
          const startDateMidnight = new Date(startDate);
          startDateMidnight.setHours(0, 0, 0, 0);
          if (
            new Date(startDateMidnight).getTime() >
            new Date(calcDateValue(resultObj.createTime)).getTime()
          ) {
            await handleOnlyDelete();
            setError("Invalid Post Date");
          }
          if (resultObj.diggCount > resultObj.playCount) {
            await handleOnlyDelete();
            setError("CHEATING! Likes exceed views");
          } else if ((resultObj.diggCount / resultObj.playCount) > .7 && resultObj.diggCount > 1000)  {
            setError("Investigate further for cheating");
          }
        })
        .catch((err) => {
          console.error(err);
          fetch(
            `https://social-media-data-tt.p.rapidapi.com/live/post/meta/?video=${pLink}`,
            {
              method: "GET",
              headers: {
                "x-rapidapi-key":
                  "2702222b51mshc1e05e8a9f14f3fp110c73jsn89c06e9f9dcf",
                "x-rapidapi-host": "social-media-data-tt.p.rapidapi.com",
              },
            }
          )
            .then((res) => res.text())
            .then(async (response) => {
              const resultObj = JSON.parse(response);
    
              setLink(
                `https://tiktok.com/@${resultObj.authorMeta.unique_id}/video/${resultObj.id}`
              );
              setCountry(resultObj.country);
    
              setVideoId(resultObj.id);
              const startDateMidnight = new Date(startDate);
              startDateMidnight.setHours(0, 0, 0, 0);
              if (
                new Date(startDateMidnight).getTime() >
                new Date(calcDateValue(resultObj.createTime)).getTime()
              ) {
                await handleOnlyDelete();
                setError("Invalid Post Date");
              }
            })
        });
    } else {
      if (post.link.includes("tiktok")) {
        setLink(post.link);
        if (post.link.includes("/video/")) {
          setVideoId(post.link.split("/video/")[1].split("?")[0]);
        }
      }
    }

    return false;
  }
  console.log(error);

  const getIsBanned = async () => {
      const myWinners = [];
      let done = false;
      let nToken = false;
      while (!done) {
        const apiData = await API.graphql({
          query: listWinnerss,
          variables: {
            limit: 1000,
            nextToken: !nToken ? null : nToken,
          },
        });
        const myWinnersTemp = get(apiData, ["data", "listWinnerss", "items"], []);
        myWinnersTemp.map((e) => {
          if (e._deleted !== true) {
            myWinners.push(e);
          }
        });
  
        nToken = apiData.data.listWinnerss.nextToken;
        if (nToken === null) {
          done = true;
        }
      }
    
    const apiData = myWinners.filter(function (e) {
      return e._deleted !== true && e.email === post.email;
    });
    if (apiData && apiData.length > 0 && apiData.filter(e => e.isBanned === true).length > 0) {
      await handleOnlyDelete();
      setError("Banned User");
    } 
  }

  useEffect(() => {
    getIsBanned();
    getVideoId();

  }, []);

  const update = async () => {
    const variables = {
      input: {
        id: post.id,
        likes: parseFloat(likes),
        link: link,
        _version: post._version,
      },
    };
    const createPostResult = await API.graphql(
      graphqlOperation(updatePost, variables)
    );
    setHasUpdated(true);
  };

  const returnIFrame = () => {
    if (post.platform === "tiktok" && videoId) {
      return (
        <>
          <iframe
            src={`https://www.tiktok.com/embed/${videoId}`}
            scrolling="no"
            width="330"
            height="740"
            style={{
              maxWidth: "100%",
              width: 330,
              height: 740,
              border: "none",
            }}
          ></iframe>
          <span>
            <a href={post.link} target="_blank" rel="noreferrer">
              Link{" "}
            </a>
            <br />
            {post.email}
            <br />
            {country === post.country ? (
              <span className="text-success">tiktok:{country} - preffy:{post.country}</span>
            ) : (
              <span className="text-danger">tiktok:{country} - preffy:{post.country}</span>
            )}
             
            <br />
            <span className="text-danger">{error}</span>
          </span>
          <input value={link} onChange={handleLinkChange} />
         
          <Button
            variant="outlined"
            color="primary"
            className="btn-outline-secondary text-white"
            onClick={handleClickOpen}
          >
            {wasDeleted}
          </Button>
        </>
      );
    } else {
      if (post.platform === "tiktok") {
        return (
          <>
          <div style={{
            maxWidth:'330px',
            width: 330,
            height: 740,
            border: "none",
            marginRight:'4px',
            marginLeft:'4px',
            background:'#fff',
            display:'flex',
            alignItems:'center'
          }}>
            <div className="loader" style={{ margin: "auto" }}></div>
            
          </div>
          <span>
            <a href={post.link} target="_blank" rel="noreferrer">
              Link{" "}
            </a>
            <br />
            {post.email}
            <br />
            <span className="text-danger">{error}</span>
          </span>
          <Button
              variant="outlined"
              color="primary"
              className="btn-outline-secondary text-white"
              onClick={handleClickOpen}
            >
              {wasDeleted}
            </Button>
          </>
        )
        
      }
    }
  }
  return (
    <div className="mb-4" style={{ display: "flex", flexDirection: "column" }}>
      {returnIFrame()}
      <div style={{display:'flex',flexDirection:'row', marginTop:6, width:'100%'}}>
        <input value={likes} onChange={handleLikesChange} type="number" />
        <div className="btn btn-secondary" onClick={() => {
          if (!hasUpdated){
            update();
          }
        }}>{hasUpdated ? "Done" : "Update"}</div>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Confirm deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are You Sure You Want To Do This?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOnlyDelete}
            color="secondary"
            className="btn-outline-secondary text-white"
          >
            No Email
          </Button>
          <Button
            onClick={handleDeleteGeneral}
            color="warning"
            className="btn-outline-secondary text-white"
          >
            General Disqualification
          </Button>

          <Button
            onClick={handleDeleteCD}
            color="warning"
            className="btn-outline-secondary text-white"
          >
            Creative Direction Issue
          </Button>

          <Button
            onClick={handleDelete}
            color="warning"
            className="btn-outline-secondary text-white"
          >
            Volume Issue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PostBlock;
